
//Another filter type should be added. It should be called EnumFilter and Enum for Source and State of session filtering.
//This is required due to backend limitation of not being able to filter enums. Therefore only is operation should be available and not startsWith and contains.
export enum FilterType {
  Generic = 'Generic', // suggestions: columns + search
  DateFilter = 'DateFilter', // od, do, je - search
  StringFilter = 'StringFilter', // je, ni - search
  NumberFilter = 'NumberFilter', // je, ni, je vecje, je manjse - search
  EnumFilter = "EnumFilter",
  String = 'String', // no suggestions - search, string regex
  Number = 'Number', // no suggestions - search, number regex
  Date = 'Date', // no suggestions - search, date regex
  Enum = "Enum",
  //  BooleanDeleteFilter = 'BooleanDeleteFilter', // izbrisan, ni izbrisan
}

export type IAppliedFilter = IAppliedLabel[];

export interface IAppliedLabel {
  label: string;
  field: string;
}

export interface ISuggestionItem {
  label: string;
  field: string;
  nextType: FilterType;
}

export interface FilterOptions {
  [index: string]: ISuggestionItem;
}

type SubFilters = FilterType.StringFilter | FilterType.DateFilter | FilterType.NumberFilter | FilterType.EnumFilter;
// | FilterType.BooleanDeleteFilter;

export type ITypeFilters = {
  [filter in SubFilters]: FilterOptions;
};

type RegexFilters = FilterType.String | FilterType.Number | FilterType.Date;

type IRegex = {
  [index: string]: RegExp;
};

type IRgexes = {
  [regex in RegexFilters]: IRegex;
};

export const UserFilters: FilterOptions = {
  createdAt: {
    label: 'Datum',
    field: 'createdAt',
    nextType: FilterType.DateFilter,
  },
  name: {
    label: 'Ime seje',
    field: 'name',
    nextType: FilterType.StringFilter,
  },
  labels: {
    label: 'Labela',
    field: 'labels',
    nextType: FilterType.StringFilter,
  },
};

export const AdminFilters: FilterOptions = {
  ...UserFilters,
  user: {
    label: 'Uporabnik',
    field: 'user',
    nextType: FilterType.StringFilter,
  },
  group: {
    label: 'Skupina',
    field: 'group',
    nextType: FilterType.StringFilter,
  },
  sessionSource: {
    label: 'Vir',
    field: 'sessionSource',
    nextType: FilterType.EnumFilter,
  },
  sessionStatus: {
    label: 'Stanje seje',
    field: 'sessionStatus',
    nextType: FilterType.EnumFilter,
  },
};

export const TypeFilters: ITypeFilters = {
  DateFilter: {
    from: { label: 'od', field: 'from', nextType: FilterType.Date },
    to: { label: 'do', field: 'to', nextType: FilterType.Date },
    is: { label: 'je', field: 'is', nextType: FilterType.Date },
  },
  StringFilter: {
    is: { label: 'je', field: 'is', nextType: FilterType.String },
    contains: { label: 'vsebuje', field: 'contains', nextType: FilterType.String },
    startsWith: { label: 'se začne', field: 'startsWith', nextType: FilterType.String },
  },
  EnumFilter: {
    is: { label: 'je', field: 'is', nextType: FilterType.Enum },
    isNot: { label: 'ni', field: 'isNot', nextType: FilterType.Enum },
  },
  NumberFilter: {
    is: { label: 'je', field: 'is', nextType: FilterType.Number },
    isnot: { label: 'ni', field: 'isNot', nextType: FilterType.Number },
    isbigger: { label: 'večje od', field: 'isBigger', nextType: FilterType.Number },
    issmaller: { label: 'manjše od', field: 'isSmaller', nextType: FilterType.Number },
  },
  // BooleanDeleteFilter: {
  //   false: { label: 'Izbrisana', field: 'false', nextType: FilterType.String },
  //   true: { label: 'Obstaja', field: 'true', nextType: FilterType.String },
  // },
};

export const ValueRegexes: IRgexes = {
  String: {
    regex: /^[a-zA-Z0-9 -ščćž]*$/,
  },
  Number: {
    regex: /^[0-9]*$/,
  },
  Date: {
    regex: /^[0-9.,:\-/ ]*$/,
  },
};

export enum SessionSource {
  API = "API",
  JAVA_LIBRARY = "JAVA_LIBRARY",
  JAVASCRIPT_LIBRARY = "JAVASCRIPT_LIBRARY",
  EDITOR = "EDITOR",
  UPLOAD_SERVICE = "UPLOAD_SERVICE",
}

export enum SessionState {
  DELETED = "DELETED",
  INITIALIZING = "INITIALIZING",
  IN_QUEUE = "IN_QUEUE",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
  CANCELED = "CANCELED",
  ERROR = "ERROR",
}

