import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AnimButtonStatesEnum, DashboardRefreshState, EditorModeEnums, IActiveConfiguration, IAddColumnMenu, IAudioInfo, IConsumtionModal, IDashboardFilterCommands, IDictionary, IEditorLock, IHeader, IHeaderWidth, IHomeFlowStates, IInverseTextNormalizator, ILoadContentToEditor, IPageable, IPronoun, IPunctuator, ISessionLabel, ISnack, ISortObject, ISpeakersModal, ISpeakersSettingsModal, IStore, ITrueCaser, IUpdateEditorState, IUser, IWsAction, SpeakersModalTypeEnum } from '../../store/IStore';
import { IAddToDictWord, IPronunciation } from '../../../api/DictionaryService';
import { IActiveUserQuotaChunk } from '../../../api/UserService';
import { IAppliedFilter } from '../../../components/DashboardHeader/ISearch';
import { ITbFormat } from '../../../components/Editor/IEditor';
import { ISttPipelines } from '../../../components/Home/ISettings';

const initialState: IStore = {
  doDictation: false,
  isTranscript: true,
  isPlaying: false,
  currentTime: 0,
  audioInfo: { url: '', loadNew: false },
  tbFormat: {},
  homeFlowStates: {
    uploadFlowInProgress: false,
    liveFlowInProgress: false,
  },
  dictionary: {
    phones: null,
    contextString: {
      contextLeft: 'homeopatsko zdravilo',
      contextRight: 'se uporablja',
      mainPhrase: 'amoxiclav forte extra',
    },
    pageSize: 5,
    caseSensitive: false,
    searchBy: 'word',
    searchedString: '',
    words: [],
    autoSave: null,
  },
  isAudioPlaying: false,
  speakersModal: {
    showModal: false,
    speaker: null,
    blockKey: null,
    modalType: SpeakersModalTypeEnum.NORMAL,
    editorSelection: null,
  },
  fontsLoaded: false,
  youtubeLink: null,
  showConfidence: false,
  editorMode: null,
  clickedTime: null,
  fontSize: '22px',
  headerIsExtended: true,
  loadContentToEditor: {
    recFinishedStartLoadingNewEditorState: false,
    recStartedLoadTextFromEditor: false,
  },
  manuallyUpdateEditorState: {
    update: false,
  },
  sessionName: '',
  isSessionDiscarded: false,
  activeWordId: '',
  uploadedFileDuration: null,
  makeBlocks: false,
  saveToDisk: false,
  user: null,
  authOkGetConfig: false,
  sessionId: '',
  snack: {
    open: false,
    message: '',
    variant: 'success',
  },
  urlName: 'unknown',
  animButtonStates: AnimButtonStatesEnum.NORMAL,
  lastOriginalText: '',
  wsAction: IWsAction.OPEN,
  entitiesKeysToMerge: [],
  validRedirect: false,
  dashboardFilters: [],
  addColumnsMenu: { anchor: null, id: 'string' },
  dashboardExpendedRow: null,
  sessionsLabels: [],
  dashboardRefresh: DashboardRefreshState.NONE,
  dashboardSort: { columnName: 'null', type: "ASC" },
  dashboardPagination: {
    offset: 0,
    pageNumber: 0,
    pageSize: 25,
    paged: false,
    totalElements: 0,
  },
  dashboardFilterCommands: {
    showDeletedSessions: true,
  },
  dashboardHeader: null,
  dashboardTableWidth: null,
  dashboardTableRefresh: DashboardRefreshState.NONE,
  dashboardHeaderWidths: null,
  activeConfiguration: {
    serviceProvider: null,
    language: null,
    domain: null,
    model: null,
  },
  activePunctuator: {
    language: null,
    domain: null,
    model: null,
    toggled: false,
  },
  activeTrueCaser: {
    language: null,
    domain: null,
    model: null,
    toggled: false,
  },
  activeInverseTextNormalizator: {
    language: null,
    domain: null,
    model: null,
    toggled: false,
  },
  editorLock: {
    sessionLockKey: null,
    recordingId: null,
    sessionId: null,
    refreshAfter: null,
    editTicket: null,
    versionName: null,
  },
  activeQuota: null,
  consumptionModal: {
    visible: false,
    template: undefined,
  },
  speakerUpdated: false,
  diarization: false,
  triggerStopRecording: false,
  speakerSettingsModal: {
    visible: false,
    numberOfSpeakers: null,
    minNumberOfSpeakers: null,
    maxNumberOfSpeakers: null,
    retranscription: false,
  },
  tableScrollPosition: 0,
  isDashboardLoading: false,
  configuration: {},
};

export const appSlice = createSlice({
  name: 'app',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setHomeFlowState: (state, action: PayloadAction<IHomeFlowStates>) => {
      state.homeFlowStates = action.payload
    },
    setDashboardFilterCommands: (state, action: PayloadAction<IDashboardFilterCommands>) => {
      state.dashboardFilterCommands = action.payload
    },
    setDictionary: (state, action: PayloadAction<Partial<IDictionary>>) => {
      state.dictionary = {...state.dictionary, ...action.payload}
    },
    setWordsInDict: (state, action: PayloadAction<IAddToDictWord[] | undefined>) => {
      state.dictionary.words = action.payload
    },
    setAddPronaun: (state, action: PayloadAction<{ pronaun: IPronunciation; wordIndex: number }>) => {
      let wordToSave: null | IAddToDictWord = null;

      const newWords = state.dictionary.words?.map((w, i) => {
        if (i === action.payload.wordIndex) {
          wordToSave = { ...w, pronunciations: [...w.pronunciations, action.payload.pronaun] };
          return wordToSave;
        }
        return w;
      });

      state.dictionary.words = newWords;
      state.dictionary.autoSave = wordToSave;
    },
    setManuallyUpdateEditorState: (state, action: PayloadAction<IUpdateEditorState>) => {
      state.manuallyUpdateEditorState = action.payload
    },
    setValidRedirect: (state, action: PayloadAction<boolean>) => {
      state.validRedirect = action.payload
    },
    setEntitiesKeysToMerge: (state, action: PayloadAction<string[]>) => {
      state.entitiesKeysToMerge = action.payload
    },
    setWsAction: (state, action: PayloadAction<IWsAction>) => {
      state.wsAction = action.payload
    },
    setDoDictation: (state, action: PayloadAction<boolean>) => {
      state.doDictation = action.payload
    },
    setLoadContentToEditor: (state, action: PayloadAction<ILoadContentToEditor>) => {
      state.loadContentToEditor = action.payload
    },
    setYoutubeLink: (state, action: PayloadAction<string | null>) => {
      state.youtubeLink = action.payload
    },
    setFontsLoaded: (state, action: PayloadAction<boolean>) => {
      state.fontsLoaded = action.payload
    },
    setTbFormat: (state, action: PayloadAction<ITbFormat>) => {
      state.tbFormat = action.payload;
    },
    setIsTranscript: (state, action: PayloadAction<boolean>) => {
      state.isTranscript = action.payload
    },
    setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload
    },
    setCurrentTime: (state, action: PayloadAction<number>) => {
      state.currentTime = action.payload
    },
    setUploadedFileDuration: (state, action: PayloadAction<number | null>) => {
      state.uploadedFileDuration = action.payload
    },
    setAudioInfo: (state, action: PayloadAction<Partial<IAudioInfo>>) => {
      state.audioInfo = {...state.audioInfo, ...action.payload}
    },
    setIsAudioPlaying: (state, action: PayloadAction<boolean>) => {
      state.isAudioPlaying = action.payload
    },
    setShowConfidence: (state, action: PayloadAction<boolean>) => {
      state.showConfidence = action.payload
    },
    setEditorMode: (state, action: PayloadAction<EditorModeEnums | null>) => {
      state.editorMode = action.payload
    },
    setClickedTime: (state, action: PayloadAction<number | null>) => {
      state.clickedTime = action.payload
    },
    setFontSize: (state, action: PayloadAction<string>) => {
      state.fontSize = action.payload
    },
    setHeaderIsExtended: (state, action: PayloadAction<boolean>) => {
      state.headerIsExtended = action.payload
    },
    setSessionName: (state, action: PayloadAction<string>) => {
      state.sessionName = action.payload
    },
    setIsSessionDiscarded: (state, action: PayloadAction<boolean>) => {
      state.isSessionDiscarded = action.payload
    },
    setUrlName: (state, action: PayloadAction<string>) => {
      state.urlName = action.payload
    },
    setActiveWordId: (state, action: PayloadAction<string>) => {
      state.activeWordId = action.payload
    },
    setMakeBlocks: (state, action: PayloadAction<boolean>) => {
      state.makeBlocks = action.payload
    },
    setSaveToDisk: (state, action: PayloadAction<boolean>) => {
      state.saveToDisk = action.payload
    },
    setUser: (state, action: PayloadAction<IUser | null>) => {
      state.user = action.payload
    },
    setAuthOkGetConfig: (state, action: PayloadAction<boolean>) => {
      state.authOkGetConfig = action.payload
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload
    },
    setAnimButtonState: (state, action: PayloadAction<AnimButtonStatesEnum>) => {
      state.animButtonStates = action.payload
    },
    setLastOriginalText: (state, action: PayloadAction<string>) => {
      state.lastOriginalText = action.payload
    },
    setSnack: (state, action: PayloadAction<ISnack>) => {
      state.snack = action.payload
    },
    setDashboardFilters: (state, action: PayloadAction<IAppliedFilter[]>) => {
      state.dashboardFilters = action.payload
    },
    setAddColumnAnchor: (state, action: PayloadAction<IAddColumnMenu>) => {
      state.addColumnsMenu = action.payload as any //TODO: FIGURE THIS ONE OUT
    },
    setDashboardExpendedRow: (state, action: PayloadAction<number>) => {
      state.dashboardExpendedRow = action.payload
    },
    setSessionsLabels: (state, action: PayloadAction<ISessionLabel[]>) => {
      state.sessionsLabels = action.payload
    },
    setDashboardRefresh: (state, action: PayloadAction<DashboardRefreshState>) => {
      state.dashboardRefresh = action.payload
    },
    setDashboardSort: (state, action: PayloadAction<ISortObject>) => {
      state.dashboardSort = action.payload
    },
    setDashboardPagination: (state, action: PayloadAction<Partial<IPageable>>) => {
      state.dashboardPagination = {...state.dashboardPagination, ...action.payload}
    },
    setDashboardHeader: (state, action: PayloadAction<IHeader>) => {
      state.dashboardHeader = action.payload
    },
    resetDashboardHeader: (state, action: PayloadAction<IHeader>) => {
      state.dashboardHeader = action.payload
    },
    setWordPronaun: (state, action: PayloadAction<IPronoun>) => {
      let wordToSaveDE: null | IAddToDictWord = null;
      const wordI = action.payload.wordIndex || 0;
      const pI = action.payload.pronaunIndex || 0;
      const newP = action.payload.pronaun;

      if (state.dictionary.words) {
        const newWords = state.dictionary.words.map((w, i) => {
          if (i === wordI) {
            const nwS = {
              ...w,
              pronunciations: newP
                ? w.pronunciations.map((p, j) => {
                    if (j === pI) {
                      return newP;
                    }
                    return p;
                  })
                : w.pronunciations.filter((p, k) => k !== pI),
            };
            wordToSaveDE = { ...nwS };
            return nwS;
          }
          return w;
        });

        state.dictionary = {...state.dictionary, words: newWords, autoSave: wordToSaveDE}
      }
    },
    setDashboardTableWidth: (state, action: PayloadAction<number>) => {
      state.dashboardTableWidth = action.payload
    },
    setDashboardTableRefresh: (state, action: PayloadAction<DashboardRefreshState>) => {
      state.dashboardTableRefresh = action.payload
    },
    setDashboardHeaderWidths: (state, action: PayloadAction<IHeaderWidth>) => {
      state.dashboardHeaderWidths = action.payload
    },
    setSpeakersModal: (state, action: PayloadAction<ISpeakersModal>) => {
      state.speakersModal = action.payload
    },
    setActiveConfiguration: (state, action: PayloadAction<IActiveConfiguration>) => {
      state.activeConfiguration = action.payload
      //updateActiveConfiguration(action.payload)
      import('../../../api/AuthenticationService').then(module => {
        module.updateActiveConfiguration(action.payload);
      });
    },
    setActivePunctuator: (state, action: PayloadAction<IPunctuator>) => {
      state.activePunctuator = action.payload
      //updateActivePunctuator(action.payload)
      import('../../../api/AuthenticationService').then(module => {
        module.updateActivePunctuator(action.payload);
      });
    },
    setActiveTruecaser: (state, action: PayloadAction<ITrueCaser>) => {
      state.activeTrueCaser = action.payload
      //updateActiveTrueCaser(action.payload)
      import('../../../api/AuthenticationService').then(module => {
        module.updateActiveTrueCaser(action.payload);
      });
    },
    setActiveInverseTextNormalizator: (state, action: PayloadAction<IInverseTextNormalizator>) => {
      state.activeInverseTextNormalizator = action.payload
      import('../../../api/AuthenticationService').then(module => {
        module.updateActiveInverseTextNormalizer(action.payload);
      });
    },
    setEditorLock: (state, action: PayloadAction<IEditorLock>) => {
      state.editorLock = action.payload
    },
    setActiveQuota: (state, action: PayloadAction<IActiveUserQuotaChunk | null>) => {
      state.activeQuota = action.payload
    },
    setConsumptionModal: (state, action: PayloadAction<IConsumtionModal>) => {
      state.consumptionModal = action.payload
    },
    setDiarization: (state, action: PayloadAction<boolean>) => {
      state.diarization = action.payload
    },
    setTriggerStopRecording: (state, action: PayloadAction<boolean>) => {
      state.triggerStopRecording = action.payload
    },
    setSpeakerSettingsModal: (state, action: PayloadAction<Partial<ISpeakersSettingsModal>>) => {
      state.speakerSettingsModal = {
        ...state.speakerSettingsModal,
        ...action.payload
      }
    },
    setTableScrollPosition: (state, action: PayloadAction<number>) => {
      state.tableScrollPosition = action.payload
    },
    setIsDashboardLoading: (state, action: PayloadAction<boolean>) => {
      state.isDashboardLoading = action.payload
    },
    setConfiguration: (state, action: PayloadAction<ISttPipelines>) => {
      state.configuration = action.payload
    }
  }
})


export const { 
  setHomeFlowState,
  setDashboardFilterCommands,
  setDictionary,
  setWordsInDict,
  setAddPronaun,
  setManuallyUpdateEditorState,
  setValidRedirect,
  setEntitiesKeysToMerge,
  setWsAction,
  setDoDictation,
  setLoadContentToEditor,
  setYoutubeLink,
  setFontsLoaded,
  setTbFormat,
  setIsTranscript,
  setIsPlaying,
  setCurrentTime,
  setUploadedFileDuration,
  setAudioInfo,
  setIsAudioPlaying,
  setShowConfidence,
  setEditorMode,
  setClickedTime,
  setFontSize,
  setHeaderIsExtended,
  setSessionName,
  setIsSessionDiscarded,
  setUrlName,
  setActiveWordId,
  setMakeBlocks,
  setSaveToDisk,
  setUser,
  setAuthOkGetConfig,
  setSessionId,
  setAnimButtonState,
  setLastOriginalText,
  setSnack,
  setDashboardFilters,
  setAddColumnAnchor,
  setDashboardExpendedRow,
  setSessionsLabels,
  setDashboardRefresh,
  setDashboardSort,
  setDashboardPagination,
  setDashboardHeader,
  resetDashboardHeader,
  setDashboardTableWidth,
  setDashboardTableRefresh,
  setDashboardHeaderWidths,
  setSpeakersModal,
  setActiveConfiguration,
  setActivePunctuator,
  setActiveTruecaser,
  setActiveInverseTextNormalizator,
  setEditorLock,
  setActiveQuota,
  setConsumptionModal,
  setDiarization,
  setTriggerStopRecording,
  setWordPronaun,
  setSpeakerSettingsModal,
  setTableScrollPosition,
  setIsDashboardLoading,
  setConfiguration
} = appSlice.actions