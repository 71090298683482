import React, { useEffect } from 'react';
import { useDimensions } from '../../hooks/useDimensions';
import ContextString from './ContextString';
import Skeleton from '@mui/material/Skeleton';
import { useAppDispatch } from '../../redux/store';
import { setDictionary } from '../../redux/features/app/app';

export interface IContextString {
  contextLeft: string;
  mainPhrase: string;
  contextRight: string;
}
interface Context {
  contextPercentage: string;
  contextString: IContextString | null;
}

interface Props {
  contexts: Context[] | null;
  isLoading: boolean;
  pageSize?: number;
}
const rowHeight = 32;
export default function ContextLine({ contexts, isLoading, pageSize }: Props) {
  const { ref, dimensions } = useDimensions();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!dimensions || dimensions.top === 0) return;
    const setHeight = (e: any) => {
      const top = dimensions.top;
      const wH = window.innerHeight;
      const diff = wH - top - 60 - 32;
      const newSize = Math.floor(diff / rowHeight);

      dispatch(setDictionary({ pageSize: newSize }));
    };
    setHeight(null);

    window.addEventListener('resize', setHeight);

    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, [dimensions, dispatch]);

  return (
    <div
      className="contexts_lines_wrapper show_scrollbar"
      ref={ref}
      // style={{ overflowX: 'auto', overflowY: 'hidden' }}
    >
      {!isLoading && contexts === null && (
        <span className="ctx_string">
          Za iskanje po korpusu vpišite željeno frazo in potrdite izbiro s pritiskom na Enter
        </span>
      )}
      {!isLoading && contexts && contexts.length === 0 && (
        <span className="ctx_string">Našli nismo nobenega zadetka</span>
      )}
      {/* {isLoading &&
        new Array(pageSize || 5).fill(null).map((_, i) => (
          <div className="context_line_wrapper" key={i.toString()}>
            <span className="ctx_percentage">
              <Skeleton variant="text" width={25} height={23} />
            </span>

            <p className="ctx_string">
              <Skeleton variant="text" height={23} width={i % 2 === 0 ? 400 : 500} />
            </p>
          </div>
        ))} */}

      <div className="cxt_main_line_wrapper">
        <div className="ctx_main_percentages">
          {!isLoading &&
            contexts &&
            contexts.length > 0 &&
            contexts.map((ctx, i) => (
              <div
                className="context_line_wrapper"
                key={`${ctx.contextString?.contextLeft}${ctx.contextString?.contextRight}${ctx.contextString?.mainPhrase}`}
              >
                <span className="ctx_percentage">{ctx.contextPercentage}</span>
              </div>
            ))}
          {isLoading &&
            new Array(pageSize || 5).fill(null).map((_, i) => (
              <div className="context_line_wrapper" key={i.toString()} style={{}}>
                <span className="ctx_percentage">
                  <Skeleton variant="text" width={25} height={23} />
                </span>
              </div>
            ))}
        </div>
        <div
          className="cxt_main_line_wrapper_words show_scrollbar"
          style={{ overflowX: 'auto', overflowY: 'hidden' }}
        >
          {!isLoading &&
            contexts &&
            contexts.length > 0 &&
            contexts.map((ctx, i) => (
              <div
                className="context_line_wrapper"
                key={`${ctx.contextString?.contextLeft}${ctx.contextString?.contextRight}${ctx.contextString?.mainPhrase}`}
              >
                {ctx.contextString && <ContextString contextString={ctx.contextString} />}
              </div>
            ))}

          {isLoading &&
            new Array(pageSize || 5).fill(null).map((_, i) => (
              <div className="context_line_wrapper" key={i.toString()}>
                <p
                  className="ctx_string"
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0px',
                  }}
                >
                  <Skeleton variant="text" height={23} width={i % 2 === 0 ? 400 : 500} />
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
