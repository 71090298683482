import React from 'react';
import './../../styles/css/search.css';
type IProps = {
  label: string;
  index: number;
  deleteCallback: (index: number) => void;
};

const SearchToken = ({ label, index, deleteCallback }: IProps) => {
  return (
    <div className="searchToken" onClick={() => deleteCallback(index)}>
      <span> {label}</span>
    </div>
  );
};

export default SearchToken;
