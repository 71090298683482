import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export function PlayIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id="play_arrow-24px"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          id="Path_47"
          data-name="Path 47"
          d="M8,5V33L30,19Z"
          transform="translate(8 5)"
        />
        <path
          id="Path_48"
          data-name="Path 48"
          d="M0,0H48V48H0Z"
          fill="rgba(0,0,0,0)"
        />
      </svg>
    </SvgIcon>
  );
}
