import React, {
  useState,
  ChangeEvent,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import SuggestionDropdown from './SuggestionDropdown';
import useKeyPress from './../../hooks/useKeyPress';
import { FilterType, ISuggestionItem, SessionSource, SessionState } from './ISearch';
import { Day, DayPicker, DayProps  } from 'react-day-picker';
import CalendarLocalization from './calendarLocalization';

import './../../styles/css/search.css';
import 'react-day-picker/src/style.css';
import { getAllUsersSuggestions, getGroupNames, getLabelSuggestions, getSessionsNames } from '../../api/SessionsService';
import { debounce, DebouncedFunc } from 'lodash';
import Tooltip from '@mui/material/Tooltip';

interface IProps {
  text: string,
  updateText: (value: string, shouldCheckStart?: boolean) => void;
  suggestions: ISuggestionItem[];
  canSearch: boolean; // If we expect a filter value, we disable search
  deleteCallback: (setLastToken: boolean) => void;
  addTokenCallback: (index: number) => void;
  confirmCallback: (label: string) => void;
  updateSuggestions: (codes: ISuggestionItem[]) => void;
  regex: RegExp | null;
  strictMode: boolean;
  setIsShown: (isShown: boolean) => void;
  isShown: boolean;
  tokenFilterLabel: string | null,
  previousTokenFilterField: string | null,
  filterType: FilterType;
  isPreviousEnum: boolean
}

const dateFormatter = new Intl.DateTimeFormat('sl', {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
});

// A helper function that detects some similiraties in suggestions
// Could be improved, but it's not bad at the moment
// Example: "datvm" detects as "datum"
const stringSimilarity = (a: string, b: string) => {
  let equivalency = 0;
  const minLength = a.length > b.length ? b.length : a.length;
  const maxLength = a.length < b.length ? b.length : a.length;
  for (var i = 0; i < minLength; i++) if (a[i] === b[i]) equivalency++;
  return equivalency / maxLength;
};

const AutoSuggestInput = ({
  text,
  updateText,
  suggestions,
  canSearch,
  deleteCallback,
  addTokenCallback,
  confirmCallback,
  updateSuggestions,
  regex,
  strictMode,
  setIsShown,
  isShown,
  filterType,
  tokenFilterLabel,
  previousTokenFilterField,
  isPreviousEnum
}: IProps) => {
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [currentSuggestion, setCurrentSuggestion] = useState(suggestions);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(-1);
  const [showErrorTooltip, setShowErrorTooltip] = useState<boolean>(false);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  // References to DOM elements
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const datePickerRef = useRef<HTMLDivElement>(null);

  // Accesibility keys
  const arrowUpDown = useKeyPress({ targetKey: 'ArrowUp' });
  const arrowDownDown = useKeyPress({ targetKey: 'ArrowDown' });

  // When hitting enter or writing, if suggestions contain the input text, use the matching suggestion
  const checkIfTextInSuggestions = useCallback(
    (toCheck: string) => {
      const refedSuggestions = suggestions.map(({ label }) => label.toLowerCase());
      const refedText = toCheck.toLowerCase().replace(/^\s+|\s+$/g, '');
      const index = refedSuggestions.indexOf(refedText);
      return index !== -1;
    },
    [addTokenCallback, suggestions]
  );

  // When user clicks a day in date picker
  const onDayClick = (day: Date, modifiers: any = {}) => {
    if (modifiers.disabled) return;

    setActiveSuggestionIndex(-1);
    if (inputRef.current) inputRef.current.focus();

    updateText('');
    confirmCallback(dateFormatter.format(day));
  };

  // Helper function for getting actual index of suggestion from current suggestions
  const getActualSuggestionIndex = useCallback((): number => {
    const actualIndex = suggestions.indexOf(currentSuggestion[activeSuggestionIndex]);
    return actualIndex
  }, [suggestions, currentSuggestion, activeSuggestionIndex]);

  // Ctrl + K accesiblity open
  const accessibilityShortcut = useCallback(
    (e: any) => {
      if (e.code === 'Escape') setIsShown(false);

      if ((e.metaKey || e.ctrlKey) && e.code === 'KeyK') {
        e.preventDefault();
        setIsShown(true);
      }
    },
    [setIsShown]
  );

  // Ctrl + K mount
  useEffect(() => {
    document.addEventListener('keydown', accessibilityShortcut);
    return () => document.removeEventListener('keydown', accessibilityShortcut);
  }, [accessibilityShortcut]);

  // We show the dropdown suggestions menu, when the animation ends
  useEffect(() => {
    if (!isShown) {
      setShowSuggestions(false);
      inputRef.current?.blur();
      setShowErrorTooltip(false);
      updateText('');
    } else if (inputRef.current) {
      inputRef.current!.focus()
    }
  }, [isShown]);

  const fetchLabelSuggestion = async (label: string) => {
    let transformedLabel = `${previousTokenFilterField === "contains" ? "%25" : ""}${label}%25`
    const response = await getLabelSuggestions(transformedLabel)
    const codes: ISuggestionItem[] = response.data.map(label => {
      return ({
          label: label.code,
          field: 'value',
          nextType: FilterType.Generic
      })
    })
    debounceRef.current = null

    updateSuggestions(codes);
  };

  const fetchSessionNameSuggestions = async (name: string) => {
    let transformedName = `${previousTokenFilterField === "contains" ? "%25" : ""}${name}%25`
    const response = await getSessionsNames(transformedName, 10)

    const names: ISuggestionItem[] = response.data.map((sessionName) => {
      return {
        label: sessionName,
        field: 'value',
        nextType: FilterType.Generic,
      };
    });

    debounceRef.current = null;
    updateSuggestions(names);
  };

  const fetchUserSuggestions = async (user: string) => {
    let transformedUser = `${previousTokenFilterField === "contains" ? "%25" : ""}${user}%25`
    const response = await getAllUsersSuggestions(transformedUser, 100)
    const users: ISuggestionItem[] = response.data.map(user => {
      return ({
          label: user.username,
          field: 'value',
          nextType: FilterType.Generic
      })
    })
    debounceRef.current = null
    updateSuggestions(users)
  }

  const fetchGroupSuggestions = async (group: string) => {
    let transformedGroup = `${previousTokenFilterField === "contains" ? "%25" : ""}${group}%25`
    const response = await getGroupNames(transformedGroup, 100)
    const users: ISuggestionItem[] = response.data.map(group => {
      return ({
          label: group.name,
          field: 'value',
          nextType: FilterType.Generic
      })
    })
    debounceRef.current = null
    updateSuggestions(users)
  }

  
  
  const fetchSourceSuggestions = () => {
    const sources: ISuggestionItem[] = [];

    for (var enumSource in SessionSource) {
        sources.push({
          label: enumSource,
          field: 'value',
          nextType: FilterType.Generic
      })
    }
    debounceRef.current = null;
    updateSuggestions(sources);
  };

  const fetchSessionStateSuggestions = () => {
    const states: ISuggestionItem[] = []

    for (var enumSource in SessionState) {
        states.push({
          label: enumSource,
          field: 'value',
          nextType: FilterType.Generic
      })
    }
    debounceRef.current = null;
    updateSuggestions(states);
  };

  const determineEndpointToCall = (value: string) => {
    switch (tokenFilterLabel) {
      case 'Ime seje':
        fetchSessionNameSuggestions(value);
        break;
      case 'Labela':
        fetchLabelSuggestion(value);
        break;
      case 'Uporabnik':
        fetchUserSuggestions(value);
        break;
      case 'Skupina':
        fetchGroupSuggestions(value);
        break;
      default: throw new Error('[AutoSuggestInput.tsx]: Invalid filter label!')
    }
  }

  const determineFilterEnum = () => {
    switch (tokenFilterLabel) {
      case 'Vir':
        fetchSourceSuggestions()
        break;
      case 'Stanje seje':
        fetchSessionStateSuggestions()
        break;
      default: throw new Error('[AutoSuggestInput.tsx]: Invalid filter label!')
    }
  };

  const debounceRef = useRef<DebouncedFunc<(label: string) => Promise<void>> | null>(null);
  // Handler for input text changing (fires after onkeydown!)
  const handleTextChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!isShown || filterType === FilterType.Enum || filterType === FilterType.EnumFilter) return;
    const val = event.target.value;
    if (regex && !regex.test(val)) {
      setShowErrorTooltip(true);
      return;
    }

   if (filterType === FilterType.String) {
      if (debounceRef.current !== null) debounceRef.current.cancel()
      debounceRef.current = debounce(determineEndpointToCall, 1000)
      debounceRef.current(val)
    } else {
      const filteredSuggestions = suggestions.filter(suggestion => suggestion.label.toLocaleLowerCase().startsWith(val.toLowerCase()))
      setCurrentSuggestion(filteredSuggestions)
    }

    setActiveSuggestionIndex(-1)
    setShowErrorTooltip(false);
    updateText(val, true);
  }

  // Callback for when the Suggestion is clicked with the mouse inside the dropdown menu
  // We always try to set focus back to the input field though
  const handleSuggestionClick = (index: number): void => {
    if (!showSuggestions) return;
    if (index === -1) handleEnter();
    else {
      if (debounceRef.current !== null) {
        debounceRef.current.cancel()
        debounceRef.current = null
      }
      addTokenCallback(index);
      updateText('');
    }
    if (inputRef.current) inputRef.current.focus();
  };

  // Checks for special keystrokes and regex
  const handleKeydown = (e: any) => {
    if (e.key === 'Enter') {
      if (text.length === 0) {
        handleEnter();  
      } else {
        if (strictMode && !checkIfTextInSuggestions(text) && activeSuggestionIndex === -1) return;
        e.preventDefault();
        handleEnter();
      }
    } else if (e.key === 'Escape') {
      inputRef.current?.blur();
      e.preventDefault();
      setShowSuggestions(false);
    } else if (e.target.selectionStart === 0 && e.key === 'Backspace') {
      e.preventDefault();
      if (debounceRef.current !== null) {
        debounceRef.current.cancel()
        debounceRef.current = null
      }
      
      deleteCallback(!isPreviousEnum)
    }
  };

  // Set the new active suggestion if user hovers over it in dropdown menu
  const handleOnHover = (index: number): void => setActiveSuggestionIndex(index);

  // When the user hits enter
  const handleEnter = useCallback(() => {
    if (debounceRef.current !== null) {
      debounceRef.current.cancel()
      debounceRef.current = null
    }

    if (activeSuggestionIndex === -1 || suggestions.length === 0) confirmCallback(text);
    else addTokenCallback(getActualSuggestionIndex());
    updateText('');
  }, [
    suggestions.length,
    activeSuggestionIndex,
    text,
    confirmCallback,
    addTokenCallback,
    getActualSuggestionIndex,
    debounceRef.current
  ]);

  // Moves the hover (current active suggestion) up
  useEffect(() => {
    setActiveSuggestionIndex((oldIndex) => {
      return oldIndex - (arrowUpDown === 'down' && showSuggestions && oldIndex > -1 ? 1 : 0);
    });
  }, [arrowUpDown, showSuggestions]);

  // Moves the hover (current active suggestion) down
  useEffect(() => {
    setActiveSuggestionIndex((oldIndex) => {
      return (
        oldIndex +
        (arrowDownDown === 'down' && showSuggestions && oldIndex < currentSuggestion.length - 1 ? 1 : 0)
      );
    });
  }, [arrowDownDown, showSuggestions, currentSuggestion.length]);

  // Everytime the text is changed, re-evaluate current suggestions
  useEffect(() => {
    setCurrentSuggestion(() => {
      // Always default to search, when typing
      setActiveSuggestionIndex(-1);

      if (text === '') {
        setShowSuggestions(document.activeElement === inputRef.current && suggestions.length !== 0);
        return suggestions;
      }

      // If you can't search and there are no suggestions, show no suggestions
      setShowSuggestions(canSearch || suggestions.length !== 0);
      return suggestions;
    });
  }, [suggestions, canSearch]);

  // Event function for closing/ opening suggestions
  const handleWindowClick = useCallback(
    (event: any): void => {
      if (filterType === FilterType.Date) {
        setShowDatePicker(!!datePickerRef.current && datePickerRef.current.contains(event.target));
      }

      // If click outside of filter suggestions then close them
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) setShowSuggestions(false);

      // If click on input then show filter suggestions
      if (inputRef.current && inputRef.current.contains(event.target)) {
        setShowSuggestions(suggestions.length !== 0);
        setShowDatePicker(true);
      }
    },
    [suggestions.length, filterType]
  );

  useEffect(() => {
    if (filterType === FilterType.Date) {
      setShowDatePicker(true);
    }
  }, [filterType]);

  useEffect(() => {
    if (filterType === FilterType.String) {
      if (debounceRef.current !== null) debounceRef.current.cancel()
      debounceRef.current =  debounce(determineEndpointToCall, 1000)
      debounceRef.current("")
    } else if (filterType === FilterType.Enum) {
      determineFilterEnum();
    } else {
      const filteredSuggestions = suggestions.filter(suggestion => suggestion.label.startsWith(""))
      setCurrentSuggestion(filteredSuggestions)
    }
  }, [tokenFilterLabel]);
  // Adding (and cleaning up) event listener for closing/ opening suggestions
  useEffect(() => {
    document.addEventListener('mousedown', handleWindowClick);
    return () => document.removeEventListener('mousedown', handleWindowClick);
  }, [handleWindowClick]);

  const onTextInputFocus = () => setShowSuggestions(canSearch || currentSuggestion.length !== 0)

  return (
    <div className="inner-input-wrapper">
      <Tooltip open={showErrorTooltip} title="Znak zavrnjen" placement="bottom-start">
        <input
          type="text"
          value={text}
          onChange={handleTextChange}
          ref={inputRef}
          onKeyDown={handleKeydown}
          // onKeyPress={handleOnKeyPress}
          onFocus={onTextInputFocus}
          className="filter-input-field"
        />
      </Tooltip>

      {currentSuggestion.length === 0 && debounceRef.current === null && filterType === FilterType.String && (
        <div className={`suggestions active`}>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <h2>Ni zadetkov.</h2>
          </div>
        </div>
      )}

      {/*TODO: Start displaying when first char of the search value is entered.*/}
      {currentSuggestion.length > 0 && debounceRef.current === null && (
        <SuggestionDropdown
          suggestions={currentSuggestion}
          shown={showSuggestions}
          activeIndex={activeSuggestionIndex}
          forwardRef={suggestionsRef}
          clickCallback={handleSuggestionClick}
          hoverCallback={handleOnHover}
          canSearch={canSearch}
          text={text}
        />
      )}

      <div
        ref={datePickerRef}
        className={`day-picker-wrapper ${
          filterType === FilterType.Date && showDatePicker && 'day-picker-wrapper-active'
        }`}
      >
        {filterType === FilterType.Date && (
          <DayPicker
            onDayClick={onDayClick}
            {...CalendarLocalization}
            components={{
              Day: (props) => <CustomDay {...props} />
            }}
            disabled={[
              {
                after: new Date(),
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default AutoSuggestInput;


//TODO: Figure this out.
const formatDate = (date: Date): string => {
  return date.toDateString(); // 'Thu Aug 1 2024' format
}

const CustomDay = (props: DayProps) => <Day data-nameTest={formatDate(props.date)} {...props} />