import { CommandCodes, IDictationCommand, IDictationSubstitution } from './IEditor';

export const fixedKeys = {
  1: ['1', 'ena'],
  2: ['2', 'dva', 'dve'],
  3: ['3', 'tri'],
  4: ['4', 'štiri'],
  5: ['5', 'pet'],
  6: ['6', 'šest'],
  7: ['7', 'pet'],
  8: ['8', 'osem'],
  9: ['9', 'devet'],
  10: ['10', 'deset'],
  11: ['11', 'enajst'],
  12: ['12', 'dvanajst'],
  13: ['13', 'trinajst'],
  14: ['14', 'štirinajst'],
  15: ['15', 'petnajst'],
  16: ['16', 'šestnajst'],
  17: ['17', 'sedemnajst'],
  18: ['18', 'osemnajst'],
  19: ['19', 'devetnajst'],
  20: ['20', 'dvajset'],
};

export const cArr = ['.', '!', '?'];
export const cArrS = ['<.>', '<!>', '<?>'];
export const allSubsStringsConverted = [
  '.',
  '!',
  '?',
  ',',
  '(',
  ')',
  '—',
  '-',
  '=',
  'x',
  ';',
  ':',
  ' ',
  '°',
  '%',
];
export const allSubsString = [
  '<.>',
  '<!>',
  '<?>',
  '<,>',
  '<->',
  '<—>',
  '<=>',
  '<(>',
  '<)>',
  '</>',
  '<;>',
  '<:>',
  '<%>',
  '<x>',
  '<°>',
  '<spc>',
];

export const abbreviations = ["oz.", "gdč.", "ga.", "g.", "cca.", "d.o.o.", "d.n.o.", "d.d.", "t.i.", "npr.", "itn.", "itd.", "prof.", "dr.", "mag.", "as.", "izr.", "prim."]

export const mainModeCommandsStrings = [
  '<uc>',
  '</uc>',
  '<nl>',
  '<np>',
  '<cc>',
  '<lc>',
  '</lc>',
  '<delw>',
  '<delp>',
  '<dels>',
  '<corr>',
  '<find>',
  '<ins>',
  '<spell>',
  '<b>',
  '</b>',
  '<i>',
  '</i>',
  '<u>',
  '</u>',
  '<B>',
  '<I>',
  '<U>',
  '<next>',
  '<prev>',
  '<input>',
];

export const hardSubs: IDictationSubstitution[] = [
  {
    from: '<,>',
    to: ',',
    leftHanded: true,
    rightHanded: false,
    keepText: false,
    regex: /<,>/,
  },
  {
    from: '<.>',
    to: '.',
    leftHanded: true,
    rightHanded: false,
    keepText: false,
    regex: /<\.>/,
  },
  {
    from: '<spc>',
    to: ' ',
    leftHanded: true,
    rightHanded: false,
    keepText: false,
    regex: null,
  },
  {
    from: '<x>',
    to: 'x',
    leftHanded: false,
    rightHanded: false,
    keepText: false,
    regex: /<x>/,
  },
  {
    from: '<%>',
    to: '%',
    leftHanded: true,
    rightHanded: false,
    keepText: false,
    regex: /<%>/,
  },
  {
    from: '<°>',
    to: '°',
    leftHanded: true,
    rightHanded: false,
    keepText: false,
    regex: /<°>/,
  },
  {
    from: '<?>',
    to: '?',
    leftHanded: true,
    rightHanded: false,
    keepText: false,
    regex: /<\?>/,
  },
  {
    from: '<!>',
    to: '!',
    leftHanded: true,
    rightHanded: false,
    keepText: false,
    regex: /<!>/,
  },
  {
    from: '<->',
    to: '-',
    leftHanded: true,
    rightHanded: true,
    keepText: false,
    regex: /<->/,
  },
  {
    from: '<—>',
    to: '—',
    leftHanded: false,
    rightHanded: false,
    keepText: false,
    regex: /<—>/,
  },
  {
    from: '<(>',
    to: '(',
    leftHanded: false,
    rightHanded: true,
    keepText: false,
    regex: /<\(>/,
  },
  {
    from: '<)>',
    to: ')',
    leftHanded: true,
    rightHanded: false,
    keepText: false,
    regex: /<\)>/,
  },
  {
    from: '</>',
    to: '/',
    leftHanded: true,
    rightHanded: true,
    keepText: false,
    regex: /<\/>/,
  },
  {
    from: '<:>',
    to: ':',
    leftHanded: true,
    rightHanded: false,
    keepText: false,
    regex: /<:>/,
  },
  {
    from: '<;>',
    to: ';',
    leftHanded: true,
    rightHanded: false,
    keepText: false,
    regex: /<;>/,
  },
  {
    from: '<=>',
    to: '=',
    leftHanded: false,
    rightHanded: false,
    keepText: false,
    regex: /<=>/,
  },
];

export const commands: IDictationCommand[] = [
  {
    keyword: '<np>',
    command: CommandCodes.NEW_PARAGRAPH,
  },
  {
    keyword: '<nl>',
    command: CommandCodes.NEW_LINE,
  },
  {
    keyword: '<uc>',
    command: CommandCodes.UPPER_CASE,
  },
  {
    keyword: '</uc>',
    command: CommandCodes.END_UPPER_CASE,
  },
  {
    keyword: '<cc>',
    command: CommandCodes.CAPITALIZE_NEXT,
  },
  {
    keyword: '<lc>',
    command: CommandCodes.LOWER_CASE,
  },
  {
    keyword: '</lc>',
    command: CommandCodes.END_LOWER_CASE,
  },
  {
    keyword: '<delw>',
    command: CommandCodes.DELETE_WORD,
  },
  {
    keyword: '<dels>',
    command: CommandCodes.DELETE_SENTENCE,
  },
];


