import React, { useCallback, useEffect } from 'react';
import Main from './components/Main/Main';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import './styles/css/App.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import useLoadFonts from './hooks/useLoadFonts';
import useLocalStorage from './hooks/useLocalStorage';
import useAxiosErrorListener from './hooks/useAxiosErrorListener';
import { ConsumtionModalTemplate } from './redux/store/IStore';
import { getActiveQuota, getUserQuota, transformConsumedAndLimitToReadable, transformLimitToReadable, translateChunkKey } from './api/UserService';
import useInterval from './hooks/useInterval';
import Modal from './components/Home/Modal';
import useKeycloak from './hooks/useKeycloak';
import './app.css'
import { useAppDispatch, useAppSelector } from './redux/store';
import { setActiveQuota, setConsumptionModal } from './redux/features/app/app';

const App: React.FC = () => {
  useLoadFonts();
  useLocalStorage();

  const dispatch = useAppDispatch();
  const {
    visible,
    template
  } = useAppSelector((state) => state.app.consumptionModal)
  const activeQuota = useAppSelector((state) => state.app.activeQuota)

  const {
    isAuthenticated
  } = useKeycloak();

  const hideConsumptionModal = () => dispatch(setConsumptionModal({
    visible: false,
    template: undefined,
  }))
  const fetchQuota = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const quota = await getUserQuota()
        dispatch(setActiveQuota(getActiveQuota(quota.data)))
      } catch (error) {
        console.log(error)
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    fetchQuota()
  }, [isAuthenticated])

  useInterval(() => {
    fetchQuota();
  }, 15000, [isAuthenticated])

  return (
    <ErrorBoundary>
      <Main />
      <Modal visible={visible} closeModal={hideConsumptionModal} title="Omejitev uporabe">
        {activeQuota && activeQuota.chunk.secondsLimit && (
          <div className='consumption-modal-content-container'>
            {template === ConsumtionModalTemplate.LIMIT && (
              <>
                <p className='consumption-modal-bold-text'>Storitev je (začasno) onemogočena.</p>
                <p className='consumption-modal-regular-text'>{`Presegli ste dogovorjeno količino časa uporabe storitve (${transformLimitToReadable(activeQuota.key, activeQuota.chunk.secondsLimit ? activeQuota.chunk.secondsLimit : 0)}).`}</p>
                <p className='consumption-modal-regular-text'>Za dodatne informacije pišite na <a className='consumption-modal-email-text' href="mailto:info@vitasis.si">info@vitasis.si</a>.</p>
              </>
            ) || template === ConsumtionModalTemplate.UPLOAD_LIMIT && (
              <>
                <p className='consumption-modal-bold-text'>{`Dosegli ste ${(activeQuota.chunk.secondsConsumed / activeQuota.chunk.secondsLimit * 100).toFixed(2)}% vaše ${translateChunkKey(activeQuota.key)} zakupljene količine, oz. ${transformConsumedAndLimitToReadable(activeQuota.chunk.secondsConsumed, activeQuota.chunk.secondsLimit)}.`}</p>
                <p className='consumption-modal-regular-text'>Z izbrano datoteko bi zakupljene količine presegli, zato storitev ni mogoča.</p>
                <p className='consumption-modal-regular-text'>Izberite krajšo datoteko oz. za dodatne informacije pišite na <a className='consumption-modal-email-text' href="mailto:info@vitasis.si">info@vitasis.si</a>.</p>
              </>
            ) || (
                <>
                  <p className='consumption-modal-bold-text'>{`Dosegli ste ${(activeQuota.chunk.secondsConsumed / activeQuota.chunk.secondsLimit * 100).toFixed(2)}% vaše ${translateChunkKey(activeQuota.key)} zakupljene količine, oz. ${transformConsumedAndLimitToReadable(activeQuota.chunk.secondsConsumed, activeQuota.chunk.secondsLimit)}.`}</p>
                  <p className='consumption-modal-regular-text'>Ob presegu dogovorjene količine bo storitev (začasno) onemogočena.</p>
                  <p className='consumption-modal-regular-text'>Za dodatne informacije pišite na <a className='consumption-modal-email-text' href="mailto:info@vitasis.si">info@vitasis.si</a>.</p>
                </>
              )}
          </div>
        ) || <></>}
      </Modal>
    </ErrorBoundary>
  );
};

export default App;