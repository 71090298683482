import React, { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getWordsAndPronaunsByText,
  IAddToDictWord,
  postWord,
} from '../../api/DictionaryService';

import Sync from '@mui/icons-material/Sync';
import { IStore } from '../../redux/store/IStore';
import { ABIcon, BigABIcon, IconNumbers, SignsIcon, SmallABIcon, TwoFAIcon } from '../Icons/DictionaryIcons';
import OptionsLine from './OptionsLine';
import WordLine from './WordLine';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setDictionary, setWordsInDict } from '../../redux/features/app/app';

async function postWords(words: IAddToDictWord[], tag: string) {
  return Promise.all(
    words
      .filter((w) => w.pronunciations.length)
      .map((word) =>
        postWord({
          word: {
            text: word.text,
            frequencyClassId: word.frequencyClassId || 3,
            pronunciations: word.pronunciations.map((p) => {
              return {
                text: p.text,
              };
            }),
          },
          tag
          ,
        })
      )
  );
}

export default function Leksicon() {
  // const [phones, setPhones] = useState<IPhonems | null>(null);
  const dispatch = useAppDispatch();

  const {
    language,
    domain,
    model
  } = useAppSelector(store => store.app.activeConfiguration)
  /*const { data, error, isLoading, isFetching } = useQuery(
    'dmodel-stats',
    () => {
      return model && getDmodelStats(modelData);
    },
    { enabled: !!modelData }
  );*/

  /*const { data: phones } = useQuery(
    `dmodel-phones-${modelData?.language}-${modelData?.domain}-${modelData?.modelVersion}`,
    () => {
      return modelData && getPhonems(modelData);
    },
    { enabled: !!modelData }
  );*/

  const { enqueueSnackbar } = useSnackbar();

  const { searchedString, caseSensitive, words, autoSave, searchBy } = useAppSelector(state => state.app.dictionary);

  const {
    data: wordsData,
    isLoading: isLoadingWords,
    isIdle: isIdleWords,
    isError: isWordsError,
    refetch,
  } = useQuery(
    `leksicon-text-${searchedString || ''}-${caseSensitive}-${searchBy}`,
    () => {
      if (!language || !domain || !model || !searchedString) return null;

      return getWordsAndPronaunsByText(searchedString, `${language}:${domain}:${model.name}`, searchBy);
    },
    { enabled: !!model && !!searchedString }
  );

  // const { data: freqData } = useQuery(`freq-classes`, () => {
  //   return getFrequencyClasses();
  // });

  const queryClient = useQueryClient();
  const { mutate: mutateWord } = useMutation(postWord, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(`leksicon-text-${searchedString || ''}-${caseSensitive}-${searchBy}`);
      enqueueSnackbar(`Beseda uspešno posodobljena`, { variant: 'success' });
    },
    onError: (error) => {
      const mappedError = error as any
      if (mappedError.response.status === 403) {
        enqueueSnackbar("Za delo s slovarjem nimate ustreznih pravic", { variant: 'error' })
      } else if (mappedError.response.status === 404) {
        enqueueSnackbar("Slovar za izbrani model ni na voljo.", { variant: 'error' })
      } else if (mappedError.response.status === 409) {
        enqueueSnackbar(`Beseda ${searchedString} je že v slovarju.`, { variant: 'error' })
      } else {
        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
      }
      
      queryClient.invalidateQueries(`leksicon-text-${searchedString || ''}-${caseSensitive}-${searchBy}`);
    },
  });

  const handleSyncWords = async () => {
    if (!words) return;
    let isOk = true;
    let indexesOfWrong: number[] = [];
    // const isIn = freqData?.data.find((fc) => fc.id === dictionaryWord?.frequencyClassId);
    // if (!dictionaryWord?.frequencyClassId || dictionaryWord.frequencyClassId < 0 || !isIn) {
    //   enqueueSnackbar('Za uspešno shranjevanje izberite frekvenco.', { variant: 'error' });
    //   isOk = false;
    //   return;
    // }

    // if (!pronunciations || pronunciations.length === 0) {
    //   enqueueSnackbar('Za shranjevanje besede mora biti vnešena vsaj ena izgovorjava.', { variant: 'error' });
    //   return;
    // }
    /*if (phones?.data) {
      words.forEach((w) => {
        w.pronunciations.forEach((p, i) => {
          if (phones?.data && p.text.length > 0) {
            if (!checkPronaunciationWithPhonems(p.text, phones.data)) {
              indexesOfWrong = [...indexesOfWrong, i];
            }
          }
          if (!p.text || p.text === '') {
            isOk = false;
          }
        });
      });
    }*/

    if (indexesOfWrong.length > 0) {
      // setPronIndexesFailed(indexesOfWrong);

      enqueueSnackbar('Za uspešno shranjevanje preverite izgovorjave.', {
        variant: 'error',
      });
      return;
    }

    if (!isOk || !language || !domain || !model) {
      enqueueSnackbar('Za shranjevanje besede in izgovorjav izpolnite vsa polja.', {
        variant: 'error',
      });
      return;
    }

    try {
      const postWordResponse = await postWords(words, `${language}:${domain}:${model.name}`);
      if (postWordResponse.length) {
        refetch();
        enqueueSnackbar('Besede uspešno posodobljene.', {
          variant: 'success',
        });
      }
    } catch (error) {
      const mappedError = error as any
      if (mappedError.response.status === 403) {
        enqueueSnackbar("Za delo s slovarjem nimate ustreznih pravic", { variant: 'error' })
      } else if (mappedError.response.status === 404) {
        enqueueSnackbar("Slovar za izbrani model ni na voljo.", { variant: 'error' })
      } else if (mappedError.response.status === 409) {
        enqueueSnackbar(`Beseda ${searchedString} je že v slovarju.`, { variant: 'error' })
      } else {
        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
      }
    }
  };

  useEffect(() => {
    if (!autoSave) return;
    dispatch(setDictionary({ autoSave: null }));

    let isOk = true;
    let indexesOfWrong: number[] = [];
    // const isIn = freqData?.data.find((fc) => fc.id === dictionaryWord?.frequencyClassId);
    // if (!dictionaryWord?.frequencyClassId || dictionaryWord.frequencyClassId < 0 || !isIn) {
    //   enqueueSnackbar('Za uspešno shranjevanje izberite frekvenco.', { variant: 'error' });
    //   isOk = false;
    //   return;
    // }

    // if (!pronunciations || pronunciations.length === 0) {
    //   enqueueSnackbar('Za shranjevanje besede mora biti vnešena vsaj ena izgovorjava.', { variant: 'error' });
    //   return;
    // }

    if (!autoSave.pronunciations.length || !autoSave.pronunciations) {
      enqueueSnackbar('Za uspešno shranjevanje besede mora biti izpolnjena vsaj ena izgovorjava', {
        variant: 'error',
      });
      refetch();
      return;
    }

    /*if (phones?.data) {
      autoSave.pronunciations.forEach((p, i) => {
        if (phones.data && p.text.length > 0) {
          if (!checkPronaunciationWithPhonems(p.text, phones.data)) {
            indexesOfWrong = [...indexesOfWrong, i];
          }
        }
        if (!p.text || p.text === '') {
          isOk = false;
        }
      });
    }*/

    if (indexesOfWrong.length > 0) {
      // setPronIndexesFailed(indexesOfWrong);
      enqueueSnackbar('Za uspešno shranjevanje preverite izgovorjave in uporabljene znake.', {
        variant: 'error',
      });
      refetch();
      return;
    }

    if (!isOk || !model) {
      enqueueSnackbar('Za shranjevanje besede in izgovorjav izpolnite vsa polja.', {
        variant: 'error',
      });
      refetch();
      return;
    }

    if (model) {
      mutateWord({
        word: {
          frequencyClassId: autoSave.frequencyClassId || 3,
          text: autoSave.text,
          pronunciations: autoSave.pronunciations.map((p) => {
            return {
              text: p.text,
            };
          }),
        },
        tag: `${language}:${domain}:${model.name}`,
      });
    }
  }, [autoSave, model, dispatch, mutateWord, enqueueSnackbar, refetch]);

  useEffect(() => {
    dispatch(setWordsInDict(wordsData?.data));
  }, [wordsData, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setDictionary({ searchBy: 'word' }));
    };
  }, [dispatch]);

  return (
    <div className="dictionary_section_wrapper">
      <OptionsLine
        isLoading={false}
        titles={[
          { text: 'Leksikon' },
          /*{ text: `${data?.data?.numPronunciations} izgovorjav` },
          { text: `${data?.data?.numWords} besed` },*/
        ]}
        mainCommands={[
          {
            children: <IconNumbers />,
            onClick: () => console.log('123 click'),
            disabled: true,
          },
          {
            children: <SmallABIcon />,
            onClick: () => console.log('123 click'),
            disabled: true,
          },
          {
            children: <BigABIcon />,
            onClick: () => console.log('123 click'),
            disabled: true,
          },
          {
            children: <SignsIcon />,
            onClick: () => console.log('123 click'),
            disabled: true,
          },

          {
            children: <TwoFAIcon />,
            onClick: () => console.log('123 click'),
            disabled: true,
          },
        ]}
        middleCommands={[
          {
            isOn: caseSensitive,
            children: <ABIcon />,
            onClick: () => dispatch(setDictionary({ caseSensitive: !caseSensitive })),
          },
          {
            isOn: false,
            children: <Sync className="sync_dict_leksicon" />,
            onClick: handleSyncWords,
            separate: true,
          },
        ]}
      />

      <WordLine words={words} isLoading={isLoadingWords} isError={isWordsError} isIdle={isIdleWords} />
    </div>
  );
}
