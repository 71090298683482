import { throttle } from 'lodash';
import { useState, useEffect, useRef, useCallback } from 'react';
import { ILoopModeOpts } from '../components/Editor/IEditor';
import {
  setCurrentTime,
  setClickedTime,
  setEditorMode,
  setAudioInfo,
  setIsAudioPlaying,
} from '../redux/features/app/app';
import { EditorModeEnums, IStore } from '../redux/store/IStore';
import { useAppDispatch, useAppSelector } from '../redux/store';

// READY STATES:
// HAVE_NOTHING	0	No information is available about the media resource.
// HAVE_METADATA	1	Enough of the media resource has been retrieved that the metadata attributes are initialized. Seeking will no longer raise an exception.
// HAVE_CURRENT_DATA	2	Data is available for the current playback position, but not enough to actually play more than one frame.
// HAVE_FUTURE_DATA	3	Data for the current playback position as well as for at least a little bit of time into the future is available (in other words, at least two frames of video, for example).
// HAVE_ENOUGH_DATA	4	Enough data is available—and the download rate is high enough—that the media can be played through to the end without interruption.

const useAudioPlayer = (onCanPlay: (v: boolean) => void, loopModeOpts: ILoopModeOpts) => {
  const [duration, setDuration] = useState<number | null>(null);
  const audioInfo = useAppSelector(state => state.app.audioInfo);
  const [audioObject, setAudioObject] = useState<HTMLAudioElement>();
  const editorMode = useAppSelector(state => state.app.editorMode);
  const [audioIsPlaying, setAudioIsPlaying] = useState<boolean>(false);
  const [audioIsInError, setAudioIsInError] = useState<boolean>(false);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [changePlaybackRate, setChangePlaybackRate] = useState<boolean>(false);
  const [dynamicPlaybackRate, setDynamicPlaybackRate] = useState<number>(1);
  const dispatch = useAppDispatch();
  const reloadCounter = useRef(0);
  const currentTimeRedux = useAppSelector(state => state.app.currentTime);
  const clickedTime = useAppSelector(state => state.app.clickedTime);
  const unloaded = useRef(false);
  const reloadAfterErrorCount = useRef(0);

  useEffect(() => {
    const { isActive, startTime, endTime } = loopModeOpts;

    if (isActive && audioObject && currentTimeRedux >= endTime) {
      audioObject.currentTime = startTime;
    }
  }, [currentTimeRedux]);

  useEffect(() => {
    const { isActive, startTime, endTime } = loopModeOpts;
    if (!isActive || !audioObject) return;

    audioObject.currentTime = startTime;
  }, [loopModeOpts]);

  /**
   * Safely play audio
   *
   * Reference: https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
   *            https://bugs.chromium.org/p/chromium/issues/detail?id=718647
   */
  const playAudioPromise = useCallback((): void => {
    // console.log('From init play promise');
    if (!audioObject) return;
    const playPromise = audioObject.play();
    // playPromise is null in IE 11
    if (playPromise) {
      playPromise
        .then(() => {
          // console.log('will PLAY audio from playAudioPromise()');
          setAudioIsPlaying(true);
          dispatch(setIsAudioPlaying(true));
          if (editorMode === EditorModeEnums.EDIT_MODE) {
            dispatch(setEditorMode(EditorModeEnums.PLAYING_MODE));
          }
        })
        .catch((err) => {
          console.log('Play promise failed', err);
          audioObject.load();
          // const { onPlayError } = this.props
          // onPlayError && onPlayError(new Error(err))
        });
    }
  }, [audioObject, dispatch, editorMode]);

  const togglePlayPause = useCallback(
    (e?: React.SyntheticEvent) => {
      if (e) {
        e.stopPropagation();
      }

      if (!audioObject) return;

      const rs = audioObject.readyState;
      // console.log('ready state is:', rs);

      if (audioObject.paused && audioObject.src) {
        // console.log('will fire PLAY promise from togglePlayPause()');
        playAudioPromise();
      } else if (!audioObject.paused) {
        audioObject.pause();
        // console.log('will PAUSE audio from togglePlayPause()');
        setAudioIsPlaying(false);
        dispatch(setIsAudioPlaying(false));
      }
    },
    [audioObject, dispatch, playAudioPromise]
  );

  const isPlaying = (): boolean => {
    if (!audioObject) return false;

    return !audioObject.paused && !audioObject.ended;
  };

  const pauseAudio = () => {
    if (audioObject && !audioObject.paused) {
      // console.log('will PAUSE audio from pauseAudio()');
      audioObject.pause();
      setAudioIsPlaying(false);
      dispatch(setIsAudioPlaying(false));
    }
  };

  useEffect(() => {
    if (dynamicPlaybackRate <= 0.1 || dynamicPlaybackRate >= 4.1) return;
    if (audioObject) {
      (audioObject as unknown as HTMLAudioElement).playbackRate = dynamicPlaybackRate;
      setPlaybackRate(dynamicPlaybackRate);
    }
  }, [dynamicPlaybackRate, audioObject]);

  useEffect(() => {
    setAudioIsInError(false);
    if (audioObject) {
      const setAudioData = () => {
        // console.log('LoadedData event');
        setAudioIsInError(false);
        setDuration((audioObject as unknown as HTMLAudioElement).duration);
        dispatch(setCurrentTime((audioObject as unknown as HTMLAudioElement).currentTime));
      };
      const setAudioTime = () => {
        dispatch(setCurrentTime((audioObject as unknown as HTMLAudioElement).currentTime));
      };

      (audioObject as unknown as HTMLAudioElement).addEventListener('loadeddata', setAudioData);

      (audioObject as unknown as HTMLAudioElement).addEventListener('emptied', () => {
        // console.log('audio emptied event');
      });

      (audioObject as unknown as HTMLAudioElement).addEventListener('error', () => {
        console.log('error event happened:', audioObject.error);
        // console.log('will do load() again from error event');
        setAudioIsInError(true);
        if (audioObject && audioObject.error) {
          console.error(
            `Error with audio: ${
              audioObject.error.code === 1
                ? 'MEDIA_ERR_ABORTED'
                : audioObject.error.code === 2
                ? 'MEDIA_ERR_NETWORK'
                : audioObject.error.code === 3
                ? 'MEDIA_ERR_DECODE'
                : 'MEDIA_ERR_SRC_NOT_SUPPORTED'
            }`
          );
        }
        if (audioObject.error?.code === 2 && reloadAfterErrorCount.current < 10) {
          // retry again in 5 seconds (in case of networking error)
          reloadAfterErrorCount.current = reloadAfterErrorCount.current + 1;
          setTimeout(() => {
            if (!unloaded.current) {
              audioObject.load();
            }
          }, 5000);
        }
      });

      (audioObject as unknown as HTMLAudioElement).addEventListener('loadedmetadata', () => {
        // console.log('loadedmetadata event');
      });

      (audioObject as unknown as HTMLAudioElement).addEventListener('suspend', () => {
        // console.log('suspend event');
      });

      (audioObject as unknown as HTMLAudioElement).addEventListener('play', () => {
        // console.log('play event');
        // setAudioIsPlaying(true);
      });

      (audioObject as unknown as HTMLAudioElement).addEventListener('pause', () => {
        // console.log('pause event');
        setAudioIsPlaying(false);
        dispatch(setIsAudioPlaying(false));
      });

      (audioObject as unknown as HTMLAudioElement).addEventListener('canplay', () => {
        // console.log('audio canplay event');
        onCanPlay(true);
      });

      (audioObject as unknown as HTMLAudioElement).addEventListener('canplaythrough', () => {
        // console.log('audio canplaythrough event');
      });

      (audioObject as unknown as HTMLAudioElement).addEventListener('playing', () => {
        // console.log('playing event');
      });

      (audioObject as unknown as HTMLAudioElement).addEventListener(
        'timeupdate',
        (e) => {
          const { target } = e;
          if (e !== null) {
            dispatch(setCurrentTime((target as HTMLAudioElement).currentTime));
          }
        }
        // throttle(setAudioTime, 100)
      );

      (audioObject as unknown as HTMLAudioElement).addEventListener('ended', () => {
        // console.log('ended event');
        setAudioIsPlaying(false);
        dispatch(setIsAudioPlaying(false));
      });

      return () => {
        // console.log('from return audio object unmount');
        pauseAudio();
        (audioObject as unknown as HTMLAudioElement).removeEventListener('loadeddata', setAudioData);
        (audioObject as unknown as HTMLAudioElement).removeEventListener(
          'timeupdate',
          throttle(setAudioTime, 100)
        );
        (audioObject as unknown as HTMLAudioElement).removeEventListener('ended', () => {});

        (audioObject as unknown as HTMLAudioElement).removeEventListener('play', () => {});
        (audioObject as unknown as HTMLAudioElement).removeEventListener('playing', () => {});
        (audioObject as unknown as HTMLAudioElement).removeEventListener('pause', () => {});
      };
    }
  }, [audioObject]);

  useEffect(() => {
    if (!audioInfo.loadNew) return;
    if (audioInfo.url !== '') {
      // pauseAudio();
      setTimeout(() => {
        const newAudio = new Audio(audioInfo.url);
        // console.log('Created new audio with url', audioInfo.url);
        setAudioObject(newAudio);
      }, 500);
      onCanPlay(false);
    }

    dispatch(setAudioInfo({ loadNew: false }));
  }, [audioInfo]);

  useEffect(() => {
    // const audio: HTMLElement | null = document.getElementById('audio');
    if (clickedTime && clickedTime !== currentTimeRedux && audioObject) {
      (audioObject as unknown as HTMLAudioElement).currentTime = clickedTime;
      dispatch(setClickedTime(null));
    }
  }, [clickedTime, dispatch]);

  useEffect(() => {
    return () => {
      unloaded.current = true;
    };
  }, []);

  return {
    //currTime,
    duration,
    audioIsPlaying,
    // setAudioIsPlaying,
    setClickedTime,
    setChangePlaybackRate,
    playbackRate,
    dynamicPlaybackRate,
    setDynamicPlaybackRate,
    togglePlayPause,
    pauseAudio,
    audioObject,
    audioIsInError,
  };
};

export default useAudioPlayer;
