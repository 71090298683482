import React from 'react';
import RoutesComponent from '../Routes/Routes';
import { mainRoutes } from '../Routes/mainRoutes';
import CssBaseline from '@mui/material/CssBaseline';

const Layout = () => {
  return (
    <div>
      <CssBaseline />

      <main>
        <RoutesComponent routes={mainRoutes} />
      </main>
    </div>
  );
};

export default Layout;
