import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function PrevIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg id="skip_previous-24px" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <path id="Path_51" data-name="Path 51" d="M6,6h4V30H6Zm7,12L30,30V6Z" transform="translate(6 6)" fill="#ccc"/>
                <path id="Path_52" data-name="Path 52" d="M0,0H48V48H0Z" fill="rgba(0,0,0,0)"/>
            </svg>
        </SvgIcon>
    );
}