import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Select from 'react-select';
import {
  getAllUsers,
  getSessionSharedUsers,
  addMultipleSharedUsers,
  removeShare,
} from '../../api/SessionsService';
import { IShare } from './IDashboard';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAppSelector } from '../../redux/store';

const theme = createTheme();

interface IProps {
  open: boolean;
  handleClose: Function;
  modalTitle: string;
  sessionId: number;
  setSharedWithOthers: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IOthUser {
  value: number; // id
  label: string; // username
}


const ShareModal = ({ open, handleClose, modalTitle, sessionId, setSharedWithOthers }: IProps) => {
  const [isAddEnabled, setIsAddEnabled] = useState(false);
  const [inSearchMode, setInSearchMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [sharedWithUsers, setSharedWithUsers] = useState<IShare[]>([]);

  const [otherUsers, setOtherUsers] = useState<IOthUser[]>([]);
  const [currentlySelectedUsers, setCurrentlySelectedUsers] = useState<IOthUser[]>([]);

  const loggedInUser = useAppSelector(store => store.app.user);

  useEffect(() => {
    if (open) {
      Promise.all([getSessionSharedUsers(sessionId), getAllUsers()]).then(
        ([{ data: shares }, { data: allUsers }]) => {
          setSharedWithUsers(shares);
          const sharedUserIds = shares.map(({ sharedWith: { id } }) => id);
          const otherUsers = allUsers.filter(
            ({ id, username }) => !sharedUserIds.includes(id) && username !== loggedInUser?.username
          );

          setOtherUsers(() => otherUsers.map(({ id: value, username: label }) => ({ value, label })));
        }
      );
    }
  }, [open, sessionId, loggedInUser?.username, sharedWithUsers]);

  // Hide the icon if shared user length is 0
  useEffect(() => {
    if (sharedWithUsers.length === 0) {
      setSharedWithOthers(false);
    }
  }, [sharedWithUsers.length, setSharedWithOthers]);

  const addUsersToShare = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setIsLoading(true);
    const addIds = currentlySelectedUsers.map(({ value }) => value);

    addMultipleSharedUsers(sessionId, addIds)
      .then(() => {
        setSharedWithOthers(true);
      })
      .finally(() => {
        setIsLoading(false);
        setInSearchMode(false);
        handleClose(false);
      });
  };

  const removeUserFromShare = (shareIdToRemove: number) => {
    removeShare(shareIdToRemove)
      .then(() => {
        setSharedWithUsers((pShares) => pShares.filter(({ id }) => id !== shareIdToRemove));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Whenever the dropdown select changes
   * @param e
   */
  const onSelectChange = (e) => {
    setCurrentlySelectedUsers(e);
    setIsAddEnabled(e.length !== 0);
  };

  const closeModal = () => {
    if (isLoading) return;

    setInSearchMode(false);
    handleClose(false);
  };

  return (
    <ThemeProvider theme={theme}>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={open}>
        <div onClick={event => event.stopPropagation()} style={{
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows ? theme.shadows[5] : undefined,
          padding: theme.spacing(2, 2, 2),
          borderRadius: '3px',
          width: theme.spacing(50),
          position: 'relative'
        }}>
          {isLoading && (
            <Box
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(150,143,240,0.1)',
                zIndex: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <Box
            flexDirection="row"
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              style={{
                width: !inSearchMode ? 0 : 40,
                overflow: 'hidden',
                transition: 'width 0.3s',
              }}
            >
              <IconButton aria-label="delete" color="primary" onClick={() => setInSearchMode(false)}>
                <ArrowBackIosIcon />
              </IconButton>
            </Box>

            <h2 id="transition-modal-title">{modalTitle}</h2>

            <div style={{ flexGrow: 1 }}></div>

            <Fade in={inSearchMode}>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{borderRadius: 0}}
                  disabled={!isAddEnabled}
                  onClick={addUsersToShare}
                >
                  Dodaj
                </Button>
              </Box>
            </Fade>
          </Box>

          <Collapse in={!inSearchMode}>
            <Typography style={{marginBottom: theme.spacing(1)}}> Osebe ki imajo dostop </Typography>

            {sharedWithUsers.length === 0 ? (
              <Box
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}
              >
                <Typography variant="overline" style={{ fontWeight: 500 }}>
                  Seja ni deljena
                </Typography>
              </Box>
            ) : (
              <List style={{ maxHeight: theme.spacing(30), overflow: 'scroll' }}>
                {sharedWithUsers.map(({ id, sharedWith: { username } }) => (
                  <ListItem key={id}>
                    <ListItemAvatar>
                      <Avatar style={{ width: theme.spacing(4), height: theme.spacing(4), fontSize: '16px' }} variant="rounded">
                        {username && username.length > 0 ? username.charAt(0) : 'u'}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={username} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => removeUserFromShare(id)}>
                        <ClearIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </Collapse>

          <Typography style={{marginBottom: theme.spacing(1)}} color={inSearchMode ? 'primary' : 'textPrimary'}>
            Dodaj nove osebe
          </Typography>
          <Select
            onChange={onSelectChange}
            options={otherUsers}
            placeholder={'Izberi...'}
            components={{ Option: CustomOption }}
            isMulti
            noOptionsMessage={() => 'Ni uporabnikov'}
            closeMenuOnSelect={false}
            onFocus={event => {event.stopPropagation();setInSearchMode(true)}}
          />
        </div>
      </Fade>
    </Modal>
    </ThemeProvider>
  );
};

const CustomOption = ({ innerProps, isDisabled, label, isFocused, ...oth }) => {
  if (isDisabled) return null;

  return (
    <div {...innerProps}>
      <ListItem selected={isFocused}>
        <ListItemAvatar>
          <Avatar variant="rounded" style={{ height: 30, width: 30 }}>
            {label.charAt(0) || 'u'}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={label} />
      </ListItem>
    </div>
  );
};

export default ShareModal;
