import { useSnackbar } from 'notistack';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getTokens } from '../api/AuthenticationService';
import { API, CLIENT, SESSIONS } from '../api/endpoints';
import { IReceivedInfo, ReceivedMessageTypes } from '../components/Libraries/ILibraries';
import { config } from '../config';
import {
  setAnimButtonState,
  setAudioInfo,
  setEditorMode,
  setHomeFlowState,
  setLoadContentToEditor,
} from '../redux/features/app/app';
import { AnimButtonStatesEnum, EditorModeEnums, IStore } from '../redux/store/IStore';
import {
  IWsAction,
  IWsOptions,
  ReadyState,
  SendMessage,
  WsActionObj,
  WsContextValue,
  WsProviderProps,
} from './wsTypes';
import { useAppDispatch, useAppSelector } from '../redux/store';

const WsContext = createContext<WsContextValue>({
  sendMessage: () => {},
  resetLastMessage: () => {},
  isConnected: false,
  lastSessionInfo: null,
  recordingsIdsInCurrentEditorSession: { current: [] },
  openWs: () => {},
  closeWs: () => {},
  lastJsonMessage: null,
  resetUpdateAudioToken: () => {},
});

// 1001 - GOING_AWAY
// 1011 - SERVER_ERROR
// 4001 - LANGUAGE_NOT_AVAILABLE
// 4002 - DOMAIN_NOT_AVAILABLE
// 4003 - MODEL_NOT_AVAILABLE
// 4004 - MODEL_NOT_ALLOWED
// 4005 - WORKERS_NOT_AVAILABLE
// 4006 - SILENCE_TIMEOUT?
// 4500 - NORMAL

export const wsCloseEventsMap = {
  1001: { en: 'Zaledni sistem ni na voljo', si: 'Zaledni sistem ni na voljo' },
  // 1006: { en: 'Zaledni sistem ni na voljo', si: 'Zaledni sistem ni na voljo' },
  1011: { en: 'Zaledni sistem ni na voljo', si: 'Zaledni sistem ni na voljo' },
  3000: { en: 'Zaledni sistem ni na voljo', si: 'Zaledni sistem ni na voljo' },
  4001: { en: 'Izbrani jezik ni na voljo', si: 'Izbrani jezik ni na voljo' },
  4002: { en: 'Izbrana domena ni na voljo', si: 'Izbrana domena ni na voljo' },
  4003: { en: 'Izbrani model ni na voljo', si: 'Izbrani model ni na voljo' },
  4004: { en: 'Izbrani model ni dovoljen', si: 'Izbrani model ni dovoljen' },
  4005: { en: 'Trenutno so vsi delavci zasedeni', si: 'Trenutno so vsi delavci zasedeni' },
  4006: { en: 'Prevec časa je bila tišina', si: 'Prevec časa je bila tišina' },
  4007: { en: 'Napaka pri pošiljanju zvočnih podatkov', si: 'Napaka pri pošiljanju zvočnih podatkov' },
  // 4500: { en: 'Internal Server Error', si: 'Interna napaka v zalednem sistemu' },
};

export const WEBSOCKET_URL = config.backendWsUrl;
export const CHUNK_SIZE = 8192; // 16384, 8192, 2048
export const NUM_OF_CHANNELS = 1;

export const urlBackend: string = config.backendApiUrl;

const shouldReconnect = false;
const MAX_RECONNECTS = 20;
const DEFAULT_RECONNECT_INTERVAL_MS = 5000;

export const WsProvider: React.FC<WsProviderProps> = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const user = useAppSelector(state => state.app.user);
  const [updateAudioToken, setUpdateAudioToken] = useState(false);
  const [wsAction, setWsAction] = useState<WsActionObj>({ action: IWsAction.IDLE });
  const [lastSessionInfo, setLastSessionInfo] = useState<IReceivedInfo | null>(null);
  const [lastJsonMessage, setLastJsonMessage] = useState<any | null>(null);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const webSocketRef = useRef<WebSocket | null>(null);
  // const startRef = useRef<() => void>(() => void 0);
  const reconnectCount = useRef<number>(0);
  // const messageQueue = useRef<WebSocketMessage[]>([]);

  const openWs = useCallback(
    (options?: IWsOptions) => {
      setWsAction({ action: IWsAction.CONNECT, options });
    },
    [setWsAction]
  );

  const resetLastMessage = useCallback(() => {
    setLastJsonMessage(null);
  }, [setLastJsonMessage]);

  const closeWs = useCallback(
    (action: IWsAction = IWsAction.CLOSE) => {
      setWsAction({ action });
    },
    [setWsAction]
  );

  const recordingsIdsInCurrentEditorSession = useRef<number[]>([]);

  useEffect(() => {
    if (wsAction.action === IWsAction.IDLE) return;
    setWsAction({ action: IWsAction.IDLE });

    let reconnectTimeout: number;
    if (wsAction.action === IWsAction.CONNECT) {
      let wsUrl = WEBSOCKET_URL;
      if (wsAction.options) {
        const { sessionId, token } = wsAction.options;
        wsUrl += `?client_code=EDITOR&access_token=${token}${sessionId ? `&session_id=${sessionId}` : ''}`;
      }
      console.log('Will create new ws session', wsAction.options);

      webSocketRef.current = new WebSocket(wsUrl);

      const wsInstance = webSocketRef.current;
      if (!wsInstance) {
        throw new Error('WebSocket failed to be created');
      } else {
        wsInstance.onopen = (event: WebSocketEventMap['open']) => {
          // onOpenFn()
          // console.log('Websocket onOpen', event);
          reconnectCount.current = 0;
          setIsConnected(true);
        };

        wsInstance.onmessage = (message: WebSocketEventMap['message']) => {
          // console.log('received msg:', message);
          // onMessageFn()
          const m = JSON.parse(message.data) as IReceivedInfo;
          // console.log('Low msg:', m);
          if (m.type === ReceivedMessageTypes.INFO && wsAction.options) {
            if (m.recordingId) {
              recordingsIdsInCurrentEditorSession.current = [
                ...recordingsIdsInCurrentEditorSession.current,
                m.recordingId,
              ];
            }

            const { token } = wsAction.options;

            const aToken = getTokens()?.accessToken;
            const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${
              m.sessionId
            }/audio.wav?access_token=${aToken ? aToken : token}&nocache=${new Date().getTime()}`;

            dispatch(
              setAudioInfo({
                url: audioUrlPath,
                loadNew: false,
              })
            );
            setUpdateAudioToken(true);
            setLastSessionInfo(m);
          }
          setLastJsonMessage(JSON.parse(message.data))
        };

        wsInstance.onclose = (event: WebSocketEventMap['close']) => {
          // onCloseFn()
          console.log('Ws will close', event);
          dispatch(setHomeFlowState({ liveFlowInProgress: false, uploadFlowInProgress: false }));
          setIsConnected(false);
          const { code, reason } = event;

          if (code !== 4500) {
            setLastJsonMessage(null)
            dispatch(
              setAudioInfo({
                loadNew: true,
              })
            );

            dispatch(
              setLoadContentToEditor({
                recFinishedStartLoadingNewEditorState: true,
                recStartedLoadTextFromEditor: false,
              })
            );
            dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
            dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));

            const isAudioError =
              reason.indexOf('Audio data can only be processed while session is in progress') >= 0;
            const translatedCode = isAudioError ? 4007 : code;
            enqueueSnackbar(
              `Seja se je zaključila: ${
                wsCloseEventsMap[translatedCode] && wsCloseEventsMap[translatedCode].si
              }`,
              {
                variant: translatedCode === 4006 ? 'info' : 'error',
              }
            );
            wsInstance.close();
          }

          if (code === 4500) {
            setLastJsonMessage(null)
            dispatch(
              setAudioInfo({
                loadNew: true,
              })
            );
            dispatch(
              setLoadContentToEditor({
                recFinishedStartLoadingNewEditorState: true,
                recStartedLoadTextFromEditor: false,
              })
            );
            dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
            dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));

            wsInstance.close();
          } else {
            setLastJsonMessage(null)
            dispatch(
              setAudioInfo({
                loadNew: true,
              })
            );
            dispatch(
              setLoadContentToEditor({
                recFinishedStartLoadingNewEditorState: true,
                recStartedLoadTextFromEditor: false,
              })
            );
            dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
            dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));
            dispatch(
              setAudioInfo({
                loadNew: true,
              })
            );
            wsInstance.close();
          }

          if (shouldReconnect) {
            if (reconnectCount.current < MAX_RECONNECTS) {
              reconnectTimeout = window.setTimeout(() => {
                reconnectCount.current++;
                openWs();
              }, DEFAULT_RECONNECT_INTERVAL_MS);
            } else {
              // onReconnectStop()
              console.warn(`Max reconnect attempts of ${reconnectCount} exceeded`);
            }
          }
        };

        wsInstance.onerror = (error: WebSocketEventMap['error']) => {
          // onEerrorFn()
          enqueueSnackbar('Prislo je do napake v webSocket povezavi.', {
            variant: 'error',
          });
          console.log('WS error:', error);
        };
      }
    } else if (wsAction.action === IWsAction.CLOSE || wsAction.action === IWsAction.FORCE_CLOSE) {
      const wsInstance = webSocketRef.current;
      if (wsInstance && wsInstance.readyState === wsInstance.OPEN) {

        if (wsAction.action === IWsAction.FORCE_CLOSE) {
          wsInstance.close(3000, 'Force close due to backend inactivity');
        } else {
          wsInstance.close();
        }
        setIsConnected(false);
      }
    }

    return () => window.clearTimeout(reconnectTimeout);
  }, [wsAction]);

  useEffect(() => {
    return () => {
      // cancelReconnectOnClose();
      // cancelReconnectOnError();

      webSocketRef.current && webSocketRef.current.close();
      // if (interval) clearInterval(interval);
    };
  }, []);

  const sendMessage: SendMessage = useCallback((message) => {
    if (webSocketRef.current && webSocketRef.current.readyState === ReadyState.OPEN) {
      // console.log('WILL SEND MESSAGE:', message);
      webSocketRef.current.send(message);
    }

    // else {
    //   messageQueue.current.push(message);
    // }
  }, []);

  /*const sendJsonMessage: SendJsonMessage = useCallback(
    (message) => {
      sendMessage(JSON.stringify(message));
    },
    [sendMessage]
  );*/

  const resetUpdateAudioToken = useCallback(() => {
    setUpdateAudioToken(false);
  }, [setUpdateAudioToken]);

  useEffect(() => {
    if (!updateAudioToken) return;
    if (!user || !user.accessToken) return;
    if (!lastSessionInfo?.sessionId) return;

    const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${
      lastSessionInfo.sessionId
    }/audio.wav?access_token=${user.accessToken}&nocache=${new Date().getTime()}`;

    dispatch(
      setAudioInfo({
        url: audioUrlPath,
        loadNew: true,
      })
    );
  }, [user?.accessToken]);

  const memoValue = useMemo(() => {
    return {
      isConnected,
      lastJsonMessage,
      sendMessage,
      openWs,
      closeWs,
      lastSessionInfo,
      resetLastMessage,
      resetUpdateAudioToken,
      recordingsIdsInCurrentEditorSession,
    };
  }, [
    isConnected,
    lastJsonMessage,
    sendMessage,
    openWs,
    closeWs,
    lastSessionInfo,
    resetLastMessage,
    resetUpdateAudioToken,
    recordingsIdsInCurrentEditorSession,
  ]);

  return <WsContext.Provider value={memoValue}>{children}</WsContext.Provider>;
};

export default WsContext;



