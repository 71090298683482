import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function SpeedTimes(props: any) {
  return (
      <SvgIcon {...props} >
        <svg id="fast_rewind-24px" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
          <path id="Path_44" data-name="Path 44" d="M0,0H48V48H0Z" fill="rgba(0,0,0,0)"/>
          <text id="_1.0_" data-name="1.0×" transform={`translate(${props.translatex} 32)`} fontSize="18px" fontFamily="Roboto-Bold, Roboto" fontWeight="700"><tspan x="0" y="0">{ props.text }</tspan></text>
        </svg>
      </SvgIcon>
  );
}