import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function ShrinkIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="_24px" data-name="24px" opacity="0.799">
                    <path id="Path_20" data-name="Path 20" d="M16.59,16,12,11.42,7.41,16,6,14.59l6-6,6,6Z" transform="translate(0 -0.59)" fill="rgba(0,0,0,0.2)"/>
                    <path id="Path_21" data-name="Path 21" d="M0,24H24V0H0Z" fill="none"/>
                </g>
            </svg>
        </SvgIcon>
    );
}