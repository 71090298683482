import React from 'react';
import { ISpeaker } from './IEditor';

export default function SpeakerSection({
  speaker,
  onClick,
  isFirst,
}: {
  speaker: ISpeaker;
  onClick?: (speaker: ISpeaker) => void;
  isFirst: boolean;
}) {
  const { name, labels } = speaker;

  return (
    <div
      contentEditable={false}
      style={{
        userSelect: 'none',
        width: 'fit-content',
        marginBottom: 10,
        marginTop: isFirst ? 0 : 30,
      }}
    >
      <span
        contentEditable={false}
        className="speaker_button"
        style={{ borderColor: `#2b8dff` }}
        onClick={() => (onClick ? onClick(speaker) : () => {})}
      >
        {name}
      </span>
    </div>
  );
}
