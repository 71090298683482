import { AxiosResponse } from 'axios';
import { IConfiguration } from '../components/Home/ISettings';
import { config } from '../config';
import axios from './axios';
import { API, MODELS, NLP, OPERATIONS, PIPELINES, STAGES } from './endpoints';

const backendUrl: string = config.backendApiUrl;

export const getStages = async (): Promise<AxiosResponse<IConfiguration>> => {
  const url = `${backendUrl}/${API}/${PIPELINES}/${STAGES}`;
  return axios.get(`${url}`);
}

interface IModelInfo {
    createdAt: string,
    id: string,
    name: string,
}
export const getTagInfo = async (tag: string): Promise<AxiosResponse<IModelInfo>> => {
    const url = `${backendUrl}/${API}/${NLP}/${MODELS}/${tag}`;
    return axios.get(`${url}`);
}


export enum TagOperation {
    UPDATE = "UPDATE"
}

interface IModelStatus {
    status: ModelStatus
}

export enum ModelStatus {
    RUNNING = "RUNNING",
    NOT_RUNNING = "NOT_RUNNING"
}

export const getTagOperationStatus = async (tag: string, operation: TagOperation): Promise<AxiosResponse<IModelStatus>> => {
    const url = `${backendUrl}/${API}/${NLP}/${MODELS}/${tag}/${OPERATIONS}/${operation}`;
    return axios.get(`${url}`);
}

export const putTagOperationStatus = async (tag: string, operation: TagOperation): Promise<AxiosResponse<{}>> => {
    const url = `${backendUrl}/${API}/${NLP}/${MODELS}/${tag}/${OPERATIONS}/${operation}`;
    return axios.put(`${url}`);
}