import React from 'react';
import { createRoot } from 'react-dom/client'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store';

import { QueryClient, QueryClientProvider } from 'react-query';

import './index.css';
import { SnackbarProvider } from 'notistack';
import { ClearIcon } from './components/Icons/ClearIcon';

// Elastic APM Real User Monitoring
import NewtonsCradleLoader from './components/Loaders/NewtonsCradle';
import useKeycloak from './hooks/useKeycloak';
import WarnMessage from './components/CustomNotistack/WarnMessage';
import { KeycloakAuthProvider } from './providers/KeycloakAuthProvider';
import AutoCopyErrorMessage from './components/CustomNotistack/AutoCopyErrorMessage';

declare module 'notistack' {
  interface VariantOverrides {
    warn: true;
    autoCopyError: true;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const notistackRef: any = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef?.current.closeSnackbar(key);
};

const RedirectLoader = () => {
  return (
    <div className='loader_container'>
      <NewtonsCradleLoader />
    </div>
  )
}
const Wrapper = () => {
  const {
    isAuthenticated
  } = useKeycloak();

  if (isAuthenticated) {
    return (
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        // @ts-ignore
        ref={notistackRef}
        action={(key) => (
          <button onClick={onClickDismiss(key)}>
            <ClearIcon fill="white" style={{ color: 'white' }} />
          </button>
        )}
        autoHideDuration={5000}
        dense={true}
        Components={{
          warn: WarnMessage,
          autoCopyError: AutoCopyErrorMessage
        }}
      >

        <QueryClientProvider client={queryClient}>
          {/*<V3WebSocketProvider>*/}
            <App />
          {/*</V3WebSocketProvider>*/}
        </QueryClientProvider>
      </SnackbarProvider>
    )
  }

  return <RedirectLoader />
}


createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <KeycloakAuthProvider>
      <Wrapper />
    </KeycloakAuthProvider>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
