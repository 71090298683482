import { useSnackbar } from "notistack";
import React, { createContext, MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { API, CLIENT, SESSIONS } from "../api/endpoints";
import { IConfigurationSend } from "../components/Home/ISettings";
import { initMicrophone, isContextInitialized, lockAudio, releaseAudio, unlockAudio } from "../components/Libraries/newMicrophone";
import { config } from "../config";
import { setAnimButtonState, setAudioInfo, setConsumptionModal, setEditorLock, setEditorMode, setHomeFlowState, setLoadContentToEditor, setValidRedirect } from "../redux/features/app/app";
import { AnimButtonStatesEnum, ConsumtionModalTemplate, EditorModeEnums, IEditorLock, IStore, IUser } from "../redux/store/IStore";

import { IV3RecievedMessage } from "../components/Libraries/ILibraries";
import { convertFromVersion3ToVersion2 } from "../api/dataConverters";
import moment from "moment";
import { getEditorContentV30, getSession } from "../api/SessionsService";
import { AccessMode } from "../types";
import useKeycloak from "../hooks/useKeycloak";
import WarnMessageContent from "../components/CustomNotistack/WarnMessageContent";
import { abbreviations } from "../components/Editor/constants";
import { paragraphTokens } from "../shared/paragrapher";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/store";

const WEBSOCKET_URL = config.backendWsUrl;
const LOWER_WORD_LIMIT = 200;
const UPPER_WORD_LIMIT = 300;
const SECOND_LIMIT = 2;

// 1000 - NORMAL
// 1001 - GOING_AWAY
// 1011 - SERVER_ERROR
// 4001 - LANGUAGE_NOT_AVAILABLE
// 4002 - DOMAIN_NOT_AVAILABLE
// 4003 - MODEL_NOT_AVAILABLE
// 4004 - MODEL_NOT_ALLOWED
// 4005 - WORKERS_NOT_AVAILABLE
// 4006 - SILENCE_TIMEOUT?
const getErrorCodeMapper = (code: number) => {
    switch(code) {
      case 1001: return "Zaledni sistem ni na voljo."
      case 1011: return "Interna napaka na zalednem sistemu."
      case 4000: return "Neveljavna operacija. Prosimo, preverite nastavitve."
      case 4030: return "Nedovoljena operacija."
      case 4031: return "Izbrani model ni dovoljen."
      case 4041: return "Izbrani model ni na voljo."
      case 4291: return "Vse procesne enote so zasedene. Poskusite kasneje."
      case 4292: return "Porabili ste vso zakupljeno kvoto."
      case 4293: return "Presegli ste dogovorjeno število sočasnih sej."
      default: return "Prišlo je do neznane napake. Kontaktirajte tehnično podporo."
    }
  }


const isCapital = (text: string): boolean => /^\p{Lu}/u.test(text);

const isNameEntity = (text: string): boolean => {
    return true;
}

const isNumber = (text: string) => /^[0-9]+$/.test(text);

const isOnlyUpperCaseOrNumber = (text: string) => /^(?![A-Z0-9]+$).+/.test(text);

const isPunctuation = (text: string) => /[.?!]/.test(text);
const isAbbreviation = (text: string) => abbreviations.includes(text);

const isNextWordValid = (text: string) => isCapital(text) && !isNameEntity(text) && !isNumber(text) && !isOnlyUpperCaseOrNumber(text)

const shouldReconnect = false;
const MAX_RECONNECTS = 20;
const DEFAULT_RECONNECT_INTERVAL_MS = 5000;

export const urlBackend: string = config.backendApiUrl;

interface V3WSPros {
    children: ReactNode
}

interface V3WSContextValue {
    startRecording: (pipeline: IConfigurationSend,  sessionId: number | null) => void;
    stopRecording: () => Promise<void>;
    recordingsIdsInCurrentEditorSession: MutableRefObject<number[]>,
    lastJsonMessage: any;
    resetLastMessage: () => void;
    updateLocalEditorLock: (editorLock: IEditorLock) => void;
    closeWs: () => void;
    localEditorLock: IEditorLock | null;
}

const V3WsContext = createContext<V3WSContextValue>({
    startRecording: () => {},
    stopRecording: () => Promise.resolve(),
    recordingsIdsInCurrentEditorSession: { current: [] },
    lastJsonMessage: null,
    resetLastMessage: () => {},
    updateLocalEditorLock: () => {},
    closeWs: () => {},
    localEditorLock: null,
});

export const V3WebSocketProvider: React.FC<V3WSPros> = ({ children }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { isAuthenticated } = useKeycloak();

    const user = useAppSelector(state => state.app.user);
    const editorLock = useAppSelector(state => state.app.editorLock);
    const recordingsIdsInCurrentEditorSession = useRef<number[]>([]);
    const webSocketRef = useRef<WebSocket | null>(null);
    const pipelineRef = useRef<IConfigurationSend | null>(null);
    const shouldNextBeCapitalizedRef = useRef<boolean>(false);
    const warnQuotaMessageRef = useRef<boolean>(true);
    
    const tokenCountRef = useRef<number>(0);

    const reconnectCount = useRef<number>(0);

    const [ sendInitialized, setSendInitialized ] = useState<boolean>(false)
    const [ lastJsonMessage, setLastJsonMessage ] = useState<any>(null);
    const resetLastMessage = useCallback(() => {
        setLastJsonMessage(null);
      }, [setLastJsonMessage]);

    const [localEditorLock, setLocalEditorLock] = useState<IEditorLock>(editorLock)
    const updateLocalEditorLock = useCallback((editorLock: IEditorLock) => {
        setLocalEditorLock(editorLock);
    }, [setLocalEditorLock]);

    const updateLock = useCallback(async () => {
        console.log('HERE!!')
        dispatch(setEditorLock(localEditorLock))
        if (!localEditorLock || !localEditorLock.sessionId || !localEditorLock.sessionLockKey || editorLock.editTicket) return;
        try {
            const editorContent = await getEditorContentV30(localEditorLock.sessionId, localEditorLock.sessionLockKey, AccessMode.READ_WRITE, editorLock.versionName);
            dispatch(setEditorLock({ ...localEditorLock, editTicket: editorContent.data.editTicket, versionName: editorLock.versionName }))
        } catch (error) {
            console.log(error);
        }
    }, [editorLock, localEditorLock])

    useEffect(() => {
        updateLock();
    }, [localEditorLock])

    useEffect(() => {
        if (sendInitialized && webSocketRef.current) {
            console.log('INITIALIZED')
            const config = JSON.stringify({
                type: "CONFIG",
                pipeline: pipelineRef.current,
                sessionId: editorLock.sessionId,
                sessionLockKey: editorLock.sessionLockKey,
            })
            webSocketRef.current.send(config)
            console.log('SEND CONFIG')
            setSendInitialized(false)
        }
    }, [sendInitialized])
    
    const startRecording = useCallback(async (pipeline: IConfigurationSend, sessionId: number | null) => {    
        if (!isAuthenticated || !user || !user.accessToken || !user.isAuthenticated) {
            enqueueSnackbar(
                "Prišlo je do napake. Poizkusite kasneje.",
                { variant: 'error' }
              );
            dispatch(setHomeFlowState({ liveFlowInProgress: false }));
            return;
        }
        
        let wsUrl = WEBSOCKET_URL + `?access_token=${user.accessToken}${sessionId ? `&session_id=${sessionId}` : ""}`;

        let currentSessionDurationInMs = 0;
        if (sessionId) {
            try {
                const sessionResponse = await getSession(sessionId)
                currentSessionDurationInMs = sessionResponse.data.recordedMs
            } catch (error) {
                console.log(error)
            }
        }
        
        webSocketRef.current = new WebSocket(wsUrl);
        if (!webSocketRef.current) {
            enqueueSnackbar(
                "Prišlo je do napake. Poizkusite kasneje.",
                { variant: 'error' }
              );

            return;
        }
        webSocketRef.current.onopen = (event: WebSocketEventMap["open"]) => {
            console.log("onopen")
            console.log(event)
            ///setTimeout(test, 10000)
        }

        webSocketRef.current.onclose = (event: WebSocketEventMap["close"]) => {
            tokenCountRef.current = 0;
            console.log("onclose")
            
            dispatch(setHomeFlowState({ liveFlowInProgress: false, uploadFlowInProgress: false }));
            
            releaseAudio();
            
            dispatch(
                setAudioInfo({
                    loadNew: true,
                })
            );

            dispatch(
                setLoadContentToEditor({
                    recFinishedStartLoadingNewEditorState: true,
                    recStartedLoadTextFromEditor: false,
                })
            );

            dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
            dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));

            const { code, reason } = event;

            if (code !== 1000) {
                const isAudioError =
                    reason.indexOf('Audio data can only be processed while session is in progress') >= 0;
                const translatedCode = isAudioError ? 4007 : code;
                enqueueSnackbar(
                    `Seja se je zaključila z napako: ${getErrorCodeMapper(translatedCode)}`,
                    { variant: translatedCode === 4006 ? 'info' : 'error' }
                );

                if (code === 4292) {
                  dispatch(setConsumptionModal({
                    visible: true,
                    template: ConsumtionModalTemplate.LIMIT
                  })); 
                }
            }

            /*if (webSocketRef.current) {
                webSocketRef.current.close();
            }*/

            /*if (shouldReconnect) {
                if (reconnectCount.current < MAX_RECONNECTS) {
                  reconnectTimeout = window.setTimeout(() => {
                    reconnectCount.current++;
                    openWs();
                  }, DEFAULT_RECONNECT_INTERVAL_MS);
                } else {
                  // onReconnectStop()
                  console.warn(`Max reconnect attempts of ${reconnectCount} exceeded`);
                }
              }
            */
            console.log(event)
        }

        webSocketRef.current.onerror = (event: WebSocketEventMap["error"]) => {
            console.log("onerror")
            console.log(event)
        }

        webSocketRef.current.onmessage = async (event: WebSocketEventMap["message"]) => {
            //console.log(event)
            if (!webSocketRef.current || !pipelineRef.current) return;
            const message = JSON.parse(event.data)
            if (message.type === "TEXT_SEGMENT" && message.textSegment) {
                const mappedMessage = (message as IV3RecievedMessage)
                console.log(mappedMessage.textSegment.isFinal)
                if (mappedMessage.textSegment.isFinal) {
                    const paragraphedTokens = paragraphTokens([mappedMessage.textSegment], tokenCountRef.current)
                    tokenCountRef.current = paragraphedTokens.tokenCount
                    setLastJsonMessage(convertFromVersion3ToVersion2({
                        ...mappedMessage,
                        textSegment: paragraphedTokens.newTranscripts
                    }, shouldNextBeCapitalizedRef.current))
                    

                    shouldNextBeCapitalizedRef.current = mappedMessage.textSegment.tokens[mappedMessage.textSegment.tokens.length - 1].text === "." ||
                    mappedMessage.textSegment.tokens[mappedMessage.textSegment.tokens.length - 1].text === "?" ||
                    mappedMessage.textSegment.tokens[mappedMessage.textSegment.tokens.length - 1].text === "!" ||
                    mappedMessage.textSegment.tokens[mappedMessage.textSegment.tokens.length - 1].text === "<.>" ||
                    mappedMessage.textSegment.tokens[mappedMessage.textSegment.tokens.length - 1].text === "<?>" ||
                    mappedMessage.textSegment.tokens[mappedMessage.textSegment.tokens.length - 1].text === "<!>"
                } else {
                    setLastJsonMessage(convertFromVersion3ToVersion2(mappedMessage, shouldNextBeCapitalizedRef.current))
                }
            } else if (message.type === "STATUS" && message.status === "INITIALIZED") {
                setSendInitialized(true)
            } else if (message.type === "STATUS" && message.status === "CONFIGURED") {
                console.log('CONFIGURED')
                //TOOD: recording should be triggered here
                const initialized = isContextInitialized(); 
                dispatch(setAnimButtonState(AnimButtonStatesEnum.WAITING_TO_START));
                recordingsIdsInCurrentEditorSession.current = [
                    ...recordingsIdsInCurrentEditorSession.current,
                    message.recordingId,
                ];
    
                const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${
                    message.sessionId
                }/audio.wav?access_token=${(user as IUser).accessToken}&nocache=${new Date().getTime()}`;
    
                dispatch(
                  setAudioInfo({
                    url: audioUrlPath,
                    loadNew: false,
                  })
                );
                //setUpdateAudioToken(true);
                
                if (!initialized) {
                    try {
    
                        await initMicrophone(16000, 4096, (audioChunk: ArrayBuffer) => {
                            if (webSocketRef.current) {
                                webSocketRef.current.send(audioChunk)
                            } else {
                                console.log("WEBSOCKET NULL")
                            }
                        })
                        await unlockAudio();
    
    //                    dispatch(setHomeFlowState({ liveFlowInProgress: false, uploadFlowInProgress: false }));
    
                        setLocalEditorLock(current => {
                            return {...current, sessionId: message.sessionId, recordingId: message.recordingId}
                        })
                        dispatch(setValidRedirect(true));
                        dispatch(setEditorMode(EditorModeEnums.RECORDING_MODE));
                        dispatch(setAnimButtonState(AnimButtonStatesEnum.RECORDING));
                    } catch (error) {
                        console.log('On mic error')
                        console.log(error)
                        stopRecording();
                        dispatch(setHomeFlowState({ liveFlowInProgress: false }));
                        dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
                        enqueueSnackbar(
                            'Za transkribiranje v živo omogočite dostop do mikrofona.',
                            { variant: 'error' }
                        );
                    }
                } else {
                    dispatch(setEditorMode(EditorModeEnums.RECORDING_MODE));
                    dispatch(setAnimButtonState(AnimButtonStatesEnum.RECORDING));
                }
    
            }  else if (message.type === "STATUS" && message.status === "FINISHED") {
                resetLastMessage();
                setLocalEditorLock(current => {
                    return {...current, sessionLockKey: message.sessionLock.key, refreshAfter: message.sessionLock.expiresAt ? moment(message.sessionLock.expiresAt).diff(moment.utc(), 'seconds') - 5 : 55}
                })
                webSocketRef.current.close()
            } else if (warnQuotaMessageRef.current && message.type === "WARNING" && message.message.startsWith("Exceeded warn quota of")) {
                enqueueSnackbar(<WarnMessageContent />, {variant: "warn"})
                warnQuotaMessageRef.current = false
            }
        }

        warnQuotaMessageRef.current = true;
        pipelineRef.current = pipeline
        dispatch(
            setLoadContentToEditor({
              recFinishedStartLoadingNewEditorState: false,
              recStartedLoadTextFromEditor: true,
            })
          );
    }, [user, navigate])

    const closeWs = () => {
        if (!webSocketRef.current) return;
        webSocketRef.current.close();
    }

    const stopRecording = async () => {
        if (!webSocketRef.current) {
            enqueueSnackbar(
                "Snemanje je že zaključeno.",
                { variant: 'error' }
              );
              return;
        }

        webSocketRef.current.send(JSON.stringify({
            type: "EOS",
            lockSession: true
        }))

        dispatch(setAnimButtonState(AnimButtonStatesEnum.WAITING_TO_STOP));
        try {
            await lockAudio();
        } catch (error) {
            console.log(error)
            enqueueSnackbar('Pri zajemu zvoka je prišlo do napake. Prosimo poskusite ponovno.', {
                variant: 'error',
              });
        }
        dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
        dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));
    }
    
    //Closes websocket connection on context unmount
    useEffect(() => {
        return () => {
            if (webSocketRef.current) {
                webSocketRef.current.close()
                webSocketRef.current = null;
                pipelineRef.current = null
            }
        }
    }, [])

    const memoValue = useMemo(() => {
        return {
            startRecording,
            stopRecording,
            recordingsIdsInCurrentEditorSession,
            lastJsonMessage,
            resetLastMessage,
            updateLocalEditorLock,
            closeWs,
            localEditorLock
        }
    }, [
        startRecording,
        stopRecording,
        recordingsIdsInCurrentEditorSession,
        lastJsonMessage,
        resetLastMessage,
        updateLocalEditorLock,
        closeWs,
        localEditorLock
    ])
    
    return <V3WsContext.Provider value={memoValue}>{children}</V3WsContext.Provider>
};

export default V3WsContext;