import { IHeader, IHeaderColumn, UserRoleEnums } from '../../redux/store/IStore';
import { SessionState } from '../DashboardHeader/ISearch';
import { IRecordingsArray } from '../Home/ISettings';

// This represents data, BE will have to provide

export interface IInitColumn {
  label: string;
  attribute: string;
  width: number;
  type: string;
  sortable: boolean;
}

// This is addtional properties frontend will have to fill at load
export interface IColumn extends IInitColumn {
  id: string;
  shown: boolean;
  filtered: boolean;
}

export interface IColumnMap {}

export enum ColumnTypes {
  UNDEF = 'UNDEF', // For testing
  INTEGER = 'INTEGER',
  STRING = 'STRING',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  TIME_FLOAT = 'TIME_FLOAT',
  TITLE = 'TITLE', // *Special: A string -> Updatable from children!
  LABELS = 'LABELS', // *Special
  USER = 'USER', // *Special: A nested object with an 'id' and 'username' field
  SOURCE = 'SOURCE', // * Special, if true -> API, else Editor
  STATUS = 'STATUS',
}

export enum Attributes {
  name = 'name',
  labels = 'labels',
  createdAt = 'createdAt',
  recordedSeconds = 'recordedSeconds',
  updatedAt = 'updatedAt',
  createdBy = 'createdBy',
  isSaved = 'isSaved',
  status = 'status'
}

export type Map<R> = { [key in keyof typeof Attributes]?: R };

// Test data for new BE API
export const UserHeader: Map<IInitColumn> = {
  [Attributes.name]: {
    label: 'Ime seje',
    attribute: 'name',
    width: 330,
    type: ColumnTypes.TITLE,
    sortable: true,
  },
  [Attributes.labels]: {
    label: 'Labele',
    attribute: 'labels',
    width: 400,
    type: ColumnTypes.LABELS,
    sortable: false,
  },
  [Attributes.createdAt]: {
    label: 'Datum in ura ustvarjanja',
    attribute: 'createdAt',
    width: 220,
    type: ColumnTypes.DATE_TIME,
    sortable: true,
  },
  [Attributes.recordedSeconds]: {
    label: 'Čas posnetka',
    attribute: 'recordedSeconds',
    width: 160,
    type: ColumnTypes.TIME_FLOAT,
    sortable: true,
  },
  [Attributes.updatedAt]: {
    label: 'Zadnja sprememba',
    attribute: 'updatedAt',
    width: 220,
    type: ColumnTypes.DATE_TIME,
    sortable: true,
  },
};

export const AdminHeader: Map<IInitColumn> = {
  ...UserHeader,
  [Attributes.createdBy]: {
    label: 'Uporabnik',
    attribute: 'createdBy',
    width: 120,
    type: ColumnTypes.USER,
    sortable: true,
  },
  [Attributes.isSaved]: {
    label: 'Vir',
    attribute: 'isSaved',
    width: 120,
    type: ColumnTypes.SOURCE,
    sortable: true,
  },
  [Attributes.status]: {
    label: 'Stanje seje',
    attribute: 'status',
    width: 120,
    type: ColumnTypes.STATUS,
    sortable: true,
  },
};

export enum SessionEntryStatus {
  UNTRANSCRIBED = '#707070',
  TRANSCRIBED = '#FC8831',
  CHECKED = '#005173',
  ARCHIVED = '#00000000',
}

export const genereateReduxHeader = (map: Map<IInitColumn>): IHeader => {
  const toRedux: Map<IHeaderColumn> = {};

  const entries = Object.entries(map) as unknown as [Attributes, IInitColumn][];

  entries.forEach(([key, { width, label, type, sortable }], index) => {
    const value: IHeaderColumn = {
      order: index,
      shown: true,
      filtered: false,
      id: key,
      label,
      sortable,
      width,
      type: type as ColumnTypes,
    };

    Object.assign(toRedux, { [key as string]: { ...value } });
  });

  return toRedux;
};


export interface IHistorySession {
  id: number;
  name: string;
  numRecordings: number;
  recordedMs: number;
  processedMs: number;
  createdAt: string;
  updatedAt: string;
  createdByUser: string
  createdByGroup: string
  isDiscarded: boolean;
  allRecordingsDiscarded: boolean;
  sharedWithMe: boolean;
  sharedWithOthers: boolean;
  notes: string;
  labels: IChipData[];
  sources: string[];
  versionTags: string[];
  status: SessionState;
  isLocked: boolean;
  shares: IShare[];
  cursor: string;
  expanded: boolean;
  openedVersion: boolean;
  openedLabels: boolean;
  summary: string | null;
  recordings: IRecordingsArray | null;
}
export interface ISession {
  id: number;
  name: string;
  numRecordings: number;
  recordedMs: number;
  processedMs: number;
  createdAt: string;
  updatedAt: string;
  createdByUser: string
  createdByGroup: string
  isDiscarded: boolean;
  allRecordingsDiscarded: boolean;
  sharedWithMe: boolean;
  sharedWithOthers: boolean;
  notes: string;
  labels: IChipData[];
  sources: string[];
  versionTags: string[];
  status: SessionState;
  isLocked: boolean;
}
export interface ISessionEntry {
  content: ISession,
  cursor: string,
}
export interface ISessionEntryWithShared extends ISessionEntry {
  shares: IShare[];
  summary: string | null;
  recordings: IRecordingsArray | null;
}
export interface ISessionWithShared extends ISession {
  shares: IShare[];
}

export interface CreatedBy {
  id: number;
  username: string;
}

export interface IRowFormData {
  title: string;
  summary: string;
  notes: string;
  chips: IChipData[];
}

export interface IFormData {
  title: string;
  notes: string;
  chips: IChipData[];
}

export interface ISavableData {
  title: string;
  notes: string;
}

export interface IFetchedData {
  title: string;
  summary: string;
  notes: string;
  chips: IChipData[];
  createdAt: string;
  updatedAt: string;
  numRecordings: number;
  recordedSeconds: any;
  isSaved?: boolean;
  sources?: string[];
}

export interface IChipData {
  isEnabled: boolean;
  label: {
    id: number;
    code: string;
    color: string;
    isDefault?: boolean; // Obstaja na DTO-ju, sam v resnic nas na Dashboardu ne zanima
    isAssigned?: boolean;
  };
}

export interface JWTDecoded {
  exp: number;
  iat: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string;
  typ: string;
  azp: string;
  session_state: string;
  acr: string;
  'allowed-origins': string[];
  realm_access: {
    roles: UserRoleEnums[];
  };
  resource_access: {
    account: {
      roles: string[];
    };
    'truebar-backend'?: {
      roles: string[];
    };
  };
  scope: string;
  'enabled-options': string[];
  email_verified: boolean;
  'allowed-models': string[];
  preferred_username: string;
}

export interface IUser {
  id: number;
  username: string;
}

export interface IGroup {
  name: string;
}

export interface IShare {
  id: number;
  created_at: any;
  sharedWith: IUser;
}

export interface IPagination<T> {
  totalPages: number,
  totalElements: number,
  pageable: {
    pageNumber: number,
    pageSize: number,
    sort: {
      sorted: boolean,
      empty: boolean,
      unsorted: boolean,
    },
    offset: number,
    paged: boolean,
    unpaged: boolean,
  },
  first: boolean,
  last: boolean,
  sort: {
    sorted: boolean,
    empty: boolean,
    unsorted: boolean,
  },
  size: number,
  content: T,
  number: number,
  numberOfElements: number,
  empty: boolean,
}