import React, { useEffect } from 'react';
import {
  setDashboardExpendedRow,
  setDashboardPagination,
} from '../redux/features/app/app';
import { useAppDispatch } from '../redux/store';

const HomescreenProvider = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setDashboardPagination({ pageNumber: 0 }));
    dispatch(setDashboardExpendedRow(-1));
  }, [dispatch]);

  return <>{children}</>;
};

export default HomescreenProvider;
