import { ITranscriptWord } from '../Editor/IEditor';

export enum ErrorCodes {
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  INVALID_MESSAGE = 'INVALID_MESSAGE',
  INVALID_OPERATION = 'INVALID_OPERATION',
  INVALID_CONFIGURATION = 'INVALID_CONFIGURATION',
  INTERNAL_EXCEPTION = 'INTERNAL_EXCEPTION',
}

export enum SendMessageTypes {
  SET_CONFIG = 'SET_CONFIG',
  GET_CONFIG = 'GET_CONFIG',
  CONTROL = 'CONTROL',
  LOG = 'LOG',
  AUTHENTICATION = 'AUTHENTICATION',
  AUDIO_CHUNK = 'AUDIO_CHUNK',
}

export enum ControlCodes {
  START_STREAM = 'START_STREAM',
  FINISH_STREAM = 'FINISH_STREAM',
  START_SUBSESSION = 'START_SUBSESSION',
  FINISH_SUBSESSION = 'FINISH_SUBSESSION',
}

export enum LogLevels {
  ERROR = 'ERROR',
  INFO = 'INFO',
  TRACE = 'TRACE',
}

export interface ISend {
  messageType: SendMessageTypes;
  returnAll?: boolean;
  transcriptionLanguageDomainModel?: boolean;
  audioChunk?: ArrayBuffer;
}

export interface ISendAuthentication extends ISend {
  token: string;
}

export interface ISendControl extends ISend {
  controlCode: ControlCodes;
}

export interface ISendLog extends ISend {
  logLevel: LogLevels;
  logMmessage: string;
}

export enum ReceivedMessageTypes {
  TRANSCRIPT = 'TRANSCRIPT',
  TRANSLATION = 'TRANSLATION',
  ERROR = 'ERROR',
  STATUS = 'STATUS',
  CONFIG = 'CONFIG',
  INFO = 'INFO',
  TEXT_SEGMENT = 'TEXT_SEGMENT',
  /* MY FIELDS */

  DURATION = 'DURATION',
}

export enum Statuses {
  STREAM_STARTED = 'STREAM_STARTED',
  STREAM_FINISHED = 'STREAM_FINISHED',
  SUBSESSION_STARTED = 'SUBSESSION_STARTED',
  SUBSESSION_FINISHED = 'SUBSESSION_FINISHED',
  TRANSCRIPTION_FINISHED = 'TRANSCRIPTION_FINISHED',
  CONFIG_OK = 'CONFIG_OK',
  AUTHENTICATION_OK = 'AUTHENTICATION_OK',
}

export interface IContent {
  //id: number;
  createdAt?: number;
  createdBy?: string;
  content: ITranscriptWord[] | string;
}
export interface IReceived {
  type: ReceivedMessageTypes;
  message?: string;
}
export interface IReceivedInfo extends IReceived {
  audioFile?: string;
  isNew?: boolean;
  previousRecordings?: number;
  totalRecordedSeconds?: number;
  sessionId?: number;
  recordingId?: number;
}

export interface IReceivedTranscript extends IReceived {
  isFinal: boolean;
  decoded: number;
  transcript: IContent;
  language?: string;
  isFixed?: boolean;
}


export interface IV3RecievedToken {
  isLeftHanded?: boolean,   // Optional field that determines if the token is left handed (implies a space before a token)
  isRightHanded?: boolean,  // Optional field that determines if the token is right handed (implies a space after a token)
  startOffsetMs: number,      // Optional field that can be used to set token start offset relative to the text segment start time.
  endOffsetMs: number,        // Optional field that can be used to set token end offset relative to the text segment start time.
  text: string                // Required field that determine actual token text
  speakerCode?: string;
}

export interface IV3RecievedMessage extends IReceived {
  textSegment: IV3RecievedTranscript
}
export interface IV3RecievedTranscript {
  isFinal: boolean,
  startTimeMs: number,
  endTimeMs: number,
  tokens: IV3RecievedToken[]
}

export interface IReceivedTranslation extends IReceivedTranscript {
  language: string;
}

export interface IReceivedError extends IReceived {
  errorCode: ErrorCodes;
  errorMessage: string;
}

export interface IReceivedUrl extends IReceived {
  url: string;
}

export interface IReceivedStatus extends IReceived {
  statusCode: Statuses;
  subsessionId?: string;
  selectedModelId?: number;
  audioFilename?: string;
}

export interface IReceivedDuration extends IReceived {
  duration: number;
}

// workersActive,
// workersAvailable,
// isRealtime,
// supportsDictatedCommands,
// supportsDictatedPunctuations,
// supportsDiarization
//Update all propertis with isAllowed
/*
TODO: This must be discussed!
interface IModelBase {
  isAvailable: boolean;
  isRealtime: boolean
  supportsDictatedCommands: boolean
  supportsDictatedPunctuations: boolean
  supportsDiarization: boolean
  version: string;
  isAllowed: boolean;
}

interface IModelAdmin extends IModelBase{
  workersActive: number
  workersAvailable: number
}

const test: IModelAdmin = {

}*/

export interface IModel {
  available: boolean;
  version: string;
  allowed: boolean;
  //1 - workersActive: number
  //2 - workersAvailable: number //if this is 0, model version is not available
  //1 + 2 = number of workers for this model version.
  realtime: boolean;
  diarizationSupport: boolean;
  dictationCommandsSupport: boolean;
}

export interface IConfig extends IReceived {
  [key: string]: any;
  audioChannels: number;
  audioChannelsOptions: number[];
  audioChannelsOptionEnabled: boolean;
  audioSampleRate: number;
  audioSampleRateOptions: number[];
  audioSampleRateOptionEnabled: boolean;
  transcriptionLanguage: string;
  transcriptionLanguageOptionEnabled: boolean;
  transcriptionDomainOptionEnabled: boolean;
  transcriptionDomain: string;
  transcriptionModelVersion: string;
  transcriptionModelOptions: string[];
  transcriptionModelOptionsEnabled: boolean;
  transcriptionDoInterim: boolean;
  transcriptionDoInterimOptionEnabled: boolean;
  transcriptionDoPunctuation: boolean;
  transcriptionDoPunctuationOptionEnabled: boolean;
  transcriptionDoTruecasiing: boolean;
  transcriptionDoTruecasiingOptionEnabled: boolean;
  transcriptionDoNormalisation: boolean;
  transcriptionDoNormalisationOptionEnabled: boolean;
  transcriptionDoDictation: boolean;
  transcriptionDoDictationOptionEnabled: boolean;
  translationEnabled: boolean;
  translationEnabledOptionEnabled: boolean;
  translationDoInterim: boolean;
  translationDoInterimOptionEnabled: boolean;
  translationLanguage: string;
  translationLanguageOptions: string[];
  translationLanguageOptionEnabled: boolean;
  translationEngine: string;
  translationEngineOptions: string[];
  translationEngineOptionEnabled: string[];
  transcriptionShowUnksOptionEnabled: boolean;
  transcriptionShowUnks: boolean;
  makeBlocks: boolean;
  makeBlocksOptionEnabled: boolean;
  saveToDisk: boolean;
  saveToDiskOptionEnabled: boolean;
  selectedModelId?: number;
  repopulate?: boolean;
}

export enum WsModes {
  LIVESTREAM = 'LIVESTREAM',
  WAV = 'WAV',
}

export interface IWsProps {
  mode: WsModes | undefined;
  wavFile?: any;
}
