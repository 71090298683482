import { IWordData } from '../Editor/IEditor';
import { saveAs } from 'file-saver';

export const MAX_WORDS_PER_LINE = 42;

const isPunctuation = (textValue: string) => textValue === "." || textValue === "?" || textValue === "!" || textValue === ","

export default (transcript: IWordData[], fileName: string) => {
  let words: IWordData[] = JSON.parse(JSON.stringify(transcript));

  // Subtitle string
  let subtitles: string = '';

  // The 4 .srt components
  let sequenceNumber: number = 1;
  let timeBuffer: string = '';
  let firstLineBuffer: string = '';
  let currentLineBuffer: string = '';

  // Helpers
  let sequenceNumberChanged = true;

  words.forEach((word, index, array) => {
    // If at the start of a new srt block then put start time in the timestamp
    const { spaceBefore, updatedText, text } = word;
    const selectedText = updatedText ? updatedText : text;

    if (isPunctuation(selectedText)) {
      subtitles += selectedText
    } else {
      if (sequenceNumberChanged && timeBuffer === '') {
        sequenceNumberChanged = false;
        timeBuffer += convertSecondsToSrtTimeString(word.startTime);
        timeBuffer += ' --> ';
      }
  
      // If it is the final word
      if (index === array.length - 1) {
        timeBuffer += convertSecondsToSrtTimeString(word.endTime);
        currentLineBuffer +=
          currentLineBuffer === '' ? selectedText : spaceBefore ? ` ${selectedText}` : selectedText;
        subtitles += generateSrtBlock(sequenceNumber, timeBuffer, firstLineBuffer, currentLineBuffer);
      }
  
      // If the word is the first of a final of the final word
      else if (index !== 0 && word.firstInFinal) {
        timeBuffer += convertSecondsToSrtTimeString(array[index - 1].endTime);
        subtitles += generateSrtBlock(sequenceNumber, timeBuffer, firstLineBuffer, currentLineBuffer);
  
        sequenceNumber++;
        timeBuffer = convertSecondsToSrtTimeString(word.startTime) + ' --> ';
        firstLineBuffer = '';
        // currentLineBuffer = word.text + ' ';
        currentLineBuffer =
          currentLineBuffer === '' ? selectedText : spaceBefore ? ` ${selectedText}` : selectedText;
  
        sequenceNumberChanged = true;
      }
  
      // Check if word can fit in the buffer
      else if (lineBufferCharacterNumber(currentLineBuffer) + word.text.length <= MAX_WORDS_PER_LINE) {
        // currentLineBuffer += word.text + ' ';
        currentLineBuffer +=
          currentLineBuffer === '' ? selectedText : spaceBefore ? ` ${selectedText}` : selectedText;
      }
  
      // If the first line buffer is empty put the current buffer there and insert the new word in the new one
      else if (firstLineBuffer === '') {
        firstLineBuffer = currentLineBuffer;
        // currentLineBuffer = word.text + ' ';
  
        currentLineBuffer =
          currentLineBuffer === '' ? selectedText : spaceBefore ? ` ${selectedText}` : selectedText;
      }
  
      // End of srt block
      else {
        timeBuffer += convertSecondsToSrtTimeString(array[index - 1].endTime);
        subtitles += generateSrtBlock(sequenceNumber, timeBuffer, firstLineBuffer, currentLineBuffer);
  
        sequenceNumber++;
        timeBuffer = convertSecondsToSrtTimeString(word.startTime) + ' --> ';
        firstLineBuffer = '';
        // currentLineBuffer = word.text + ' ';
        currentLineBuffer =
          currentLineBuffer.length === 0 ? selectedText : spaceBefore ? ` ${selectedText}` : selectedText;
        sequenceNumberChanged = true;
      }
    }
  });

  let subtitlesBlob = new Blob([subtitles], { type: 'text/plain' });
  saveAs(subtitlesBlob, fileName + '.srt');
};

const generateSrtBlock = (
  sequenceNumber: number,
  timestamp: string,
  firstLine: string,
  secondLine: string
) => {
  if (secondLine.charAt(0) === ' ') {
    secondLine = secondLine.slice(1);
  }

  if (firstLine.charAt(0) === ' ') {
    firstLine = firstLine.slice(1);
  }

  return (
    sequenceNumber +
    '\n' +
    timestamp +
    '\n' +
    (firstLine !== '' ? firstLine + '\n' : '') +
    secondLine +
    '\n\n'
  );
};

const lineBufferCharacterNumber = (lineBuffer: string): number => {
  let lineBufferCharacterNumber = 0;

  let lineBufferWithoutWhitespace = lineBuffer.split(' ');
  lineBufferWithoutWhitespace.forEach((word) => {
    lineBufferCharacterNumber += word.length;
  });

  return lineBufferCharacterNumber;
};

const convertSecondsToSrtTimeString = (secondsAndMilliseconds: number): string => {
  const allSeconds = Math.floor(secondsAndMilliseconds);
  const milliseconds = secondsAndMilliseconds - allSeconds;

  const hours = Math.floor(allSeconds / 3600);

  const remainingSeconds = allSeconds - 3600 * hours;
  const minutes = Math.floor(remainingSeconds / 60);

  let seconds = remainingSeconds - 60 * minutes;

  let timeString = '';

  timeString += hours < 10 ? '0' + hours : hours;
  timeString += ':';

  timeString += minutes < 10 ? '0' + minutes : minutes;
  timeString += ':';

  timeString += seconds < 10 ? '0' + seconds : seconds;
  timeString += ',';

  let stringMillies = String(milliseconds);
  stringMillies = stringMillies.split('.')[1];
  stringMillies = stringMillies ? stringMillies.substr(0, 3) : '';
  while (stringMillies.length < 3) {
    stringMillies += '0';
  }

  timeString += stringMillies;

  return timeString;
};
