import React, { useEffect, useState } from "react"
import './progress_bar.css'
import AnimateHeight from "react-animate-height";
import LinearProgress from "@mui/material/LinearProgress";
import { useAppSelector } from "../../redux/store";

const ProgressBar = () => {
    const [expand, setExpand] = useState<boolean>(false)
    const isDashboardLoading = useAppSelector(state => state.app.isDashboardLoading);
    
    useEffect(() => {
        setExpand(isDashboardLoading)
    }, [isDashboardLoading])

    return (
        <AnimateHeight
        duration={275}
        height={expand ? 'auto' : 0}>
            <LinearProgress />
        </AnimateHeight>
    ) 
}

export default ProgressBar;