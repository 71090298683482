import { useCallback } from "react";
import { ModelStatus, TagOperation, getTagInfo, getTagOperationStatus } from "../api/configurationService";
import { IModelStatus } from "../types";
import { useAppSelector } from "../redux/store";

const useModelStatus = (): IModelStatus => {
    const activeConfiguration = useAppSelector(store => store.app.activeConfiguration)

    const getModelStatus = useCallback(async (language?: string, domain?: string, modelName?: string): Promise<{ isUpdating: boolean, isUpdatable: boolean }> => {
        let status = {
            isUpdating: false,
            isUpdatable: false,
        }

        if (
            (activeConfiguration.language || language) &&
            (activeConfiguration.domain || domain) &&
            (activeConfiguration.model && activeConfiguration.model.name || modelName)
        ) {

            const tag = `${activeConfiguration.language ? activeConfiguration.language : language}%3A${activeConfiguration.domain ? activeConfiguration.domain : domain}%3A${activeConfiguration.model ? activeConfiguration.model.name : modelName}`
            try {
                await getTagInfo(tag)
                status.isUpdatable = true;
            } catch (error) {
                console.log('Model info response failed...')
            }

            try {
                const tagOperationStatus = await getTagOperationStatus(tag, TagOperation.UPDATE)
                if (tagOperationStatus.data.status === ModelStatus.RUNNING) {
                    status.isUpdating = true;
                }
            } catch (error) {
                console.log('Model status response failed...')
            }
        }

        return status;
    }, [activeConfiguration])
    
    return {
        getModelStatus
    };
};

export default useModelStatus;