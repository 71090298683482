import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import useKeycloak from '../../hooks/useKeycloak';

export interface IAuthGuardProps {
  children: JSX.Element;
}

const AuthGuard: FC<IAuthGuardProps> = ({
  children
}) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useKeycloak();

  if (!isAuthenticated) {
    navigate('/')
    return <></>
  }

  return <>{children}</>;
};

export default AuthGuard;
