import React, { FC } from 'react';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import AppliedFilters from './AppliedFilters';
import '../../styles/css/dashboard.css';
import V3Table from './V3Table';
import ProgressBar from './ProgressBar';
import SpeakerSettingsModal from '../SpeakerSettings/SpeakerSettingsModal';

const Viewer: FC = () => {
  return (
    <>
      <article style={{ backgroundColor: '#F7F7F7' }}>
        <DashboardHeader />
        <ProgressBar />
        <AppliedFilters />
        <V3Table />
      </article>
      <SpeakerSettingsModal />
    </>
  );
};

export default Viewer;
