import React from 'react';

export function CopyIcon({ fill, height, width, ...props }: any) {
    return (
        <svg onClick={props.onClick} className={props.className} fill="#FFFFFF" height="18px" width="18px" version="1.1" id="XMLID_154_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g id="copy">
                <g>
                    <path d="M19,24H1V4h4V0h12.4L23,5.6V20h-4V24z M3,22h14v-2H5V6H3V22z M7,18h14V8h-6V2H7V18z M17,6h3.6L17,2.4V6z M17,16H9v-2h8V16 z M19,12H9v-2h10V12z M13,8H9V6h4V8z"/>
                </g>
            </g>
        </svg>
    )
}
