import React, { ReactNode, useState } from 'react';
import { ShrinkIcon } from '../Icons/ShrinkIcon';
import '../../styles/css/header.css';
import '../../styles/css/dashboard.css';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';

interface IHeaderWrapper {
  children: ReactNode
}
const HeaderWrapper = ({ children }: IHeaderWrapper) => {
  const [extended, setExtended] = useState<boolean>(true);

  return (
    <>
    <div id="header" className="header-wrapper-dashboard">
      <Collapse in={extended}>
        <Grid
          className="header_extender_content_wrapper"
          justifyContent="space-between"
          alignItems="center"
          container
        >
          {children}
        </Grid>
      </Collapse>

      <Extender extended={extended} setExtended={setExtended} />
    </div>
    </>
  );
};

const Extender = ({ extended, setExtended }) => (
  <ShrinkIcon
    onClick={() => setExtended(!extended)}
    className={`header_extender ${extended ? 'extended' : ''}`}
  />
);

export default HeaderWrapper;
