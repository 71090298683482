import { SnackbarContent, CustomContentProps, closeSnackbar } from 'notistack'
import React from 'react'
import { forwardRef } from 'react'
import './auto_copy_error_message.css'
import { CrossIcon } from '../Icons/CrossIcon'
import { CopyIcon } from '../Icons/CopyIcon'


const AutoCopyErrorMessage = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
    const {
        message,
        id
    } = props

    const close = () => closeSnackbar(id)
    const onMessageClick = () => {
        navigator.clipboard.writeText((message as string).split(":")[1].trim())
        close();
    }

    return (
        <SnackbarContent className='custom-snackbar error' ref={ref}>
            <span className='custom-snackbar-span'>{message}</span>
            <CopyIcon className='custom-snackbar-icon' onClick={onMessageClick} />
            <CrossIcon className='custom-snackbar-icon' onClick={close} stroke="white" fill="white" />
        </SnackbarContent>
    )
})

export default AutoCopyErrorMessage;