import { useEffect, useState } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import { useAppDispatch } from '../redux/store';
import { setFontsLoaded } from '../redux/features/app/app';

const robotoFont = new FontFaceObserver('Roboto');
const robotoMonoFont = new FontFaceObserver('Roboto Mono');

export default function useLoadFonts() {
  const [robotoLoaded, setRobotoLoaded] = useState(false);
  const [robotoMonoLoaded, setRobotoMonoLoaded] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    robotoFont
      .load()
      .then(() => {
        setRobotoLoaded(true);
      })
      .catch(() => {
        setRobotoLoaded(true);
      });

    robotoMonoFont
      .load()
      .then(() => {
        setRobotoMonoLoaded(true);
      })
      .catch(() => {
        setRobotoMonoLoaded(true);
      });
  }, [dispatch]);

  useEffect(() => {
    if (robotoMonoLoaded && robotoLoaded) {
      dispatch(setFontsLoaded(true));
    }
  }, [robotoMonoLoaded, robotoLoaded]);
}
