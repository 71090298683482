import React from 'react';

import { UserRoleEnums } from '../../redux/store/IStore';
import BackButton from '../Header/BackButton';
import HeaderWrapper from './HeaderWrapper';
import Search from './Search';
import './../../styles/css/dashboard_header.css';
import useIsRole from '../../hooks/useIsRole';

const DashboardHeader = () => {
  const [isAdmim] = useIsRole(UserRoleEnums.ADMIN);

  return (
    <HeaderWrapper>
      <div className="header-title-wrapper">
        {/*<BackButton />*/}
        <div className="header-title-wrapper-inner">
          <div className="header-title">Zgodovina</div>
          {isAdmim && <div className="header-subtitle"> Admin </div>}
        </div>
      </div>
      <Search />
    </HeaderWrapper>
  );
};

export default DashboardHeader;
