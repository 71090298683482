import { useMemo } from 'react';

export default function useSelectedModelData() {
  const transcriptionLanguage = ""
  const transcriptionDomain = ""
  const transcriptionModelVersion = ""

  return useMemo(() => {
    return transcriptionLanguage && transcriptionModelVersion && transcriptionDomain
      ? {
          language: transcriptionLanguage,
          modelVersion: transcriptionModelVersion,
          domain: transcriptionDomain,
        }
      : null;
  }, [transcriptionLanguage, transcriptionModelVersion, transcriptionDomain]);
}
