import axiosInstance from './axios';
import { AxiosResponse } from 'axios';
import { API, PIPELINES, PROCESS } from './endpoints';
import { config } from '../config';
import { IConfigurationSend } from '../components/Home/ISettings';
import { IProcessFile } from './types';

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/${PIPELINES}/${PROCESS}?async=true`;

export const processFile = (file: File, pipeline: IConfigurationSend, updateProgress: (value: number) => void): Promise<AxiosResponse<IProcessFile>> => {
    var data = new FormData();
    
    const binaryData = new Blob([file], { type: 'application/octet-stream' });
    const jsonBlob = new Blob([JSON.stringify(pipeline)], { type: 'application/json' });
    data.append("pipeline", jsonBlob)
    data.append('data', binaryData, file.name);

    return axiosInstance.post(`${baseUrl}`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
            updateProgress(event.loaded/(event.total ? event.total : event.loaded))
        },
    });
};