import React from 'react';

function useInterval(callback: () => void, delay: number | null, deps: any[] = [], exception?: boolean) {
  const savedCallback = React.useRef<() => void | null>();
  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  });
  // Set up the interval.
  const tick = () => {
    if (typeof savedCallback?.current !== 'undefined') {
      savedCallback?.current();
    }
  }

  React.useEffect(() => {
    if (exception) return;

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [...deps, delay, exception]);
}

export default useInterval;
