import { API, INFO } from './endpoints';
import axiosAuth from './axios';
import { config } from '../config';

const backendUrl: string = config.backendApiUrl;

const baseUrl: string = `${backendUrl}/${API}/${INFO}`;

export const getAbout = (): Promise<any> => {
  return axiosAuth.get(baseUrl);
};
