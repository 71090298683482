import { AxiosPromise } from 'axios';
import { config } from '../config';
import axiosInstance from './axios';
import { API, ME, QUOTA, USERS } from './endpoints';

const backendUrl: string = config.backendApiUrl;

interface IUserQuotaChunk {
    secondsConsumed: number,
    secondsLimit?: number,
    secondsWarn?: number,
}
export interface IUserQuota {
    daily: IUserQuotaChunk,
    monthly: IUserQuotaChunk,
    total: IUserQuotaChunk,
    weekly: IUserQuotaChunk,
    yearly: IUserQuotaChunk,
}

export enum UserQuotaKeys {
    daily = "daily",
    monthly = "monthly",
    total = "total",
    weekly = "weekly",
    yearly = "yearly",
}
export interface IActiveUserQuotaChunk {
    key: UserQuotaKeys,
    chunk: IUserQuotaChunk,
}

export const getUserQuota = (): AxiosPromise<IUserQuota>=> {
    const baseUrl = `${backendUrl}/${API}/${USERS}/${ME}/${QUOTA}`;
    return axiosInstance.get(baseUrl);
}

interface IUserData {
    id: number;
    username: string;
    group: string;
}
export const getUserData = (): AxiosPromise<IUserData>=> {
    const baseUrl = `${backendUrl}/${API}/${USERS}/${ME}`;
    return axiosInstance.get(baseUrl);
}

//Returns a quota chunk, which is closest to being fulfilled
export const getActiveQuota = (quota: IUserQuota): IActiveUserQuotaChunk | null => {
    let activeRatio = -1;
    let activeUserQuotaChunk: IActiveUserQuotaChunk | null = null
    
    for (const timeframe in quota) {
        const ratio = quota[timeframe].secondsConsumed / quota[timeframe].secondsLimit
        if (ratio > activeRatio) {
            activeRatio = ratio
            activeUserQuotaChunk = {
                key: timeframe as UserQuotaKeys,
                chunk: quota[timeframe],
            }
        }
    }

    if (activeRatio === -1) {
        activeUserQuotaChunk = {
            key: UserQuotaKeys.monthly,
            chunk: quota.monthly
        }
    }

    console.log(activeUserQuotaChunk)

    return activeUserQuotaChunk;
}

export const translateChunkKey = (key: UserQuotaKeys) => {
    switch(key) {
        case UserQuotaKeys.daily: return "dnevne"
        case UserQuotaKeys.weekly: return "tedenske"
        case UserQuotaKeys.monthly: return "mesečne"
        case UserQuotaKeys.yearly: return "letne"
        case UserQuotaKeys.total: return "skupne"
    }
}


export const translateLimitKey = (key: UserQuotaKeys) => {
    switch(key) {
        case UserQuotaKeys.daily: return "na dan"
        case UserQuotaKeys.weekly: return "na teden"
        case UserQuotaKeys.monthly: return "na mesec"
        case UserQuotaKeys.yearly: return "na leto"
        case UserQuotaKeys.total: return "skupno"
    }
}

export const transformConsumedAndLimitToReadable = (consumedSeconds: number, limitSeconds: number) => {
    const consumedHours = Math.floor(consumedSeconds / 3600);
    const consumedMinutes = Math.floor((consumedSeconds % 3600) / 60);

    const limitHours = Math.floor(limitSeconds / 3600);
    const limitMinutes = Math.floor((limitSeconds % 3600) / 60);

    return `${consumedHours}h ${consumedMinutes}min od ${limitHours}h ${limitMinutes}min`
}

export const transformLimitToReadable = (key: UserQuotaKeys, limitSeconds: number) => {
    const limitHours = Math.floor(limitSeconds / 3600);
    const limitMinutes = Math.floor((limitSeconds % 3600) / 60);

    return `${limitHours}h ${limitMinutes}min ${translateLimitKey(key)}`
}

export const transformConsumedToReadable = (key: UserQuotaKeys, consumedSeconds: number) => {
    const limitHours = Math.floor(consumedSeconds / 3600);
    const limitMinutes = Math.floor((consumedSeconds % 3600) / 60);

    return `${limitHours}h ${limitMinutes}min`
}

/*export const transformConsumptionToReadable = (key: UserQuotaKeys, consumedSeconds: number) => {
    const limitHours = Math.floor(consumedSeconds / 3600);
    const limitMinutes = Math.floor((consumedSeconds % 3600) / 60);

    return `${limitHours}h ${limitMinutes}min ${translateLimitKey(key)}`
}*/