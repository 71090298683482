import Editor from '../Editor/Editor';
import AuthGuard, { IAuthGuardProps } from '../Guards/AuthGuard';
import Home from '../Home/Home';
import Dashboard from '../Dashboard/Dashboard';
import { FC, FunctionComponent, ReactNode } from 'react';
import { EditorProvider } from '../../providers/EditorProvider';
import HomescreenProvider from '../../providers/HomescreenProvider';
import DictionaryMain from '../Dictionary/DictionaryMain';
import { config } from '../../config';
import { RouteProps } from 'react-router-dom';

type CustomRoutes = {
  guard?: FC<IAuthGuardProps>;
  provider?: FunctionComponent<any>;
  exact: boolean;
  path: string;
  component: FC;
}
export type MainRoute = CustomRoutes

export const mainRoutes: MainRoute[] = [
  { exact: true, path: '/', component: Home, provider: HomescreenProvider },
  { exact: true, path: '/editor', guard: AuthGuard, component: Editor, provider: EditorProvider },
  { exact: true, path: '/dashboard', guard: AuthGuard, component: Dashboard },
  ...(config.environment !== 'demo'
    ? [{ exact: true, path: '/dictionary', guard: AuthGuard, component: DictionaryMain }]
    : []),
];
