import React, { useEffect, useState } from 'react';
import './classess.css';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { registerNewClientLabel, getClientLabels, patchClientLabel } from '../../api/SessionsService';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Zoom from '@mui/material/Zoom';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setSessionsLabels } from '../../redux/features/app/app';

const tempColours = ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'];

const AddLabelsButton = () => {
  const dispatch = useAppDispatch();

  const [newLabel, setNewLabel] = useState<string>('');
  const [anchorElAddLabel, setAnchorElAddLabel] = useState<null | HTMLElement>(null);

  const availableLabels = useAppSelector(state => state.app.sessionsLabels)

  const addButtonOpen = Boolean(anchorElAddLabel);
  const addButtonId = addButtonOpen ? 'simple-popper' : undefined;

  const generateColor = () => {
    return tempColours[availableLabels.length % tempColours.length];
  };

  const clickAwayHandler = () => {
    if (anchorElAddLabel) setAnchorElAddLabel(null);
  };

  const addLabelClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElAddLabel) {
      const target = event.currentTarget;
      getClientLabels().then(({ data }) => {
        dispatch(setSessionsLabels(data));
        setAnchorElAddLabel(target);
      });
    } else setAnchorElAddLabel(null);
  };

  const registerNewDefaultLabel = () => {
    const index = availableLabels.findIndex(({ code }) => code === newLabel);

    if (index !== -1) {
      patchClientLabel(availableLabels[index].id, { isDefault: true }).then(() => {
        setNewLabel('');
        getClientLabels().then(({ data }) => dispatch(setSessionsLabels(data)));
      });
    } else {
      registerNewClientLabel(newLabel, generateColor(), true).then(() => {
        setNewLabel('');
        getClientLabels().then(({ data }) => dispatch(setSessionsLabels(data)));
      });
    }
  };

  const deleteDefaultLabel = (id) => {
    patchClientLabel(id, { isDefault: false }).then(() => {
      getClientLabels().then(({ data }) => dispatch(setSessionsLabels(data)));
    });
  };

  const addDefaultLabel = (id) => {
    patchClientLabel(id, { isDefault: true }).then(() => {
      getClientLabels().then(({ data }) => dispatch(setSessionsLabels(data)));
    });
  };

  useEffect(() => {
    getClientLabels(newLabel).then(({ data }) => {
      dispatch(setSessionsLabels(data));
    });
  }, [newLabel])

  return (
    <>
      <button onClick={addLabelClick}>
        <img src="more.svg" alt="Info svg" style={{ width: 24, height: 24 }} />
      </button>
      <Popper
        id={addButtonId}
        open={addButtonOpen}
        anchorEl={anchorElAddLabel}
        placement="right-end"
        disablePortal={false}
        style={{ zIndex: 100000 }}
        modifiers={[
          {
              name: 'flip',
              enabled: true,
          },
          {
              name: 'preventOverflow',
              enabled: true,
              options: {
                  boundariesElement: 'scrollParent'
              },
          }
      ]}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={clickAwayHandler}>
            <Zoom {...TransitionProps} timeout={350}>
              <Paper
                elevation={3}
                className="accordion-chip-popper-container roundedcorners paper"
                style={{ marginLeft: '-5px' }}
                square={false}
              >
                <div style={{ maxHeight: 350, overflowY: 'scroll' }}>
                  {availableLabels
                    .map(({ id, code, color, isDefault }) => (
                      <MenuItem
                        key={id}
                        dense
                        className='menuItem'
                        onClick={(e) => e.stopPropagation()}
                        disableRipple
                      >
                        <div className="accordion-chip-popper-dot" style={{ backgroundColor: color }} />
                        {code}

                        <div style={{ flexGrow: 1 }} />

                        {isDefault && (
                          <Tooltip title={`Odstrani privzeto labelo '${code}'`}>
                            <IconButton
                              aria-label="delete"
                              onClick={() => deleteDefaultLabel(id)}
                              className='deleteButton'
                            >
                              <DeleteOutlinedIcon className='deleteIcon' fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        ) || (
                            <Tooltip title={`Dodaj privzeto labelo '${code}'`}>
                              <IconButton
                                aria-label="add"
                                onClick={() => addDefaultLabel(id)}
                                className='addButton'
                              >
                                <AddOutlinedIcon fontSize="small" className='addButton' />
                              </IconButton>
                            </Tooltip>
                          )}
                      </MenuItem>
                    ))}
                </div>

                <div className="accordion-chip-popper-footer">
                  <AddCircleOutlineOutlined
                    fontSize="small"
                    className="accordion-chip-popper-footer-add"
                    style={{ color: generateColor() }}
                  />
                  <TextField
                    fullWidth
                    placeholder="Vnesi ime labele"
                    value={newLabel}
                    spellCheck={false}
                    onChange={(e) => setNewLabel(e.target.value)}
                    variant="outlined"
                    className='paddingoutline'
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') registerNewDefaultLabel();
                    }}
                  />
                </div>
              </Paper>
            </Zoom>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default AddLabelsButton;
