import { setClickedTime } from '../redux/features/app/app';
import { EditorModeEnums } from '../redux/store/IStore';
import useKeyPress from './useKeyPress';
import { useAppDispatch, useAppSelector } from '../redux/store';

const useShortcuts = ({
  toggleRecording,
  togglePlayPause,
  toggleSpellCheck,
  setDynamicPlaybackrate,
  playbackRate,
  editorMode,
  duration,
  playing,
  exception,
}: {
  toggleRecording: () => void;
  togglePlayPause: (e?: React.SyntheticEvent) => void;
  toggleSpellCheck: () => void;
  setDynamicPlaybackrate: React.Dispatch<React.SetStateAction<number>>;
  playbackRate: number;
  editorMode: EditorModeEnums;
  duration: number | null;
  playing: boolean;
  exception?: boolean;
}) => {
  const currentTime = useAppSelector(state => state.app.currentTime);
  // Variables which store keypress state

  const dispatch = useAppDispatch();

  const handleBackwardClick = () => {
    if (editorMode === EditorModeEnums.PLAYING_MODE) {
      if (currentTime - 5 <= 0) {
        dispatch(setClickedTime(0));
      }
      dispatch(setClickedTime(currentTime - 5));
    }
  };

  const handleForwardClick = () => {
    if (editorMode === EditorModeEnums.PLAYING_MODE) {
      dispatch(setClickedTime(currentTime + 5));
    }
  };

  useKeyPress({
    targetKey: 'Tab',
    exception,
    callback: (keyState) => {
      if (
        (editorMode === EditorModeEnums.EDIT_MODE || editorMode === EditorModeEnums.PLAYING_MODE) &&
        keyState === 'down'
      ) {
        togglePlayPause(undefined);
      }
    },
  }); // play/pause

  useKeyPress({
    targetKey: 'Escape',
    exception: exception || playing,
    callback: (keyState) => {
      if (keyState === 'down') {
        toggleRecording();
      }
    },
  }); // STOP Live recording

  useKeyPress({
    targetKey: 'F1',
    exception,
    callback: (keyState) => {
      if (keyState === 'down') {
        toggleSpellCheck();
      }
    },
  }); // confidence

  useKeyPress({
    targetKey: 'F2',
    exception,
    callback: (keyState) => {
      if (keyState === 'down') {
        if (playbackRate + 0.2 >= 4) {
          setDynamicPlaybackrate(0.3);
        } else {
          setDynamicPlaybackrate(playbackRate + 0.2);
        }
      }
    },
  }); // playbackrate

  useKeyPress({
    targetKey: 'F9',
    exception,
    callback: (keyState, e) => {
      if (keyState === 'down') {
        if (editorMode === EditorModeEnums.PLAYING_MODE) {
          if (duration && currentTime + 5 >= duration) {
            dispatch(setClickedTime(duration));
          } else {
            dispatch(setClickedTime(currentTime + 5));
          }
        }
      }
    },
  }); // ff
  useKeyPress({
    targetKey: 'F8',
    exception,
    callback: (keyState) => {
      if (keyState === 'down') {
        if (editorMode === EditorModeEnums.PLAYING_MODE) {
          if (currentTime - 5 <= 0) {
            dispatch(setClickedTime(0));
          }
          dispatch(setClickedTime(currentTime - 5));
        }
      }
    },
  }); // fb

  const altDown = useKeyPress({ targetKey: 'Alt', exception });
  useKeyPress({
    targetKey: 'ArrowLeft',
    exception,
    preventDefault: false,
    callback: (keyState, e) => {
      if (altDown === 'down' && keyState === 'down') {
        e?.preventDefault();
        handleBackwardClick();
      }
    },
  });
  useKeyPress({
    targetKey: 'ArrowRight',
    exception,
    preventDefault: false,
    callback: (keyState, e) => {
      if (altDown === 'down' && keyState === 'down') {
        e?.preventDefault();
        handleForwardClick();
      }
    },
  });
};

export default useShortcuts;
