import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { V3WebSocketProvider } from '../../providers/V3WebSocketProvider';
import Layout from './Layout';

const Main = () => {
  return (
    <>
      <Router>
      <V3WebSocketProvider>
        <Layout />
        </V3WebSocketProvider>
      </Router>
    </>
  );
};

export default Main;
