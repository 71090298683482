import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { CSSProperties, useCallback, useEffect, useRef } from 'react';

import useEditor from '../../hooks/useEditor';
import useLiveEditorRefs from '../../hooks/useLiveEditorReft';
import { EditorModeEnums, IStore } from '../../redux/store/IStore';
import {
  convertReceivedTranscriptToWordDataLiveNew,
  expandWordVariables,
  findInputByIndex,
  findInputIndexByPosition,
  findNextInputPosition,
  findPreviousInputPosition,
  getNextNonEmptyWord,
  getPreviousNonEmptyWord,
  mergeEntryToLiveWordDataParagraphs,
} from '../../shared/DataConverters';
import { IReceivedTranscript, ReceivedMessageTypes } from '../Libraries/ILibraries';
import { allSubsStringsConverted, fixedKeys } from './constants';
import {
  checkIfCommandForFindModeIsInReceivedTr,
  checkIfSubOrCommandForFixModeIsInReceivedTr,
  checkIfSubOrCommandForInsModeIsInReceivedTr,
  checkIfWordIsSelected,
  findSearchedOrNextWordInLiveWordData,
  generateNewLiveWordDataWithInsertion,
  generateNewReplaceWords,
} from './helpers/commandModesHelpers';
import {
  Colors,
  Commands,
  CurrentCommandModeEnums,
  ICommandSub,
  IEntry,
  ISubsContent,
  ITranscriptWord,
  IWordData,
} from './IEditor';
import LastPostitionCursor, { CursorTypesEnum } from './LastPositionCursor';
import LiveWord from './LiveWord';
import SpeakerSection from './SpeakerSection';
import useV3Ws from '../../hooks/useV3Ws';
import { getEntries } from '../../api/entriesService';
import { getSpelledWordSubs, getWordSubs } from '../../api/DictionaryService';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setTriggerStopRecording } from '../../redux/features/app/app';

interface ILiveEditorProps {
  isNewRecordingSession: React.MutableRefObject<boolean>;
  hasEditorContentChanged: React.MutableRefObject<boolean>;
}

function genInlineStylesForInterim(
  isBold: boolean,
  isItalic: boolean,
  isUnderline: boolean,
  isInInput: boolean
): CSSProperties {
  return {
    ...(isBold && { fontWeight: 'bold' }),
    ...(isItalic && { fontStyle: 'italic' }),
    ...(isUnderline && { textDecoration: 'underline' }),
    ...(isInInput && {
      borderBottom: `solid 1px ${Colors.OUTLINE_ACTIVE_INPUT}`,
    }),
    backgroundColor: '#fafafa',
    color: '#cccccc ',
  };
}

function LiveEditor({ isNewRecordingSession, hasEditorContentChanged }: ILiveEditorProps) {
  const dispatch = useAppDispatch();
  
  const {
    liveWordData,
    setCursor,
    cursor,
    fixSpellCommandMode,
    setLiveWordData,
    setFixSpellCommandMode,
    setCommandModeSubs,
    findCommandMode,
    setFindCommandMode,
    commandModeMoveSubs,
    setCommandModeMoveSubs,
    commandModeSubs,
    changeCursor,
    setFooterTr,
    setInsCommandMode,
    insCommandMode,
    setTranscriptData,
  } = useEditor();
  
  const editorMode = useAppSelector(state => state.app.editorMode);
  const {
    language,
    domain,
    model,
  } = useAppSelector(state => state.app.activeConfiguration);

  const {
    lastJsonMessage: receivedMessage,
  } = useV3Ws();
  const { enqueueSnackbar } = useSnackbar();

  const {
    isInSubModeOfFindMode,
    currentReplaceTextRef,
    ucaInsertion,
    lcaInsertion,
    cnInsertion,
    isInFindMode,
    capitalizeNextLetter,
    capitalizeNextWordCommand,
    lowerCaseAllNext,
    upperCaseAllNext,
    ucSpelling,
    ccSpelling,
    lcSpelling,
    trCounter,
    LCSpelling,
    boldNext,
    boldOn,
    italicNext,
    italicOn,
    underlineNext,
    underlineOn,
    liveWordDataContainsInputs,
  } = useLiveEditorRefs();

  const bottomScrollerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (
      editorMode === EditorModeEnums.RECORDING_MODE ||
      editorMode === EditorModeEnums.TRANSCRIBING_UPLOAD_MODE
    ) {
      if (cursor.cursorPosition) {
        const c = document.getElementById('cursor-position');
        if (c) {
          //TO-DO: Think about if this makes sense or how to make UX better when user has cursor on random words
          // c.scrollIntoView({ block: 'center', inline: 'center' });
        }
      } else {
        bottomScrollerRef.current?.scrollIntoView();
      }
    }
  }, [liveWordData.finalsBlocks, editorMode]);

  useEffect(() => {
    if (
      findCommandMode.findCommandModeOn &&
      findCommandMode.searchedText &&
      findCommandMode.selectedWordIndexes
    ) {
      const c = document.getElementById(
        `live_word_${findCommandMode.selectedWordIndexes[0].toString()}${findCommandMode.selectedWordIndexes[1].toString()}`
      );
      if (c) {
        c.scrollIntoView({ block: 'center', inline: 'center' });
      }
    }
  }, [findCommandMode]);

  useEffect(() => {
    if (fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.SPELL_MODE_ONLY) {
      setCursor({
        cursorType: CursorTypesEnum.SPELL,
        cursorPosition: null,
      });
    } else {
      setCursor((curr) => {
        return {
          ...curr,
          cursorType: CursorTypesEnum.NORMAL,
        };
      });
    }
  }, [fixSpellCommandMode.currentCommandMode]);

  useEffect(() => {
    if (
      fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.FIX_MODE ||
      fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.SPELL_MODE_ONLY ||
      insCommandMode.isOn
    ) {
      setLiveWordData((curr) => {
        return {
          ...curr,
          lastInterim: '',
        };
      });
    } else {
      setCommandModeSubs({
        subsState: 'loading',
        subs: null,
        selectedSubText: undefined,
      });
    }
  }, [fixSpellCommandMode.currentCommandMode, insCommandMode.isOn]);

  useEffect(() => {
    if (!findCommandMode.findCommandModeOn) {
      isInSubModeOfFindMode.current = false;
      currentReplaceTextRef.current = {
        finals: [],
        lastInterim: [],
      };
    }
  }, [findCommandMode]);

  useEffect(() => {
    if (findCommandMode.findCommandModeOn) {
      setLiveWordData((curr) => {
        return {
          ...curr,
          lastInterim: '',
        };
      });
    } else {
      isInSubModeOfFindMode.current = false;
      ucaInsertion.current = false;
      lcaInsertion.current = false;
      cnInsertion.current = false;
    }
  }, [findCommandMode.findCommandModeOn]);

  useEffect(() => {
    if (!commandModeMoveSubs) return;
    setCommandModeMoveSubs(null);
  }, [commandModeMoveSubs]);

  const generateFooterTr = (parsedContent: any, isFinal: boolean) => {
    let newString = '';
    parsedContent.forEach((w) => {
      newString += ' ' + w.text;
    });
    const toReturn = isFinal
      ? {
          finalStr: newString,
          interimStr: '',
        }
      : {
          finalStr: '',
          interimStr: newString,
        };
    setFooterTr(toReturn);
  };

  useEffect(() => {
    if (receivedMessage && receivedMessage.type === ReceivedMessageTypes.TEXT_SEGMENT) {
      // Got valid transcript
        // console.log('RECEIVED MESSAGE:', receivedMessage);
        let transcriptMessage: IReceivedTranscript = receivedMessage as IReceivedTranscript;
        let parsedContent: ITranscriptWord[] = [];

        if (typeof transcriptMessage.transcript.content === 'string') {
          parsedContent = JSON.parse(transcriptMessage.transcript.content);
        } else {
          parsedContent = transcriptMessage.transcript.content as ITranscriptWord[];
        }


        let tokenIndex = -1
        for (let index = 0; index < parsedContent.length; index++) {
          const token = parsedContent[index]
          if (tokenIndex > -1 && (
            token.text.toLowerCase() === 'nareka' ||
            token.text.toLowerCase() === 'diktata' ||
            token.text.toLowerCase() === 'snemanja' ||
            token.text.toLowerCase() === 'snemanje'
          )) {
            break;
          } else if (
            token.text.toLowerCase() === 'konec' ||
            token.text.toLowerCase() === 'zaključi' ||
            token.text.toLowerCase() === 'zakluči'
          ) {
            tokenIndex = index
          } else if (token.text === Commands.END_RECORDING) {
            tokenIndex = index;
            break;
          } else {
            tokenIndex = -1;
          }
        }

        const isFinal = transcriptMessage.isFinal;
        if (isFinal && tokenIndex > -1) {
          parsedContent.splice(tokenIndex);
          setTranscriptData((curr) => {
            return [...curr, parsedContent];
          });
          dispatch(setTriggerStopRecording(true));
        } else if (isFinal) {
          setTranscriptData((curr) => {
            return [...curr, parsedContent];
          });
        }

        // if state in insert block command mode we handle received tr here and return
        if (insCommandMode.isOn) {
          if (transcriptMessage.isFinal) {
            checkIfSubOrCommandForInsModeIsInReceivedTr(parsedContent, commandModeSubs.subs, {
              onConfirm: () => {
                setInsCommandMode({ isOn: false });
              },
              onCancel: () => {
                // TO-DO: REMOVE entry from liveworddata
                setInsCommandMode({ isOn: false });
              },
              onShowMoreLess: (selectedSub) => {
                setCommandModeMoveSubs(selectedSub === Commands.SHOW_LESS ? 'L' : 'R');
              },
              onSelect: (sub) => {
                if (sub.entry) {
                  const { cursorPosition } = cursor;
                  // console.log('From onSelect cursorPosition:', cursorPosition);
                  // console.log('From onSelect    sub.entry.content:', sub.entry.content);
                  let { data, newCursorPos } = mergeEntryToLiveWordDataParagraphs(
                    liveWordData.finalsBlocks,
                    expandWordVariables(sub.entry.content),
                    cursorPosition
                  );

                  // Check if there is any {input} in the new data. If it is,
                  // then move to cursor to the first one.
                  const inputCursorPos = findNextInputPosition(data, cursorPosition);

                  if (!!inputCursorPos) {
                    liveWordDataContainsInputs.current = true;
                    newCursorPos = inputCursorPos;
                  }

                  if (newCursorPos) {
                    setCursor((curr) => {
                      return {
                        ...curr,
                        cursorPosition: newCursorPos,
                      };
                    });
                  }

                  setCommandModeSubs((curr) => {
                    return {
                      ...curr,
                      selectedSubText: sub.subText,
                    };
                  });

                  setLiveWordData((curr) => {
                    return {
                      ...curr,
                      finalsBlocks: data,
                    };
                  });

                  // TO-DO: uncomment below if we want to also handle canceling
                  // setInsCommandMode((curr) => {
                  //   return {
                  //     ...curr,
                  //     insertedEntryInfo: {
                  //       startPos: cursorPosition,
                  //       length: sub.entry?.content.length || 0,
                  //     },
                  //   };
                  // });
                  setInsCommandMode({ isOn: false });
                }
              },
            });
          }

          return;
        }

        // If state already in Find command mode
        if (typeof isInSubModeOfFindMode.current === 'string') {
          if (isFinal) {
            const foundCommand = checkIfCommandForFindModeIsInReceivedTr(parsedContent, {
              isInFindMode: false,
              isInSelectMode: false,
            });
            if (foundCommand === Commands.CONFIRM || foundCommand === Commands.CANCEL) {
              setFindCommandMode({
                findCommandModeOn: false,
                selectedWordIndexes: null,
                searchedText: null,
                selectedWordData: null,
                selectedRangeLength: 1,
              });
              isInFindMode.current = false;
              isInSubModeOfFindMode.current = false;
              currentReplaceTextRef.current = {
                finals: [],
                lastInterim: [],
              };

              setCursor({ cursorType: CursorTypesEnum.NORMAL, cursorPosition: null });
              return;
            }
          }

          if (isInSubModeOfFindMode.current === 'repl') {
            if (!findCommandMode.selectedWordIndexes) return;

            const newWords = generateNewReplaceWords(parsedContent);
            const { finals, lastInterim } = currentReplaceTextRef.current;
            if (isFinal) {
              const newFinals = [...finals, ...newWords];
              currentReplaceTextRef.current = {
                finals: newFinals,
                lastInterim: [],
              };
            } else {
              currentReplaceTextRef.current = {
                finals: finals,
                lastInterim: newWords,
              };
            }

            setLiveWordData((curr) => {
              if (!findCommandMode.selectedWordIndexes) return curr;

              const selectedBlockI = findCommandMode.selectedWordIndexes[0];
              const swi = findCommandMode.selectedWordIndexes[1];
              let newBlocks = cloneDeep(curr.finalsBlocks);
              newBlocks[selectedBlockI].words.splice(
                swi,
                findCommandMode.selectedRangeLength,
                ...currentReplaceTextRef.current.finals
              );

              return {
                ...curr,
                finalsBlocks: newBlocks,
              };
            });

            setFindCommandMode((curr) => {
              return {
                ...curr,
                selectedWordData: [...currentReplaceTextRef.current.finals],
                selectedRangeLength: currentReplaceTextRef.current.finals.length,
              };
            });

            setCursor({
              cursorType: CursorTypesEnum.NORMAL,
              cursorPosition: [
                findCommandMode.selectedWordIndexes[0],
                findCommandMode.selectedWordIndexes[1] + currentReplaceTextRef.current.finals.length - 1,
              ],
            });
          } else if (isInSubModeOfFindMode.current === 'insb' || isInSubModeOfFindMode.current === 'insa') {
            const newLiveWordData = generateNewLiveWordDataWithInsertion(
              parsedContent,
              liveWordData,
              isFinal,
              isInSubModeOfFindMode.current === 'insb' ? 'before' : 'after',
              {
                uca: ucaInsertion.current,
                lca: lcaInsertion.current,
                cn: cnInsertion.current,
              },
              findCommandMode.selectedWordIndexes
            );

            if (newLiveWordData?.flags) {
              const { upperCaseAll: uca, lowerCaseAll: lca, capitalizeNext: cn } = newLiveWordData.flags;

              ucaInsertion.current = uca;
              lcaInsertion.current = lca;
              cnInsertion.current = cn;

              if (!uca && !lca && !cn) {
                // changeCursor(CursorTypesEnum.NORMAL);
              }
            }

            findCommandMode.selectedWordIndexes &&
              newLiveWordData &&
              setCursor({
                cursorType: cnInsertion.current
                  ? CursorTypesEnum.UPPER_CASE_FIRST
                  : ucaInsertion.current
                  ? CursorTypesEnum.UPPER_CASE_ALL
                  : lcaInsertion.current
                  ? CursorTypesEnum.LOWER_CASE_ALL
                  : CursorTypesEnum.NORMAL,
                cursorPosition: [
                  findCommandMode.selectedWordIndexes[0],
                  findCommandMode.selectedWordIndexes[1] + newLiveWordData?.addedLength,
                ],
              });

            newLiveWordData &&
              setFindCommandMode((curr) => {
                if (!curr.selectedWordIndexes) return curr;
                return {
                  ...curr,
                  selectedWordIndexes: [
                    curr.selectedWordIndexes[0],
                    curr.selectedWordIndexes[1] + newLiveWordData?.addedLength,
                  ],
                  isInsertMode: isInSubModeOfFindMode.current === 'insb' ? 'insb' : 'insa',
                };
              });

            newLiveWordData && setLiveWordData(newLiveWordData.liveWordDataCopy);
          }

          return;
        }
        if (
          findCommandMode.findCommandModeOn &&
          findCommandMode.searchedText &&
          findCommandMode.searchedText !== '' &&
          findCommandMode.selectedWordIndexes
        ) {
          // if state already in FIND MODE we handle received tr here
          if (!isFinal) return;
          // handle received trs && commands specific for when in findCommandMode

          const foundCommand = checkIfCommandForFindModeIsInReceivedTr(parsedContent, {
            isInFindMode: false,
            isInSelectMode: false,
          });

          if (foundCommand === null) return;

          if (
            foundCommand === Commands.PREV ||
            (foundCommand === Commands.NEXT && !findCommandMode.lostSearchContext)
          ) {
            const newSearchedWord = findSearchedOrNextWordInLiveWordData({
              blocks: liveWordData.finalsBlocks,
              searchedText: findCommandMode.searchedText,
              findAll: false,
              startIndexes: findCommandMode.selectedWordIndexes,
              directionToSearch: foundCommand === Commands.PREV ? 'left' : 'right',
            });

            if (newSearchedWord === null) {
              // word not found - show message ?
            }

            if (newSearchedWord && typeof newSearchedWord === 'object') {
              setFindCommandMode((curr) => {
                return {
                  ...curr,
                  selectedWordIndexes: newSearchedWord
                    ? [newSearchedWord.wordIndexes[0], newSearchedWord.wordIndexes[1]]
                    : null,
                  selectedWordData: newSearchedWord ? [newSearchedWord.wordData] : null,
                };
              });
            }
          } else if (foundCommand === Commands.CONFIRM || foundCommand === Commands.CANCEL) {
            setFindCommandMode({
              findCommandModeOn: false,
              selectedWordIndexes: null,
              searchedText: null,
              selectedWordData: null,
              selectedRangeLength: 1,
            });
            isInFindMode.current = false;

            setCursor({ cursorType: CursorTypesEnum.NORMAL, cursorPosition: null });
            return;
          } else if (typeof foundCommand === 'object') {
            const { command, numOfWords } = foundCommand;

            if (command === Commands.LEFT || command === Commands.RIGHT) {
              if (
                findCommandMode.selectedRangeLength > 1 &&
                findCommandMode.selectedWordData &&
                findCommandMode.selectedWordData?.length > 1
              ) {
                setFindCommandMode((curr) => {
                  if (!curr.selectedWordIndexes) return curr;

                  const nextWord = findSearchedOrNextWordInLiveWordData({
                    blocks: liveWordData.finalsBlocks,
                    searchedText: '',
                    startIndexes: curr.selectedWordIndexes,
                    directionToSearch: command === Commands.RIGHT ? 'right' : 'left',
                    findAll: false,
                    jumpNumber: numOfWords > 1 ? numOfWords : undefined,
                  });
                  if (!nextWord) return curr;

                  const newselectedWords = liveWordData.finalsBlocks[nextWord.wordIndexes[0]].words.slice(
                    nextWord.wordIndexes[1],
                    nextWord.wordIndexes[1] + findCommandMode.selectedRangeLength
                  );

                  return {
                    ...curr,
                    selectedWordIndexes: nextWord.wordIndexes,
                    selectedWordData: newselectedWords,
                    lostSearchContext: true,
                  };
                });
              } else {
                setFindCommandMode((curr) => {
                  if (!curr.selectedWordIndexes) return curr;

                  const nextWord = findSearchedOrNextWordInLiveWordData({
                    blocks: liveWordData.finalsBlocks,
                    searchedText: '',
                    startIndexes: curr.selectedWordIndexes,
                    directionToSearch: command === Commands.RIGHT ? 'right' : 'left',
                    findAll: false,
                    jumpNumber: numOfWords > 1 ? numOfWords : undefined,
                  });
                  if (!nextWord) return curr;

                  return {
                    ...curr,
                    selectedWordIndexes: nextWord.wordIndexes,
                    selectedWordData: [{ ...nextWord.wordData }],
                    lostSearchContext: true,
                  };
                });
              }
            } else if (command === Commands.SELECT) {
              setFindCommandMode((curr) => {
                if (!curr.selectedWordIndexes) return curr;
                const selectedWords = liveWordData.finalsBlocks[curr.selectedWordIndexes[0]].words.slice(
                  curr.selectedWordIndexes[1],
                  curr.selectedWordIndexes[1] + numOfWords
                );

                return {
                  ...curr,
                  selectedRangeLength: numOfWords,
                  selectedWordData: selectedWords,
                  lostSearchContext: true,
                };
              });
            } else if (command === Commands.DELETE_WORD) {
              setLiveWordData((curr) => {
                if (!findCommandMode.selectedWordIndexes) return curr;
                const newBlocks = [...curr.finalsBlocks];
                newBlocks[findCommandMode.selectedWordIndexes[0]].words.splice(
                  findCommandMode.selectedWordIndexes[1],
                  findCommandMode.selectedRangeLength
                );

                return {
                  ...curr,
                  finalsBlocks: newBlocks,
                };
              });
              setFindCommandMode({
                findCommandModeOn: false,
                selectedWordIndexes: null,
                searchedText: null,
                selectedWordData: null,
                selectedRangeLength: 1,
              });
            }
          } else if (foundCommand === Commands.REPLACE) {
            isInSubModeOfFindMode.current = 'repl';
            setFindCommandMode((curr) => {
              return {
                ...curr,
                isReplaceMode: true,
              };
            });
          } else if (foundCommand === Commands.INSERT_AFTER || foundCommand === Commands.INSERT_BEFORE) {
            const isInsertBefore = foundCommand === Commands.INSERT_BEFORE;
            isInSubModeOfFindMode.current = isInsertBefore ? 'insb' : 'insa';

            if (findCommandMode.selectedRangeLength > 1) {
            }

            const isLongerRange = findCommandMode.selectedRangeLength > 1;
            const newWordIndexes: number[] | null =
              isLongerRange && !isInsertBefore
                ? [
                    findCommandMode.selectedWordIndexes[0],
                    findCommandMode.selectedWordIndexes[1] + (findCommandMode.selectedRangeLength - 1),
                  ]
                : null;

            setCursor({
              cursorType: CursorTypesEnum.NORMAL,
              cursorPosition: newWordIndexes
                ? newWordIndexes
                : [findCommandMode.selectedWordIndexes[0], findCommandMode.selectedWordIndexes[1]],
            });

            setFindCommandMode((curr) => {
              if (!curr.selectedWordIndexes) return curr;
              return {
                ...curr,
                selectedRangeLength: 1,
                lostSearchContext: true,
                selectedWordData: null,
                selectedWordIndexes: newWordIndexes ? newWordIndexes : curr.selectedWordIndexes,
                isInsertMode: isInsertBefore ? 'insb' : 'insa',
              };
            });

            return;
          } else if (foundCommand === Commands.FIX) {
            // Handle transitin to FIX mode when inside FIND mode

            isInSubModeOfFindMode.current = 'fix';

            let textToFetch = '';
            findCommandMode.selectedWordData?.forEach((w) => {
              textToFetch += w.text;
            });

            setFixSpellCommandMode({
              selectedWordIndexes:
                findCommandMode.selectedRangeLength > 1
                  ? [
                      findCommandMode.selectedWordIndexes[0],
                      findCommandMode.selectedWordIndexes[1] + findCommandMode.selectedRangeLength - 1,
                    ]
                  : findCommandMode.selectedWordIndexes,
              currentCommandMode: CurrentCommandModeEnums.FIX_MODE,
              currentSpelledWord: [],
              selectedWordOriginalText:
                findCommandMode.selectedRangeLength > 1
                  ? null
                  : liveWordData.finalsBlocks[findCommandMode.selectedWordIndexes[0]].words[
                      findCommandMode.selectedWordIndexes[1]
                    ].text,
              textToFetch,
              isSpellingOn: false,
              numberOfWordsInFixMode:
                findCommandMode.selectedRangeLength > 1 ? findCommandMode.selectedRangeLength : 1,
              prevWordsHistory:
                findCommandMode.selectedRangeLength > 1 ? findCommandMode.selectedWordData : undefined,
            });
            setFindCommandMode({
              findCommandModeOn: false,
              selectedWordIndexes: null,
              searchedText: null,
              selectedWordData: null,
              selectedRangeLength: 1,
              isInsertMode: null,
            });
          }
          return;
        }

        // if state already in FIX/SPELL MODE we handle received tr here
        if (
          fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.FIX_MODE ||
          fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.SPELL_MODE_ONLY
        ) {
          generateFooterTr(parsedContent, isFinal);

          // Check if received tr has needed word for applying sub inside and apply it
          if (
            transcriptMessage.isFinal &&
            (fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.SPELL_MODE_ONLY ||
              fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.FIX_MODE)
          ) {
            const selectedSub = checkIfSubOrCommandForFixModeIsInReceivedTr(
              parsedContent,
              commandModeSubs.subs,
              fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.SPELL_MODE_ONLY
                ? true
                : fixSpellCommandMode.isSpellingOn,
              fixSpellCommandMode.currentSpelledWord,
              ucSpelling.current,
              lcSpelling.current,
              ccSpelling.current,
              LCSpelling.current
            );

            if (selectedSub === null) return;
            // Sub is confirmed
            else if (selectedSub === true) {
              setFixSpellCommandMode({
                isSpellingOn: false,
                currentCommandMode: CurrentCommandModeEnums.INIT,
                currentSpelledWord: [],
                selectedWordOriginalText: null,
                selectedWordIndexes: null,
                textToFetch: '',
                numberOfWordsInFixMode: 1,
              });
              // }

              return;
            }
            // FixSpell mode is canceled
            else if (selectedSub === false) {
              let newB = cloneDeep(liveWordData.finalsBlocks);
              if (!fixSpellCommandMode.selectedWordIndexes) return;
              const [line, word] = fixSpellCommandMode.selectedWordIndexes;

              if (
                fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.FIX_MODE &&
                fixSpellCommandMode.selectedWordIndexes !== null
              ) {
                const newWord = newB[line].words[word];
                if (fixSpellCommandMode.prevWordsHistory) {
                  if (newWord?.wasMergedWithCorrCommand) {
                    const pwh = fixSpellCommandMode.prevWordsHistory;
                    newB[line].words.splice(word, 1, ...pwh);
                    setCursor((curr) => {
                      return {
                        ...curr,
                        cursorPosition: [line, word + pwh.length - 1],
                      };
                    });
                  }
                } else {
                  newWord.updatedText = undefined;
                  newWord.text = fixSpellCommandMode.selectedWordOriginalText
                    ? fixSpellCommandMode.selectedWordOriginalText
                    : '';
                }
              } else if (
                fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.SPELL_MODE_ONLY &&
                fixSpellCommandMode.hasAddedNewWordInSpellingMode
              ) {
                newB[newB.length - 1].words.pop();
              }
              setLiveWordData((curr) => {
                return {
                  lastInterim: curr.lastInterim,
                  finalsBlocks: newB,
                };
              });
              setFixSpellCommandMode({
                currentCommandMode: CurrentCommandModeEnums.INIT,
                selectedWordIndexes: null,
                currentSpelledWord: [],
                isSpellingOn: false,
                selectedWordOriginalText: null,
                textToFetch: '',
                numberOfWordsInFixMode: 1,
              });
              // }

              return;
            } else if (
              selectedSub === Commands.SPELL &&
              !fixSpellCommandMode.isSpellingOn &&
              fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.FIX_MODE
            ) {
              setFixSpellCommandMode((curr) => {
                return {
                  ...curr,
                  isSpellingOn: true,
                };
              });
              return;
            } else if (selectedSub === Commands.SHOW_LESS || selectedSub === Commands.SHOW_MORE) {
              setCommandModeMoveSubs(selectedSub === Commands.SHOW_LESS ? 'L' : 'R');
            }
            // Spellig changed
            else if (fixSpellCommandMode.isSpellingOn && typeof selectedSub === 'object') {
              // adddto currspelledword array

              setFixSpellCommandMode((curr) => {
                return {
                  ...curr,
                  currentSpelledWord: selectedSub.spellingArr,
                  wordDataSpellingOnlyMode: selectedSub.currentData ? selectedSub.currentData : undefined,
                };
              });

              ccSpelling.current = selectedSub.cc;
              ucSpelling.current = selectedSub.uc;
              lcSpelling.current = selectedSub.lc;
              LCSpelling.current = selectedSub.LC;
              return;
            }
            // selected sub changed
            else if (typeof selectedSub === 'string') {
              let changeSelecedWordIndexes: false | number[] = false;

              let newB = cloneDeep(liveWordData.finalsBlocks);
              // if (!fixSpellCommandMode.selectedWordIndexes) return;
              const selectedBlockI = fixSpellCommandMode.selectedWordIndexes
                ? fixSpellCommandMode.selectedWordIndexes[0]
                : newB.length - 1;
              const selectedBlock = newB[selectedBlockI];

              if (
                fixSpellCommandMode.numberOfWordsInFixMode > 1 &&
                fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.FIX_MODE
              ) {
                if (!fixSpellCommandMode.selectedWordIndexes) return;

                if (
                  !newB[selectedBlockI].words[fixSpellCommandMode.selectedWordIndexes[1]]
                    .wasMergedWithCorrCommand
                ) {
                  const updatedWord = {
                    ...liveWordData.finalsBlocks[selectedBlockI].words[
                      fixSpellCommandMode.selectedWordIndexes[1]
                    ],
                    text: selectedSub,
                    updatedText: undefined,
                    wasMergedWithCorrCommand: true,
                  };
                  newB[selectedBlockI].words.splice(
                    fixSpellCommandMode.selectedWordIndexes[1] -
                      fixSpellCommandMode.numberOfWordsInFixMode +
                      1,
                    fixSpellCommandMode.numberOfWordsInFixMode,
                    updatedWord
                  );
                  changeSelecedWordIndexes = [
                    selectedBlockI,
                    fixSpellCommandMode.selectedWordIndexes[1] -
                      (fixSpellCommandMode.numberOfWordsInFixMode - 1),
                  ];
                }

                const wI =
                  fixSpellCommandMode.selectedWordIndexes[1] - fixSpellCommandMode.numberOfWordsInFixMode;

                if (!!fixSpellCommandMode.selectedWordIndexes && !!fixSpellCommandMode.prevWordsHistory) {
                  // adjust cursor position, because corrected words come back as single word
                  // TODO: should fix '<corr> n' command?
                  const [line, word] = fixSpellCommandMode.selectedWordIndexes;
                  const prevWordLen = fixSpellCommandMode.prevWordsHistory.length;
                  setCursor((curr) => {
                    return {
                      ...curr,
                      cursorPosition: [line, word - prevWordLen + 1],
                    };
                  });
                }

                setFixSpellCommandMode((curr) => {
                  return {
                    ...curr,
                    selectedWordIndexes: changeSelecedWordIndexes
                      ? changeSelecedWordIndexes
                      : curr.selectedWordIndexes,
                    numberOfWordsInFixMode: 1,
                  };
                });
              } else if (
                fixSpellCommandMode.selectedWordIndexes !== null &&
                fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.FIX_MODE
              ) {
                newB[fixSpellCommandMode.selectedWordIndexes[0]].words[
                  fixSpellCommandMode.selectedWordIndexes[1]
                ].text = selectedSub;
                newB[fixSpellCommandMode.selectedWordIndexes[0]].words[
                  fixSpellCommandMode.selectedWordIndexes[1]
                ].updatedText = undefined;
              } else if (
                fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.SPELL_MODE_ONLY &&
                fixSpellCommandMode.wordDataSpellingOnlyMode
              ) {
                if (fixSpellCommandMode.hasAddedNewWordInSpellingMode) {
                  newB[selectedBlockI].words[selectedBlock.words.length - 1].text = selectedSub;
                } else {
                  newB[selectedBlockI].words.push({
                    ...fixSpellCommandMode.wordDataSpellingOnlyMode,
                    text: selectedSub,
                  });

                  setFixSpellCommandMode((curr) => {
                    return {
                      ...curr,
                      hasAddedNewWordInSpellingMode: true,
                    };
                  });
                }
              }

              setCommandModeSubs((curr) => {
                return {
                  ...curr,
                  selectedSubText: selectedSub,
                };
              });

              setLiveWordData((curr) => {
                return {
                  lastInterim: curr.lastInterim,
                  finalsBlocks: newB,
                };
              });
            }
          }

          return;
        }

        // if state not in special modes we handle thing normally
        let isInFixModeToSend = false;
        //@ts-ignore
        if (fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.FIX_MODE) {
          isInFixModeToSend = true;
        }

        const {
          newLiveWordData,
          goToFixMode,
          goToSpellMode,
          goToInsMode,
          goToFindMode,
          goToNextInput,
          goToPrevInput,
          goToInput,
          goToInputIndex,
          textToSearchInFindMode,
          capitalizeNextWord,
          capitalizeNextWordCommand: capNextCommand,
          upperCaseAll,
          lowerCaseAll,
          numberOfWordsInFixMode,
          newCursorPosition,
          isUnderlineOn,
          isBoldNext,
          isBoldOn,
          isItalicNext,
          isItalicOn,
          isUnderlineNext,
        } = convertReceivedTranscriptToWordDataLiveNew(
          transcriptMessage,
          liveWordData,
          {
            postProcessingLive: true, //transcriptionDoDictation
            capitalizeNextWord: capitalizeNextLetter.current || trCounter.current === 0,
            capitalizeNextWordCommandPrev: capitalizeNextWordCommand.current,
            lowerCaseAllNext: lowerCaseAllNext.current,
            isFromUpload: editorMode === EditorModeEnums.TRANSCRIBING_UPLOAD_MODE,
            upperCaseAllNext: upperCaseAllNext.current,
            fixModeOptions: {
              isInFixMode: isInFixModeToSend,
              fixModeAvailableSubs: ['ena'],
            },
            isNewRecordingSession: isNewRecordingSession.current,
            isInFindMode: isInFindMode.current,
            cursorPosition: cursor.cursorPosition,
            boldNext: boldNext.current,
            boldOn: boldOn.current,
            italicNext: italicNext.current,
            italicOn: italicOn.current,
            underlineNext: underlineNext.current,
            underlineOn: underlineOn.current,
          },
          changeCursor
        );

        if (newCursorPosition) {
          setCursor((curr) => {
            return {
              ...curr,
              cursorPosition: newCursorPosition,
            };
          });
        }

        if (isFinal) {
          isNewRecordingSession.current = false;
          hasEditorContentChanged.current = true;
        }

        if (goToFindMode && textToSearchInFindMode === '') {
          setCursor({
            cursorType: CursorTypesEnum.FIND,
            cursorPosition: null,
          });
        }

        // if we got FIND command in last received final we handle steps here
        if (
          goToFindMode &&
          newLiveWordData.finalsBlocks.length > 0 &&
          textToSearchInFindMode !== '' &&
          typeof textToSearchInFindMode === 'string'
        ) {
          const searchedWord = findSearchedOrNextWordInLiveWordData({
            blocks: newLiveWordData.finalsBlocks,
            searchedText: textToSearchInFindMode,
            findAll: false,
            startIndexes: newCursorPosition
              ? newCursorPosition
              : cursor.cursorPosition
              ? cursor.cursorPosition
              : null,
            directionToSearch: 'left',
          });

          if (textToSearchInFindMode === Commands.CANCEL || textToSearchInFindMode === Commands.CONFIRM) {
            setFindCommandMode({
              findCommandModeOn: false,
              selectedWordIndexes: null,
              selectedWordData: null,
              searchedText: '',
              selectedRangeLength: 1,
            });
            isInFindMode.current = false;

            setCursor({ cursorType: CursorTypesEnum.NORMAL, cursorPosition: null });
            return;
          }

          if (searchedWord === null) {
            enqueueSnackbar(`Iskane besede ni v besedilu.`, { variant: 'error' });
            return;
          }

          isInFindMode.current = false;
          const searchedWordIsObj = typeof searchedWord === 'object';
          setFindCommandMode({
            findCommandModeOn: true,
            selectedWordIndexes:
              searchedWordIsObj && searchedWord
                ? [searchedWord.wordIndexes[0], searchedWord.wordIndexes[1]]
                : null,
            selectedWordData: searchedWordIsObj && searchedWord ? [searchedWord.wordData] : null,
            searchedText: textToSearchInFindMode,
            selectedRangeLength: 1,
          });
        } else if (goToSpellMode) {
          // Handle gotospell mode solo

          setFixSpellCommandMode({
            selectedWordIndexes: null,
            currentCommandMode: CurrentCommandModeEnums.SPELL_MODE_ONLY,
            currentSpelledWord: [],
            selectedWordOriginalText: null,
            textToFetch: '',
            isSpellingOn: true,
            numberOfWordsInFixMode: 1,
            prevWordsHistory: null,
          });
        } else if (goToInsMode) {
          setInsCommandMode({ isOn: true });
        }
        // if we got FIX command in last received final we handle steps here
        else if (goToFixMode && newLiveWordData.finalsBlocks.length > 0) {
          const { finalsBlocks } = newLiveWordData;

          const [line, word] = newCursorPosition?.length
            ? newCursorPosition
            : [finalsBlocks.length - 1, finalsBlocks[finalsBlocks.length - 1].words.length - 1];

          if (finalsBlocks[line]) {
            const blockWords = finalsBlocks[line].words;
            // The word at the cursor might be punctuation (dot, colon, ...), so we want to
            // select word before that.
            let dictWord = word;
            while (dictWord >= 0 && !!allSubsStringsConverted.includes(blockWords[dictWord]?.text)) {
              dictWord = dictWord - 1;
            }

            if (blockWords[dictWord]) {
              let textToFetch = blockWords[dictWord].text;
              const ut = blockWords[dictWord].updatedText;
              if (typeof ut === 'string' && ut !== undefined) {
                textToFetch = ut;
              }

              // if fix command is of type <corr> n we need to combine text for sub fetching
              let lastNWords: IWordData[] = [];
              if (numberOfWordsInFixMode > 1) {
                lastNWords = blockWords
                  .slice(Math.max(dictWord + 1 - numberOfWordsInFixMode, 0), dictWord + 1)
                  .map((w) => ({ ...w }));

                textToFetch = lastNWords.map((w) => w.updatedText || w.text).join(' ');
              }

              setFixSpellCommandMode({
                selectedWordIndexes: [line, dictWord],
                currentCommandMode: CurrentCommandModeEnums.FIX_MODE,
                currentSpelledWord: [],
                selectedWordOriginalText: numberOfWordsInFixMode > 1 ? null : blockWords[dictWord].text,
                textToFetch,
                isSpellingOn: false,
                numberOfWordsInFixMode: numberOfWordsInFixMode,
                prevWordsHistory: lastNWords.length > 0 ? lastNWords : undefined,
              });
            }
          }
        } else if (goToNextInput) {
          const newInputPos = findNextInputPosition(liveWordData.finalsBlocks, cursor.cursorPosition);

          if (!!newInputPos) {
            setCursor((prev) => ({
              ...prev,
              cursorPosition: newInputPos,
            }));
            setLiveWordData((prev) => ({ ...prev, lastInterim: '' }));
          }
        } else if (goToPrevInput) {
          const newInputPos = findPreviousInputPosition(liveWordData.finalsBlocks, cursor.cursorPosition);

          if (!!newInputPos) {
            setCursor((prev) => ({
              ...prev,
              cursorPosition: newInputPos,
            }));
            setLiveWordData((prev) => ({ ...prev, lastInterim: '' }));
          }
        } else if (goToInput && goToInputIndex > 0) {
          const newInputPos = findInputByIndex(liveWordData.finalsBlocks, goToInputIndex);

          if (!!newInputPos) {
            setCursor((prev) => ({
              ...prev,
              cursorPosition: newInputPos,
            }));
            setLiveWordData((prev) => ({ ...prev, lastInterim: '' }));
          }
        } else if (!findCommandMode.findCommandModeOn) {
          setLiveWordData(newLiveWordData);
          upperCaseAllNext.current = upperCaseAll === undefined ? false : upperCaseAll;
          lowerCaseAllNext.current = lowerCaseAll === undefined ? false : lowerCaseAll;
          capitalizeNextWordCommand.current = capNextCommand === undefined ? false : capNextCommand;
          capitalizeNextLetter.current = capitalizeNextWord ? capitalizeNextWord : false;
          isInFindMode.current = goToFindMode;

          boldNext.current = isBoldNext || false;
          boldOn.current = isBoldOn || false;
          italicNext.current = isItalicNext || false;
          italicOn.current = isItalicOn || false;
          underlineNext.current = isUnderlineNext || false;
          underlineOn.current = isUnderlineOn || false;

          if (isFinal) {
            trCounter.current = trCounter.current + 1;
          }
        }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (
      (fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.INIT ||
        (fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.FIX_MODE &&
          fixSpellCommandMode.textToFetch === '')) &&
      !insCommandMode.isOn
    ) {
      setFooterTr({ finalStr: '', interimStr: '' });
      return;
    }

    if (fixSpellCommandMode.isSpellingOn && fixSpellCommandMode.currentSpelledWord.length === 0) {
      const spelledSub = [
        {
          id: '1',
          keys: fixedKeys['1'],
          subText: fixSpellCommandMode.currentSpelledWord.join(''),
        },
      ];

      setCommandModeSubs((c) => {
        return { ...c, subsState: 'available', subs: spelledSub };
      });

      return;
    }
    const fws = async () => {
      const modelData =
        language && model && model.name && domain
          ? {
              language: language,
              modelVersion: model ? model.name : '',
              domain: domain,
            }
          : null;
      if (!modelData && !insCommandMode.isOn) return;
      setCommandModeSubs((curr) => {
        return {
          ...curr,
          subs: curr.subs,
          subsState: 'loading',
        };
      });


      let response: any = null;
      if (insCommandMode.isOn) {
        try {
          response = await getEntries()
          
          const newInsSubs: ICommandSub[] = response.data.map((entry: IEntry, i) => {
            const correctI = fixSpellCommandMode.isSpellingOn ? i + 2 : i + 1;
            const keys = fixedKeys[correctI.toString()];
            return {
              id: correctI.toString(),
              keys: keys ?? [correctI.toString()],
              subText: entry.description,
              entry,
            };
          });
          setCommandModeSubs((c) => {
            return { ...c, subsState: 'available', subs: newInsSubs };
          });
          return;
        } catch (error) {
          const mappedError = error as any;
          
          enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, {
            variant: 'error',
            autoHideDuration: null,
          });
          setCommandModeSubs((curr) => {
            return {
              ...curr,
              subsState: 'error',
            };
          });
        }
      } else if (fixSpellCommandMode.isSpellingOn && modelData) {
        try {
          response = await getSpelledWordSubs(fixSpellCommandMode.currentSpelledWord.join(''), 4, modelData)
        } catch (error) {
          setCommandModeSubs((c) => {
            let newSubs = c.subs && c.subs[0] ? [...c.subs] : [];
            newSubs[0] = {
              id: '1',
              keys: fixedKeys['1'],
              subText: fixSpellCommandMode.currentSpelledWord.join(''),
            };

            return { ...c, subsState: 'available', subs: newSubs };
          });
        }
      } else if (modelData) {
        try {
          response = await getWordSubs(false, fixSpellCommandMode.textToFetch, modelData)
        } catch (error) {
          enqueueSnackbar('Pri iskanju substitutov je prišlo do napake. Poskusite črkovati', {
            variant: 'error',
          });
          setCommandModeSubs((curr) => {
            return {
              ...curr,
              subsState: 'error',
            };
          });
        }
      }

      if (!response) return;

      const content = response.data.content as ISubsContent;

      let newSubs: ICommandSub[] | null = content
        ? Object.keys(content).map((key, i) => {
          const correctI = fixSpellCommandMode.isSpellingOn ? i + 2 : i + 1;

          return {
            id: correctI.toString(),
            keys: fixedKeys[correctI.toString()],
            subText: content[key],
          };
        })
        : null;

      if (fixSpellCommandMode.isSpellingOn && newSubs) {
        newSubs.unshift({
          id: '1',
          keys: fixedKeys['1'],
          subText: fixSpellCommandMode.currentSpelledWord.join(''),
        });
      } else if (fixSpellCommandMode.isSpellingOn && !newSubs) {
        newSubs = [
          {
            id: '1',
            keys: fixedKeys['1'],
            subText: fixSpellCommandMode.currentSpelledWord.join(''),
          },
        ];
      }

      if (newSubs && newSubs.length > 10) {
        newSubs = newSubs.slice(0, 10);
      }

      setCommandModeSubs((c) => {
        return { ...c, subsState: 'available', subs: newSubs };
      });
    };

    fws();
  }, [
    fixSpellCommandMode.currentCommandMode,
    fixSpellCommandMode.currentSpelledWord,
    fixSpellCommandMode.isSpellingOn,
    insCommandMode.isOn,
  ]);

  useEffect(() => {
    // When a TAB key is pressed, we want to move to next {input},
    // and to previous for SHIFT + TAB.
    const handleKeyUp = (event: KeyboardEvent): any => {
      if (!liveWordDataContainsInputs.current) {
        return;
      }

      const { key, shiftKey } = event;

      if (key === 'Tab') {
        event.preventDefault();

        const newInputPos = shiftKey
          ? findPreviousInputPosition(liveWordData.finalsBlocks, cursor.cursorPosition)
          : findNextInputPosition(liveWordData.finalsBlocks, cursor.cursorPosition);

        if (!!newInputPos) {
          setCursor((prev) => ({
            ...prev,
            cursorPosition: newInputPos,
          }));
        }
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [liveWordDataContainsInputs, liveWordData, cursor.cursorPosition, setCursor]);

  const handleLiveWordClick = useCallback(
    (pos) => {
      setCursor((prev) => {
        return {
          ...prev,
          cursorPosition: pos,
        };
      });
    },
    [setCursor]
  );

  const findInputIndex = useCallback(
    (lineIndex: number, wordIndex: number) =>
      findInputIndexByPosition(liveWordData.finalsBlocks, lineIndex, wordIndex),
    [liveWordData.finalsBlocks]
  );

  const inputIndexForCursor = !!cursor.cursorPosition
    ? findInputIndex(cursor.cursorPosition[0], Math.max(0, cursor.cursorPosition[1]))
    : 0;
  const isCursorInInput = inputIndexForCursor > 0;
  const cursorType = isCursorInInput ? CursorTypesEnum.LOWER_CASE_ALL : cursor.cursorType;

  const interimStyle = genInlineStylesForInterim(
    boldOn.current,
    italicOn.current,
    underlineOn.current,
    isCursorInInput
  );

  return (
    <div
      className={clsx(
        'live_text_main_wrapper',
        editorMode === EditorModeEnums.TRANSCRIBING_UPLOAD_MODE && 'mouse_off'
      )}
    >
      {liveWordData.finalsBlocks.length === 0 ? (
        <span className="live_text_p_block draft_word" style={interimStyle}>
          {liveWordData.lastInterim && <LastPostitionCursor type={cursorType} isInInput={isCursorInInput} />} 
        </span>
      ) : (
        <>
          {liveWordData.finalsBlocks.map((final, k, arr) => (
            <div className="SpeakerBlockWrapper_wrapper">
              {final.speaker && !(arr[k - 1] && arr[k - 1].speaker?.id === final.speaker.id) && (
                <SpeakerSection speaker={final.speaker} isFirst={k === 0} />
              )}
              <div
                className="live_text_p_block draft_word"
                key={`live_text_block_${k}`}
                style={{
                  cursor:
                    !final.words.length || (final.words.length === 1 && final.words[0].text === '')
                      ? 'text'
                      : 'auto',
                }}
                onClick={() => {
                  setCursor((prev) => {
                    return {
                      ...prev,
                      cursorPosition: [
                        k,
                        !final.words.length || (final.words.length === 1 && final.words[0].text === '')
                          ? -1
                          : final.words.length - 1,
                      ],
                    };
                  });
                }}
              >
                {cursor.cursorPosition && cursor.cursorPosition[0] === k && cursor.cursorPosition[1] === -1 && (
                  <>
                    <span
                      key={`lastInterim_first${k}`}
                      style={{
                        marginLeft: isCursorInInput ? 'unset' : '-15px',
                        ...interimStyle,
                      }}
                    >
                      {typeof liveWordData.lastInterim === 'string' ? liveWordData.lastInterim : liveWordData.lastInterim.map(interim => interim.text).join(' ')}
                    </span>
                    <LastPostitionCursor type={cursorType} isInInput={isCursorInInput} />
                  </>
                )}

                {final.words.map((w, i, arr) => (
                  <>
                    {cursor.cursorPosition &&
                      cursor.cursorPosition[0] === k &&
                      cursor.cursorPosition[1] === i &&
                      findCommandMode.isInsertMode === 'insb' && (
                        <>
                          <span key={`lastInterim_first${k}`} style={interimStyle}>
                            {typeof liveWordData.lastInterim === 'string' ? liveWordData.lastInterim : liveWordData.lastInterim.map(interim => interim.text).join(' ')}
                          </span>
                          <LastPostitionCursor
                            type={cursorType}
                            key={`cursor_position${k}${i}`}
                            isInInput={isCursorInInput}
                          />
                        </>
                      )}
                    {(!(
                      findCommandMode.selectedWordIndexes &&
                      findCommandMode.selectedWordIndexes[0] === k &&
                      i >= findCommandMode.selectedWordIndexes[1] &&
                      i <= findCommandMode.selectedWordIndexes[1] + findCommandMode.selectedRangeLength - 1
                    ) ||
                      findCommandMode.selectedRangeLength <= 1) && (
                      <LiveWord
                        word={w}
                        previousWord={getPreviousNonEmptyWord(liveWordData.finalsBlocks, k, i - 1)}
                        nextWord={getNextNonEmptyWord(liveWordData.finalsBlocks, k, i + 1)}
                        key={`live_word_initial${k}${i}`}
                        id={`live_word_${k}${i}`}
                        isSelectedForFixMode={
                          !fixSpellCommandMode.selectedWordIndexes
                            ? false
                            : checkIfWordIsSelected(
                                fixSpellCommandMode.selectedWordIndexes,
                                k,
                                i,
                                fixSpellCommandMode.numberOfWordsInFixMode
                              )
                        }
                        isSelectedForFindMode={
                          findCommandMode.selectedWordIndexes &&
                          findCommandMode.selectedWordIndexes[0] === k &&
                          i >= findCommandMode.selectedWordIndexes[1] &&
                          i <=
                            findCommandMode.selectedWordIndexes[1] +
                              findCommandMode.selectedRangeLength -
                              1 &&
                          !findCommandMode.isInsertMode
                        }
                        i={i}
                        k={k}
                        onClickCallback={handleLiveWordClick}
                        findInputIndex={findInputIndex}
                        inputIndexForCursor={inputIndexForCursor}
                      />
                    )}
                    {findCommandMode.selectedWordIndexes &&
                      findCommandMode.selectedWordIndexes[0] === k &&
                      findCommandMode.selectedWordIndexes[1] === i &&
                      findCommandMode.selectedRangeLength > 1 && (
                        <>
                          {findCommandMode.selectedWordData &&
                          findCommandMode.selectedWordData[0] &&
                          findCommandMode.selectedWordData[0].spaceBefore
                            ? ' '
                            : ''}
                          <span
                            className="draft_word"
                            style={{
                              animation: 'heartbeat-border-red 800ms infinite ease-in',
                              borderBottom: '2px solid',
                              ...(findCommandMode.isReplaceMode && { backgroundColor: '#cccccc' }),
                            }}
                          >
                            {findCommandMode.selectedWordData &&
                              findCommandMode.selectedWordData.map((w, m) => (
                                <LiveWord
                                  word={{ ...w, spaceBefore: m === 0 ? false : w.spaceBefore }}
                                  previousWord={getPreviousNonEmptyWord(
                                    liveWordData.finalsBlocks,
                                    k,
                                    i - m - 1
                                  )}
                                  nextWord={getNextNonEmptyWord(liveWordData.finalsBlocks, k, i + m + 1)}
                                  key={`live_word_${k}${i + m}`}
                                  id={`live_word_${k}${i + m}`}
                                  isSelectedForFixMode={false}
                                  isSelectedForFindMode={false}
                                  i={i + m}
                                  k={k}
                                  onClickCallback={handleLiveWordClick}
                                  findInputIndex={findInputIndex}
                                  inputIndexForCursor={inputIndexForCursor}
                                />
                              ))}
                          </span>
                        </>
                      )}
                    {cursor.cursorPosition &&
                      cursor.cursorPosition[0] === k &&
                      cursor.cursorPosition[1] === i &&
                      findCommandMode.isInsertMode !== 'insb' && (
                        <>
                          <span key={`lastInterim_${k}`} style={interimStyle}>
                          {typeof liveWordData.lastInterim === 'string' ? liveWordData.lastInterim : liveWordData.lastInterim.map(interim => interim.text).join(' ')}
                          </span>
                          <LastPostitionCursor
                            type={cursorType}
                            key={`cursor_${k}${i}`}
                            isInInput={isCursorInInput}
                          />
                        </>
                      )}
                  </>
                ))}

                {k === arr.length - 1 && (
                  <>
                    {!cursor.cursorPosition && (
                      <span key={`lastInterim_last${k}`} style={interimStyle}>
                        {typeof liveWordData.lastInterim === 'string' ? liveWordData.lastInterim : liveWordData.lastInterim.map(interim => interim.text).join(' ')}
                      </span>
                    )}
                    {!cursor.cursorPosition &&
                      fixSpellCommandMode.currentCommandMode !== CurrentCommandModeEnums.FIX_MODE &&
                      !findCommandMode.findCommandModeOn && (
                        <LastPostitionCursor
                          spaceLeft={
                            fixSpellCommandMode.currentCommandMode ===
                              CurrentCommandModeEnums.SPELL_MODE_ONLY &&
                            fixSpellCommandMode.currentSpelledWord.length > 0 &&
                            fixSpellCommandMode.hasAddedNewWordInSpellingMode
                              ? false
                              : !(
                                  (liveWordData.finalsBlocks.length === 0 ||
                                    liveWordData.finalsBlocks[liveWordData.finalsBlocks.length - 1].words
                                      .length === 0) &&
                                  liveWordData.lastInterim.length === 0
                                )
                          }
                          type={cursorType}
                          key={`cursor_last${k}`}
                          isInInput={isCursorInInput}
                        />
                      )}
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      )}

      <span className="bottom_scroller" ref={bottomScrollerRef} />
    </div>
  );
}

export default React.memo(LiveEditor);
