import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

interface IProps {
  open: boolean;
  handleClose: any;
  deleteSessionCallback: any;
  hardDelete: boolean;
}

const DeletionConfirmation = ({ open, handleClose, deleteSessionCallback, hardDelete }: IProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Potrditev brisanja'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {hardDelete && "Seje je že izbrisana. Zdaj jo boste izbrisali dokončno. Ali ste prepričani?"}
          {!hardDelete && "Ste prepričani da želite izbrisati ta projekt?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Ne
        </Button>
        <Button onClick={() => deleteSessionCallback()} color="primary" autoFocus>
          Izbriši
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletionConfirmation;
