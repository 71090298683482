import { useEffect, useState } from 'react';
import { IStore, UserRoleEnums } from '../redux/store/IStore';
import { useAppSelector } from '../redux/store';

export default function useIsRole(role: UserRoleEnums) {
  const roles = useAppSelector(state => state.app.user?.userRoles);
  const [isRole, setIsRole] = useState<boolean>(false);

  useEffect(() => {
    if (!roles) return;

    setIsRole(roles.includes(role));
  }, [roles, role]);

  return [isRole];
}
