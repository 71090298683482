import React from 'react';
import { ClearIcon } from '../Icons/ClearIcon';
import './modal.css'

interface IModalProps {
    visible: boolean;
    closeModal: () => void;
    children: JSX.Element;
    title: string;
}
const Modal = ({
    title,
    visible,
    children,
    closeModal,
}: IModalProps) => {

    const preventOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <>
            {visible && (
                <div onClick={closeModal} className='modal-wrapper'>
                    <div onClick={preventOnClick} className='modal-container'>
                        <div className='modal-header'>
                            <p className='title'>{title}</p>
                            <button className='header-button' type="button" onClick={closeModal}>
                                <ClearIcon />
                            </button>
                        </div>
                        {children}
                    </div>
                </div>
            )}
        </>
    )
}

export default Modal;