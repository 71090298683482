import React, { useCallback, useMemo } from 'react'
import { IAppliedFilter, SessionState } from '../DashboardHeader/ISearch';
import moment from 'moment';
import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height';
import { ResizableBox } from 'react-resizable';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './tablev3.css'
import '../Home/classess.css'
import { IChipData, ISession, ISessionEntryWithShared, IShare } from './IDashboard';
import { addNewLabelToSession, cancelSessionInProgress, copySession, deleteSession, getAllDataAboutSessionAdmin, getClientLabels, getEditorContentV30, getNewSessionLock, getSession, getSessionLabels, getSessionRecordings, getSessionSummary, getSessionTranscripts, getSessionsWithShares, getSessionsWithSharesForIds, patchEditorContentV30, patchSessionInfo, recreateSession, registerNewClientLabel, removeLabelFromSession, unlockSession, updateSessionLabel } from '../../api/SessionsService';
import { useDebounce } from '../../hooks/useDebounce';
import { useSnackbar } from 'notistack';
import ShareModal from './ShareModal';
import { IRecordingsArray, Task } from '../Home/ISettings';
import { EditorModeEnums, UserRoleEnums } from '../../redux/store/IStore';
import { DeleteLabelIcon } from '../Icons/DeleteIcon';
import saveAs from 'file-saver';
import { transformNotSavedSessionTranscriptsToLiveWordData } from '../../shared/DataConverters';
import { API, CLIENT, SESSIONS } from '../../api/endpoints';
import { urlBackend } from '../../providers/V3WebSocketProvider';
import { useNavigate } from 'react-router-dom';
import { AccessMode } from '../../types';
import { ArrowDropDownIcon } from '../Icons/ArrowDropDownIcon';
import { convertFromVersion3ToVersion2File } from '../../api/dataConverters';
import useV3Ws from '../../hooks/useV3Ws';
import { convertToRaw } from 'draft-js';
import DeletionConfirmation from './DeletionConfirmation';
import usePipeline from '../../hooks/usePipeline';
import { CancelIcon } from '../Icons/CancelIcon';
import { RestartIcon } from '../Icons/RestartIcon';
import { IV3RecievedTranscript } from '../Libraries/ILibraries';
import { paragraphTokens } from '../../shared/paragrapher';
import axios, { CancelTokenSource } from 'axios';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Zoom from '@mui/material/Zoom';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setAudioInfo, setDiarization, setEditorLock, setEditorMode, setIsSessionDiscarded, setSessionName, setSessionsLabels, setTbFormat, setValidRedirect } from '../../redux/features/app/app';
import NewTable from './NewTable';

const tempColours = ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'];

const parseSloDate = (dateStr: string): Date | null => {
    const arr = dateStr.split('.');
    if (arr.length !== 3) return null;
    const numArr = arr.map((v) => Number(v));
    let date = new Date(numArr[2], numArr[1] - 1, numArr[0])
    date = new Date(date.getTime() + (-1 * date.getTimezoneOffset() * 60000))
    return date;
};

const useOnScreen = (id: number) => {

    const [isIntersecting, setIntersecting] = useState(false)

    const observerRef = useMemo(() => {
        return new IntersectionObserver(
            ([entry]) => {
                console.log(`Element is ${entry.isIntersecting ? '' : 'not'} intersecting`);
                setIntersecting(entry.isIntersecting);
            },
            { threshold: 0.5 } // Adjust threshold as needed
        );
    }, []);

    useEffect(() => {
        // Add observer to the target element
        const targetElement = document.getElementById(id.toString());
        if (targetElement) {
            observerRef.observe(targetElement);
        }

        // Cleanup function to remove observer when component unmounts or re-renders
        return () => {
            if (targetElement) {
                observerRef.unobserve(targetElement);
            }
        };
    }, [observerRef]);

    return isIntersecting
}

const transformFilters = (appliedFilters: IAppliedFilter[]): URLSearchParams => {
    const urlParams: URLSearchParams = new URLSearchParams()

    appliedFilters.forEach((filter: IAppliedFilter) => {
        switch (filter[0].field) {
            case 'createdAt':
                if (filter.length === 3) {//user passed specific day or open interval at one side
                    let date = parseSloDate(filter[2].label);
                    if (!date) throw new Error(`Invalid date filter: value ${filter[2].label}`)

                    if (filter[1].field === "to") {
                        date = new Date(date.getTime() + 60 * 60 * 24 * 1000);
                    }
                    const dateStr = date.toISOString();

                    switch (filter[1].field) {
                        case 'to':
                            urlParams.append('created-at', `;${dateStr}`)
                            break;
                        case 'from':
                            urlParams.append('created-at', `${dateStr};`)
                            break;
                        case 'is':
                            const dateEnd = new Date(date.getTime() + 60 * 60 * 24 * 1000).toISOString();
                            urlParams.append('created-at', `${dateStr};${dateEnd}`)
                            break;
                        default: throw new Error(`Unknown date filter operation field: ${filter[1].field}`)
                    }
                } else if (filter.length === 5) {//user passed closed interval on both sides
                    let dateOne = parseSloDate(filter[2].label)
                    let dateTwo = parseSloDate(filter[4].label)

                    if (!dateOne) throw new Error(`Invalid date filter: value ${filter[2].label}`)
                    if (!dateTwo) throw new Error(`Invalid date filter: value ${filter[4].label}`)

                    if (filter[1].field === "from") {
                        dateTwo = new Date(dateTwo.getTime() + 60 * 60 * 24 * 1000);
                    } else {
                        dateOne = new Date(dateOne.getTime() + 60 * 60 * 24 * 1000);
                    }
                    const startDate = filter[1].field === "from" ? dateOne.toISOString() : dateTwo.toISOString()
                    const endDate = filter[1].field === "to" ? dateOne.toISOString() : dateTwo.toISOString()

                    urlParams.append('created-at', `${startDate};${endDate}`)
                } else throw new Error('Date filters should be of length 3 or 5.')
                break;
            case 'labels':
                let labelFilterValue = filter[2].label
                if (filter[1].field === "contains") labelFilterValue = `%${labelFilterValue}%`
                else if (filter[1].field === "startsWith") labelFilterValue = `${labelFilterValue}%`
                urlParams.append('label', labelFilterValue)
                break;

            case 'name':
                let namelFilterValue = filter[2].label
                if (filter[1].field === "contains") namelFilterValue = `%${namelFilterValue}%`
                else if (filter[1].field === "startsWith") namelFilterValue = `${namelFilterValue}%`
                urlParams.append('name', namelFilterValue)
                break;

            case 'search':
                urlParams.append('name', `${filter[1].label}`)
                break;

            case 'user':
                // admin only
                let userFilterValue = filter[2].label
                if (filter[1].field === "contains") userFilterValue = `%${userFilterValue}%`
                else if (filter[1].field === "startsWith") userFilterValue = `${userFilterValue}%`
                urlParams.append('created-by-username', userFilterValue)
                break;
            case 'group':
                let groupFilterValue = filter[2].label
                if (filter[1].field === "contains") groupFilterValue = `%${groupFilterValue}%`
                else if (filter[1].field === "startsWith") userFilterValue = `${groupFilterValue}%`
                urlParams.append('created-by-group', groupFilterValue)
                break;
            case 'sessionSource':
                // admin only
                let sessionSourceFilterValue = filter[2].label
                if (filter[1].field === "contains") sessionSourceFilterValue = `%${sessionSourceFilterValue}%`
                else if (filter[1].field === "startsWith") sessionSourceFilterValue = `${sessionSourceFilterValue}%`
                urlParams.append('source', sessionSourceFilterValue)
                break;
            case 'sessionStatus':
                // admin only
                let sessionStatusFilterValue = filter[2].label
                let sessionStatusFilterField = filter[1].field

                if (sessionStatusFilterValue === SessionState.DELETED) {
                    if (sessionStatusFilterField === "isNot") {
                        urlParams.append('is-discarded', 'false')
                    } else if (sessionStatusFilterField === "is") {
                        urlParams.append('is-discarded', 'true')
                    } else {
                        throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
                    }
                } else {
                    if (sessionStatusFilterField === "isNot") {
                        Object.keys(SessionState).forEach(sessionState => {
                            if (sessionState !== sessionStatusFilterValue && sessionState !== SessionState.DELETED) {
                                urlParams.append('status', sessionState)
                            }
                        })
                    } else if (sessionStatusFilterField === "is") {
                        urlParams.append('status', sessionStatusFilterValue)
                    } else {
                        throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
                    }
                }
                break;
            default:
                break;
        }
    });
    return urlParams;
};

interface IRowValues {
    id: number,
    name: string,
    age: number,
    country: string
}

interface ITableHeader {
    key: TableHeaders,
    label: string,
    width: number,
}
enum TableHeaders {
    status = "status",
    name = "name",
    visibility = "visibility", //no
    labels = "labels", //no
    recordedMs = "recordedMs",
    createdAt = "createdAt",
    updatedAt = "updatedAt",
    createdByUsername = "createdByUsername", //no
    sources = "sources", //no
}

interface TransformedSessionState {
    text: string,
    class: string,
    containerClass: string
}
const transformStatusEnums = (value: SessionState, isDiscarded: boolean): TransformedSessionState => {
    if (isDiscarded) return { text: "IZBRISANO", class: "statusRowParagraphDeleted", containerClass: 'statusRowBasic' }
    if (value === SessionState.ERROR) return { text: "NAPAKA", class: "statusRowParagraphError", containerClass: 'statusRowContainerError' }
    if (value === SessionState.IN_PROGRESS) return { text: "V TRANSKRIBIRANJU", class: "statusRowParagraphTranscribing", containerClass: 'statusRowBasic' }
    if (value === SessionState.FINISHED) return { text: "UREDI", class: "statusRowParagraphEdit", containerClass: 'statusRowContainerEdit' }
    if (value === SessionState.DELETED) return { text: "IZBRISANO", class: "statusRowParagraphDeleted", containerClass: 'statusRowBasic' }
    if (value === SessionState.IN_QUEUE) return { text: "V VRSTI", class: "statusRowParagraphInQueue", containerClass: 'statusRowBasic' }
    /*if (value === SessionState.UPLOADING) return { text: "V NALAGANJU", class: "statusRowParagraphUploading", containerClass: 'statusRowBasic' }
    if (value === SessionState.EDITING) return { text: "V UREJANJU", class: "statusRowParagraphEditing", containerClass: 'statusRowBasic' }*/
    if (value === SessionState.INITIALIZING) return { text: "INICIALIZACIJA", class: "statusRowParagraphEditing", containerClass: 'statusRowBasic' }
    /*if (value === SessionState.INITIALIZED) return { text: "INICIALIZIRANO", class: "statusRowParagraphEditing", containerClass: 'statusRowBasic' }
    if (value === SessionState.FINISHING) return { text: "ZAKLJUČEVANJE", class: "statusRowParagraphEditing", containerClass: 'statusRowBasic' }*/
    if (value === SessionState.CANCELED) return { text: "PREKINJENO", class: "statusRowParagraphEditing", containerClass: 'statusRowBasic' }
    throw new Error(`Unknown session state: ${value}`)
}

const translateHeader = (key: TableHeaders) => {
    switch (key) {
        case TableHeaders.status: return "Stanje seje"
        case TableHeaders.name: return "Ime seje"
        case TableHeaders.createdByUsername: return "Uporabnik"
        case TableHeaders.labels: return "Labele"
        case TableHeaders.sources: return "Viri"
        case TableHeaders.recordedMs: return "Dolžina seje"
        case TableHeaders.createdAt: return "Datum kreiranja"
        case TableHeaders.updatedAt: return "Zadnja sprememba"
        case TableHeaders.visibility: return "Vidnost"
    }
}

const transformHeaderValue = (key: TableHeaders, value: IShare[] | IChipData[] | string[] | string | number) => {
    switch (key) {
        case TableHeaders.sources:
            return (value as string[]).join(", ")
        case TableHeaders.status:
        case TableHeaders.name:
        case TableHeaders.createdByUsername:
            return value as string
        case TableHeaders.labels:
            let labels = '';

            (value as IChipData[]).forEach((_, index) => {
                if (index !== 0) {
                    labels += " "    
                }
                labels += "■"
            })

            return labels;
        case TableHeaders.recordedMs:
            const duration = moment.duration(value as number);

            const hours = Math.floor(duration.asHours());
            const minutes = Math.floor(duration.minutes());
            const seconds = Math.floor(duration.seconds());
            const remainingMilliseconds = Math.floor(duration.milliseconds());

            return `${hours > 0 ? hours + ':' : ''}${minutes > 0 ? minutes + ':' : ''}${seconds}.${remainingMilliseconds}`
        case TableHeaders.createdAt:
        case TableHeaders.updatedAt:
            return moment(value as string).format('DD. MM. YYYY HH:mm:ss')
        case TableHeaders.visibility:
            if ((value as IShare[]).length === 0) return ""
            else if ((value as IShare[]).length === 1) return "1 oseba"
            else if ((value as IShare[]).length === 2) return "2 osebe"
            else if ((value as IShare[]).length === 3) return "3 osebe"
            else if ((value as IShare[]).length === 4) return "4 osebe"
            return `${(value as IShare[]).length} osebe`
    }
}

interface ITableHeader {
    key: TableHeaders,
    label: string,
    width: number,
    sortDirection: SortDirection | null,
}

enum SortDirection {
    asc = 'asc',
    desc = 'desc',
}

interface ITableRow {
    rows: ISessionEntryWithShared[],
    values: ISessionEntryWithShared,
    tableHeaders: ITableHeader[],
    isSessionOpening: boolean;
    handleResize: (index: number, width: number) => void;
    updateNotes: (id: number, updatedNotes: string) => void;
    deleteRow: (id: number) => void;
    addLabel: (id: number, label: IChipData) => void;
    removeLabel: (sessionId: number, labelId: number) => void;
    toggleLabel: (sessionId: number, labelId: number, isEnabled: boolean) => void;
    setLoading: (newValue: boolean) => void;
    restartDashboardData: () => Promise<void>;
    setSessionIsOpening: () => void;
    cancelSessionOpening: () => void;
}
const Row = ({
    values,
    rows,
    tableHeaders,
    handleResize,
    updateNotes,
    deleteRow,
    addLabel,
    removeLabel,
    toggleLabel,
    setLoading,
    restartDashboardData,
    isSessionOpening,
    setSessionIsOpening,
    cancelSessionOpening,
}: ITableRow) => {
    
    const navigate = useNavigate();
    const availableLabels = useAppSelector(state => state.app.sessionsLabels);
    const dispatch = useAppDispatch();
    const [triggerExpanding, setTriggerExpanding] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<boolean>(false)
    const rowRef = useRef<HTMLTableRowElement | null>(null);
    const targetElementRef = useRef<HTMLElement | null>(null);
    const [shareModal, setShareModal] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>(values.content.notes);
    const [summary, setSummary] = useState<string>('')
    const [recordings, setRecordings] = useState<IRecordingsArray>([])
    const debouncedNotes = useDebounce(notes, 500, []);
    const [newLabel, setNewLabel] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();
    const [anchorElAddLabel, setAnchorElAddLabel] = useState<null | HTMLElement>(null);
    const [anchorElOpenVersions, setAnchorElOpenVersions] = useState<null | HTMLElement>(null);
    const user = useAppSelector(state => state.app.user);
    const editorLock = useAppSelector(state => state.app.editorLock);
    const {
        updateLocalEditorLock
    } = useV3Ws();

    const {
        buildPipeline
    } = usePipeline();
    const isHardDeleter = user && user.userRoles.includes(UserRoleEnums.SESSION_HARD_DELETE) || false

    const [deletionConfirmationModal, setDeletionConfirmationModal] = useState<boolean>(false);
    const toggleDeletion = () => {
        console.log(isHardDeleter)
        console.log(values.content)
        setDeletionConfirmationModal(!deletionConfirmationModal)
    }

    const openSession = async (session: ISession, versionName?: string) => {
        if (!user || !user.accessToken || isSessionOpening || session.status !== SessionState.FINISHED) {
            return;
        }

        const {
            id,
            versionTags,
            allRecordingsDiscarded,
            name,
            isLocked
        } = session

        setSessionIsOpening();
        if (typeof name === 'string') {
            dispatch(setSessionName(name));
        }
        dispatch(setIsSessionDiscarded(allRecordingsDiscarded));

        try {
            if (versionTags.length > 0) {
                const latestVersion = versionName ? versionName : versionTags.slice().sort((a, b) => b.localeCompare(a))[0];

                if (isLocked) {
                    const r = await getEditorContentV30(id, null, AccessMode.READ_ONLY, latestVersion);
                    const newLock = {
                        ...editorLock,
                        sessionLockKey: null,
                        sessionId: id,
                        refreshAfter: null,
                        editTicket: null,
                        versionName: latestVersion,
                    }
                    dispatch(setEditorLock(newLock))
                    updateLocalEditorLock(newLock)

                    const { rawContentState } = JSON.parse(r.data.content);

                    if (rawContentState) {
                        dispatch(
                            setTbFormat({
                                rawContentState: rawContentState,
                                sessionId: id,
                            })
                        );
                    } else {
                        dispatch(
                            setTbFormat({
                                editorStateStringified: r.data.content as string,
                                sessionId: id,
                            })
                        );
                    }
                } else {
                    try {
                        const response = await getNewSessionLock(id, null);
                        const r = await getEditorContentV30(id, response.data.key, AccessMode.READ_WRITE, latestVersion);
                        const newLock = {
                            ...editorLock,
                            sessionLockKey: response.data.key,
                            sessionId: id,
                            refreshAfter: response.data.expiresAt ? moment(response.data.expiresAt).diff(moment.utc(), 'seconds') - 5 : 55,
                            editTicket: r.data.editTicket,
                            versionName: latestVersion,
                        }
                        dispatch(setEditorLock(newLock))
                        updateLocalEditorLock(newLock)
                        //const r = await getEditorContent(id);
                        const { rawContentState } = JSON.parse(r.data.content);

                        if (rawContentState) {
                            dispatch(
                                setTbFormat({
                                    rawContentState: rawContentState,
                                    sessionId: id,
                                })
                            );
                        } else {
                            dispatch(
                                setTbFormat({
                                    editorStateStringified: r.data.content as string,
                                    sessionId: id,
                                })
                            );
                        }
                    } catch (error) {
                        console.log(error);
                        if ((error as any).response.status === 423) {
                            const r = await getEditorContentV30(id, null, AccessMode.READ_ONLY, latestVersion);
                            const newLock = {
                                ...editorLock,
                                sessionLockKey: null,
                                sessionId: id,
                                refreshAfter: null,
                                editTicket: null,
                                versionName: latestVersion,
                            }
                            dispatch(setEditorLock(newLock))
                            updateLocalEditorLock(newLock)

                            const { rawContentState } = JSON.parse(r.data.content);

                            if (rawContentState) {
                                dispatch(
                                    setTbFormat({
                                        rawContentState: rawContentState,
                                        sessionId: id,
                                    })
                                );
                            } else {
                                dispatch(
                                    setTbFormat({
                                        editorStateStringified: r.data.content as string,
                                        sessionId: id,
                                    })
                                );
                            }
                        }
                    }
                }
            } else {
                const response = await getNewSessionLock(id, null);
                const r = await getEditorContentV30(id, response.data.key, AccessMode.READ_WRITE, null);

                const res = await getSessionTranscripts(id);
                const mappedTranscripts = res.data as IV3RecievedTranscript[]

                const paragraphedTranscript = paragraphTokens(mappedTranscripts);
                const converted = convertFromVersion3ToVersion2File([paragraphedTranscript.newTranscripts]);
                const { newEditorState } = await transformNotSavedSessionTranscriptsToLiveWordData(converted);

                await patchEditorContentV30(
                    JSON.stringify({ rawContentState: convertToRaw(newEditorState.getCurrentContent()) }),
                    id,
                    r.data.editTicket,
                    response.data.key,
                );

                const sesisonData = await getSession(id)
                const latestVersion = sesisonData.data.versionTags.slice().sort((a, b) => b.localeCompare(a))[0];
                const newLock = {
                    ...editorLock,
                    sessionLockKey: response.data.key,
                    sessionId: id,
                    refreshAfter: response.data.expiresAt ? moment(response.data.expiresAt).diff(moment.utc(), 'seconds') - 5 : 55,
                    editTicket: r.data.editTicket,
                    versionName: latestVersion,
                }
                dispatch(setEditorLock(newLock))
                updateLocalEditorLock(newLock)
                
                const sessionRecordingPagination = await getSessionRecordings(id);
                if (sessionRecordingPagination.data.length < 1) {
                  throw new Error("Session has no recordings")
                }

                const pipeline = sessionRecordingPagination.data[0].pipeline
                let diarization = false;
                pipeline.forEach(tag => {
                    if (tag.config.parameters.enableSd) {
                        diarization = tag.config.parameters.enableSd
                    }
                })

                if (diarization) {
                    dispatch(setDiarization(true))
                }

                dispatch(
                    setTbFormat({
                        editorState: newEditorState,
                        sessionId: id,
                    })
                );
            }

            dispatch(setValidRedirect(true));
            dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));

            const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${id}/audio.wav?access_token=${user.accessToken}&nocache=${new Date().getTime()}`;

            dispatch(
                setAudioInfo({
                    url: audioUrlPath,
                    loadNew: true,
                })
            );

            navigate('/editor');
        } catch (e) {
            enqueueSnackbar(
                //@ts-ignore
                e.response && e.response.data && e.response.data.message
                    ? //@ts-ignore
                    e.response.data.message
                    : `Izbrana seja trenutno ni na voljo.`,
                { variant: 'error' }
            );
        }
        cancelSessionOpening();
    }
    const onSessionOpen = async (event: React.MouseEvent) => {
        event.stopPropagation();
        const target = event.target as HTMLElement

        if (target.classList.contains("version-opener") ||
            target.classList.contains("version-option") ||
            target.classList.contains("version-option-date") ||
            target.classList.contains("version-option-name")) return;


        await openSession(values.content);
    };

    const onRowExpand = () => {
        if (!expanded && rowRef.current) {
            if (rowRef.current.classList.contains('blurred-row-header')) return;
            //rowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

            const rowHeaders = Array.from(document.getElementsByClassName('row-header')).concat(Array.from(document.getElementsByClassName('row-header-deleted')))
            for (let rowHeaderIndex = 0; rowHeaderIndex < rowHeaders.length; rowHeaderIndex++) {
                const row = rowHeaders[rowHeaderIndex]
                if (!row) continue;

                const key = row.getAttribute('identifier')
                if (key === values.content.id.toString()) {
                    row.classList.add('selected-row-header')
                    continue;
                } else if (key === null) {
                    continue;
                }

                row.classList.add('blurred-row-header')
            }
        } else {
            const rowHeaders = Array.from(document.getElementsByClassName('row-header')).concat(Array.from(document.getElementsByClassName('row-header-deleted')))
            for (let rowHeaderIndex = 0; rowHeaderIndex < rowHeaders.length; rowHeaderIndex++) {
                const row = rowHeaders[rowHeaderIndex]
                if (!row) continue;

                const key = row.getAttribute('identifier')
                if (key === values.content.id.toString()) {
                    row.classList.remove('selected-row-header')
                    continue;
                } else if (key === null) {
                    continue;
                }

                row.classList.remove('blurred-row-header')
            }
            setTimeout(() => setTriggerExpanding(false), 500)
        }
        setExpanded(!expanded)
    }

    //This sets the start target element only if it includes react-resizable-handle className
    const onMouseDown = (event: SyntheticEvent) => {
        const target = event.target as HTMLElement
        if (
            target.classList.contains("react-resizable-handle") ||
            target.classList.contains("manage-labels-button") ||
            target.classList.contains("manage-labels-button-expanded") ||
            target.classList.contains("session-open") ||
            target.classList.contains("version-opener") ||
            target.classList.contains("version-option") ||
            target.classList.contains("version-option-date") ||
            target.classList.contains("version-option-name")
        ) {
            targetElementRef.current = target
        }
    }

    //This verifies that target element was set. If yes it resets it and returns
    //otherwise it expands the row
    const onMouseUp = () => {
        if (targetElementRef.current) {
            targetElementRef.current = null
            return;
        }

        if (!triggerExpanding) {
            setTriggerExpanding(true)
        } else {
            onRowExpand();
        }
    }

    const onResizeStart = (key: TableHeaders) => {
        const header = document.getElementById(`table-header-${key}`)
        const rows = document.getElementsByClassName(`row-cell-${key}`)

        if (!header || rows.length === 0) {
            return;
        }

        header.classList.add('resizing-header')

        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            const row = rows.item(rowIndex)
            if (!row) continue;

            row.classList.add('resizing-row')
        }
    }

    const onResizeStop = (key: TableHeaders) => {
        const header = document.getElementById(`table-header-${key}`)
        const rows = document.getElementsByClassName(`row-cell-${key}`)

        if (!header || rows.length === 0) {
            return;
        }

        header.classList.remove('resizing-header')

        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            const row = rows.item(rowIndex)
            if (!row) continue;

            row.classList.remove('resizing-row')
        }
    }

    useEffect(() => {
        if (rowRef.current) {
            rowRef.current.setAttribute('identifier', values.content.id.toString())
        }
    }, [rowRef.current])


    const sum = tableHeaders.map(header => header.width).reduce((prev, cur) => prev + cur)

    const openLabels = (event: React.MouseEvent<HTMLElement>) => {
        if (!anchorElAddLabel) {
            const target = event.currentTarget;
            getClientLabels().then(({ data }) => {
                dispatch(setSessionsLabels(data));
                setAnchorElAddLabel(target);
            });
        } else setAnchorElAddLabel(null);
    }
    // Used for when the user clicks away when the Add Label popover is opened
    const clickAwayHandler = () => {
        if (anchorElAddLabel) setAnchorElAddLabel(null);
    };


    const toggleVersionsDropdown = (event: React.MouseEvent<HTMLElement>) => {
        if (!anchorElOpenVersions) {
            const target = event.currentTarget;
            setAnchorElOpenVersions(target)
        } else setAnchorElOpenVersions(null);
    }
    // Used for when the user clicks away when the select version popover is opened
    const versionClickAwayHandler = () => {
        if (anchorElOpenVersions) setAnchorElOpenVersions(null);
    };

    const openEditorWithSelectedVersion = async (event: SyntheticEvent, versionName: string) => {
        const target = event.target as HTMLElement
        if (target.classList.contains("version-option") ||
            target.classList.contains("version-option-date") ||
            target.classList.contains("version-option-name")) {
            setAnchorElOpenVersions(null)
        }

        await openSession(values.content, versionName);
    }

    const downloadSession = () => {
        getAllDataAboutSessionAdmin(values.content.id)
            .then(
                ([
                    { data: dSession },
                    { data: dSummary },
                    {
                        data: dRecordings,
                    },
                    { data: dTranscripts },
                ]) => {
                    const printable = {
                        session: { ...dSession, ...dSummary },
                        recordings: dRecordings,
                        transcripts: dTranscripts.map((transcript, index) => ({
                            index,
                            content: transcript,
                        })),
                    };

                    

                    var data = new Blob([JSON.stringify(printable, null, 4)], { type: 'application/json' });

                    saveAs(data, `truebar-${values.content.id}.json`);
                }
            )
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Prenos podatkov neuspešen.', {
                    variant: 'error',
                });
            });
    }
    const openShare = () => setShareModal(true)
    const copySessionHandler = async () => {
        try {
            await copySession(values.content.id)
            enqueueSnackbar('Seja uspešno kopirana!', { variant: 'success' });
            await restartDashboardData();
        } catch (error) {
            enqueueSnackbar('Seja neuspešno kopirana!', { variant: 'error' });
        }
    }

    const deleteSessionHandler = async () => {
        try {
            await deleteSession(values.content.id, values.content.isDiscarded && isHardDeleter)
            const rowHeaders = Array.from(document.getElementsByClassName('row-header')).concat(Array.from(document.getElementsByClassName('row-header-deleted')))
            for (let rowHeaderIndex = 0; rowHeaderIndex < rowHeaders.length; rowHeaderIndex++) {
                const row = rowHeaders[rowHeaderIndex]
                if (!row) continue;

                const key = row.getAttribute('identifier')
                if (key === values.content.id.toString()) {
                    row.classList.remove('selected-row-header')
                    continue;
                } else if (key === null) {
                    continue;
                }

                row.classList.remove('blurred-row-header')
            }
            deleteRow(values.content.id);
        } catch (error) {
            enqueueSnackbar('Seja neuspešno izbrisana!', { variant: 'error' });
        }
    }

    const addButtonOpen = Boolean(anchorElAddLabel);
    const addButtonId = addButtonOpen ? 'simple-popper' : undefined;


    const versionSelectOpen = Boolean(anchorElOpenVersions);
    const versionSelectButtonId = versionSelectOpen ? 'simple-popper' : undefined;

    const generateColor = () => {
        return tempColours[availableLabels.length % tempColours.length];
    };

    const addNewLabel = (labelId: number, code: string, color: string) => {
        addNewLabelToSession(values.content.id, labelId, true).then(() => {
            addLabel(values.content.id, {
                isEnabled: true,
                label: {
                    id: labelId,
                    code,
                    color,
                },
            })

            setNewLabel('');
            setAnchorElAddLabel(null);
        });
    };

    const registerNewLabel = () => {
        const color = generateColor();

        if (
            availableLabels.some(({ code }) => code === newLabel) ||
            values.content.labels.some(({ label: { code } }) => code === newLabel)
        ) {
            enqueueSnackbar(`Labela "${newLabel}" že obstaja.`, {
                variant: 'error',
                autoHideDuration: 4000,
            });

            return;
        }

        registerNewClientLabel(newLabel, color)
            .then(({ data: { id } }) => addNewLabel(id, newLabel, color))
            .catch((err) => {
                const labelId = err.response?.data?.id;

                if (labelId) {
                    setNewLabel('');
                    addNewLabelToSession(values.content.id, labelId, true).then(() => {
                        getSessionLabels(values.content.id).then(({ data }) => {
                            addLabel(values.content.id, data)
                        });
                    });
                }
            });
    };

    useEffect(() => {
            getClientLabels(newLabel).then(({ data }) => {
                dispatch(setSessionsLabels(data));
            });
    }, [newLabel])

    const removeLabelWrapper = (event: React.MouseEvent<HTMLElement>, labelIdToDelete: number) => {
        event.stopPropagation();

        console.log(`SESSION ID: ${values.content.id}`)
        console.log(`LABEL ID: ${labelIdToDelete}`)
        removeLabelFromSession(values.content.id, labelIdToDelete).then(() => {
            removeLabel(values.content.id, labelIdToDelete)
        });
    };

    const toggleLabelWrapper = (labelId: number, isEnabled: boolean) => {
        updateSessionLabel(values.content.id, labelId, isEnabled).then(() => {
            toggleLabel(values.content.id, labelId, isEnabled)
        })
    }


    useEffect(() => {
        if (debouncedNotes !== values.content.notes) {
            const changeNotes = async () => {
                try {
                    await patchSessionInfo({ notes: debouncedNotes }, values.content.id)
                    updateNotes(values.content.id, debouncedNotes)
                } catch (error) {
                    enqueueSnackbar('Zapiski niso bili posodobljeni!', { variant: 'error' });
                    setNotes(values.content.notes)
                }
            }

            changeNotes();

        }
    }, [debouncedNotes])

    useEffect(() => {
        let mounted = true
        if (expanded) {
            setLoading(true)
            const fetchInitialData = async () => {
                try {
                    const response = await getSessionSummary(values.content.id)
                    const responseRecordings = await getSessionRecordings(values.content.id)
                    if (mounted) {
                        setSummary(response.data.summary)
                        setRecordings(responseRecordings.data)
                    }
                } catch (error) {
                    console.log(error)
                }

                if (mounted) {
                    setLoading(false)
                }
            }

            fetchInitialData();
        } else {
            if (mounted) {
                setSummary('')
                setRecordings([])
                setLoading(false)
            }
        }

        return () => {
            mounted = false
        }
    }, [expanded])

    const initialRef = useRef<boolean>(true)
    useEffect(() => {
        if (initialRef.current) {
            initialRef.current = false
        } else if (triggerExpanding) {
            onRowExpand();
        }
    }, [triggerExpanding])

    const isVisible = useOnScreen(values.content.id)
    const recreateSessionCallback = async () => {
        try {
            const pipeline = buildPipeline();
            if (!pipeline) {
                enqueueSnackbar('Operacija ni možna, ker ni izbran noben model. Preverite nastavitve.', { variant: 'error' })
                return;
            }
            await recreateSession(values.content.id, pipeline);
            await restartDashboardData();
        } catch (error) {
            console.log(error)
        }
    }

    const cancelSessionProggress = async () => {
        try {
            await cancelSessionInProgress(values.content.id);
            await restartDashboardData();
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <tr id={values.content.id.toString()} key={values.content.id.toString()} className={`row-header${values.content.isDiscarded ? '-deleted' : ''}`} ref={rowRef} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
                {isVisible && tableHeaders.map((tableHeader, index) => {

                    if (tableHeader.key === TableHeaders.status) {
                        const object = transformStatusEnums(values.content[tableHeader.key], values.content.isDiscarded)

                        return (
                            <td key={`${tableHeader.key}-${values.content.id.toString()}`}>
                                <div className={`row-cell-${tableHeader.key}`} style={{ position: 'relative', width: tableHeader.width, height: 30, padding: '0' }} >
                                    <ResizableBox
                                        style={{ top: 25 }}
                                        className='row-resizer'
                                        width={tableHeader.width}
                                        minConstraints={[100, 0]}
                                        maxConstraints={[(window.innerWidth * 0.8) / Object.values(TableHeaders).length, 100]}
                                        axis="x"
                                        resizeHandles={['e']}
                                        onResizeStart={() => onResizeStart(tableHeader.key)}
                                        onResizeStop={() => onResizeStop(tableHeader.key)}
                                        onResize={(_, { size }) => handleResize(index, size.width)}>
                                    </ResizableBox>
                                    <div onClick={onSessionOpen} className={`session-open ${object.class} ${object.containerClass}`} style={{ position: 'absolute', top: 0, left: 0, width: tableHeader.width - 20, height: '100%', display: 'flex', alignItems: 'center', paddingLeft: 12 }}>
                                        <p className='session-open' style={{ fontSize: 18, fontWeight: values.content[tableHeader.key] === SessionState.FINISHED ? 500 : 400, margin: 0, width: tableHeader.width, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: 1 }}>{object.text}</p>
                                        {!values.content.isDiscarded && (
                                            <div style={{ display: 'flex', alignItems: 'center' }} className='version-opener' onClick={toggleVersionsDropdown}>
                                                <ArrowDropDownIcon />
                                            </div>
                                        )}
                                    </div>
                                    {/**TOOD: Add popper here*/}
                                    <Popper
                                        id={versionSelectButtonId}
                                        open={versionSelectOpen}
                                        anchorEl={anchorElOpenVersions}
                                        placement="bottom-end"
                                        disablePortal={false}
                                        style={{ zIndex: 100000, width: tableHeader.width - 25 }}
                                        modifiers={[
                                            {
                                                name: 'flip',
                                                enabled: true,
                                            },
                                            {
                                                name: 'preventOverflow',
                                                enabled: true,
                                                options: {
                                                    boundariesElement: 'scrollParent'
                                                },
                                            }
                                        ]}
                                        transition
                                    >
                                        {({ TransitionProps }) => (
                                            <ClickAwayListener onClickAway={versionClickAwayHandler}>
                                                <Zoom {...TransitionProps} timeout={350}>
                                                    <Paper
                                                        elevation={3}
                                                        style={{ marginLeft: '-5px', paddingTop: '4px', paddingBottom: '4px' }}
                                                        className='roundedcornes'
                                                    >
                                                        {values.content.versionTags
                                                            .map(isoDate => (
                                                                <MenuItem className='version-option' key={isoDate} onClick={event => openEditorWithSelectedVersion(event, isoDate)} style={{ backgroundColor: '#FFFFFF', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <p className='version-option-date'>{moment(isoDate).format('DD.M.YYYY HH:mm:ss')}</p>
                                                                    <p className='version-option-name'>{isoDate}</p>
                                                                </MenuItem>
                                                            ))}
                                                    </Paper>
                                                </Zoom>
                                            </ClickAwayListener>
                                        )}
                                    </Popper>
                                </div>
                            </td>
                        )
                    } else if (tableHeader.key === TableHeaders.name) {
                        return (
                            <td key={`${tableHeader.key}-${values.content.id.toString()}`}>
                                <div className={`row-cell-${tableHeader.key}`} style={{ position: 'relative', width: tableHeader.width, height: 30, padding: '0' }} >
                                    <ResizableBox
                                        style={{ top: 25 }}
                                        className='row-resizer'
                                        width={tableHeader.width}
                                        minConstraints={[100, 0]}
                                        maxConstraints={[(window.innerWidth * 0.8) / Object.values(TableHeaders).length, 100]}
                                        axis="x"
                                        resizeHandles={['e']}
                                        onResizeStart={() => onResizeStart(tableHeader.key)}
                                        onResizeStop={() => onResizeStop(tableHeader.key)}
                                        onResize={(_, { size }) => handleResize(index, size.width)}>
                                    </ResizableBox>
                                    <div style={{ position: 'absolute', top: 0, left: 0, width: tableHeader.width - 20, height: '100%', display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 18, fontWeight: 700, margin: 0, width: tableHeader.width, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {transformHeaderValue(tableHeader.key, values.content[tableHeader.key])}
                                        </p>
                                    </div>
                                </div>
                            </td>
                        )
                    } else if (tableHeader.key === TableHeaders.labels) {
                        return (
                            <td key={`${tableHeader.key}-${values.content.id.toString()}`}>
                                <div className={`row-cell-${tableHeader.key}`} style={{ position: 'relative', width: tableHeader.width, height: 30, padding: '0px 0px' }} >
                                    <ResizableBox
                                        style={{ top: 25 }}
                                        className='row-resizer'
                                        width={tableHeader.width}
                                        minConstraints={[100, 0]}
                                        maxConstraints={[200, 100]}
                                        axis="x"
                                        resizeHandles={['e']}
                                        onResizeStart={() => onResizeStart(tableHeader.key)}
                                        onResizeStop={() => onResizeStop(tableHeader.key)}
                                        onResize={(_, { size }) => handleResize(index, size.width)}>
                                    </ResizableBox>
                                    <div style={{ position: 'absolute', top: 0, left: 0, width: tableHeader.width - 20, height: '100%', display: 'flex', alignItems: 'center' }}>
                                        {(values.content[tableHeader.key] as IChipData[]).map(label => {
                                            if (label.isEnabled) {
                                                return (
                                                    <p key={label.label.id} style={{ fontSize: 18, margin: 0, color: label.label.color, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>■</p>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </td>
                        )
                    } else {
                        return (
                            <td key={`${tableHeader.key}-${values.content.id.toString()}`}>
                                <div className={`row-cell-${tableHeader.key}`} style={{ position: 'relative', width: tableHeader.width, height: 30, padding: '0px 0px' }} >
                                    <ResizableBox
                                        style={{ top: 25 }}
                                        className='row-resizer'
                                        width={tableHeader.width}
                                        minConstraints={[100, 0]}
                                        maxConstraints={[200, 100]}
                                        axis="x"
                                        resizeHandles={['e']}
                                        onResizeStart={() => onResizeStart(tableHeader.key)}
                                        onResizeStop={() => onResizeStop(tableHeader.key)}
                                        onResize={(_, { size }) => handleResize(index, size.width)}>
                                    </ResizableBox>
                                    <div style={{ position: 'absolute', top: 0, left: 0, width: tableHeader.width - 20, height: '100%', display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 18, margin: 0, width: tableHeader.width, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: tableHeader.key === TableHeaders.recordedMs ? 'right' : 'left' }}>
                                            {transformHeaderValue(tableHeader.key, tableHeader.key === TableHeaders.visibility ? values.shares : values.content[tableHeader.key])}
                                        </p>
                                    </div>
                                </div>
                            </td>
                        )
                    }
                })}
            </tr>
            {triggerExpanding && (
                <>
                    <ShareModal
                        open={shareModal}
                        handleClose={setShareModal}
                        modalTitle={values.content.name}
                        sessionId={values.content.id}
                        setSharedWithOthers={() => { }}
                    />


                    <Popper
                        id={addButtonId}
                        open={addButtonOpen}
                        anchorEl={anchorElAddLabel}
                        placement="right-end"
                        disablePortal={false}
                        style={{ zIndex: 100000 }}
                        modifiers={[
                            {
                                name: 'flip',
                                enabled: true,
                            },
                            {
                                name: 'preventOverflow',
                                enabled: true,
                                options: {
                                    boundariesElement: 'scrollParent'
                                },
                            }
                        ]}
                        transition
                    >
                        {({ TransitionProps }) => (
                            <ClickAwayListener onClickAway={clickAwayHandler}>
                                <Zoom {...TransitionProps} timeout={350}>
                                    <Paper
                                        elevation={3}
                                        className="accordion-chip-popper-container roundedcornes"
                                        style={{ marginLeft: '-5px' }}
                                        square={false}>
                                        <div className='accordion-chip-popper-wrapper'>
                                            {availableLabels
                                                .filter(({ id }) => !values.content.labels.some(({ label: { id: _id } }) => _id === id))
                                                .map(({ id, code, color }) => (
                                                    <MenuItem key={id} onClick={() => addNewLabel(id, code, color)}>
                                                        <div className="accordion-chip-popper-dot" style={{ backgroundColor: color }} />
                                                        {code}
                                                    </MenuItem>
                                                ))}

                                            {availableLabels.filter(({ id }) => !values.content.labels.some(({ label: { id: _id } }) => _id === id)).length === 0 && (
                                                <MenuItem key='-1'>
                                                    Na voljo ni nobena labela
                                                </MenuItem>
                                            )}
                                        </div>

                                        <div className="accordion-chip-popper-footer">
                                            <AddCircleOutlineOutlined
                                                fontSize="small"
                                                className="accordion-chip-popper-footer-add"
                                                style={{ color: generateColor() }}
                                            />
                                            <TextField
                                                fullWidth
                                                placeholder="Vnesi ime labele"
                                                value={newLabel}
                                                spellCheck={false}
                                                onChange={(e) => setNewLabel(e.target.value)}
                                                variant="outlined"
                                                className='paddingoutline'
                                                onKeyPress={(ev) => {
                                                    if (ev.key === 'Enter') registerNewLabel();
                                                }}
                                            />
                                        </div>
                                    </Paper>
                                </Zoom>
                            </ClickAwayListener>
                        )}
                    </Popper>

                    <tr key={`expanded-row-${values.content.id}`} className='row-body'>
                        <td colSpan={tableHeaders.length}>
                            <AnimateHeight
                                duration={500}
                                height={expanded ? 'auto' : 0}>

                                <div style={{ width: sum }} className='row-body-content'>
                                    <div className='row-body-content-session-info'>
                                        <div className='preview-notes-container'>
                                            <div className='preview-container'>
                                                <div>
                                                    <p className='preview-header'>Predogled</p>
                                                </div>
                                                <div className='preview-contents'>
                                                    <p className='preview-contents-text'>
                                                        {summary}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className='notes-container'>
                                                <p className='notes-header'>Zapiski</p>
                                                <textarea
                                                    className='notes-textarea'
                                                    placeholder='Vnesi tekst tukaj'
                                                    value={notes}
                                                    onChange={text => setNotes(text.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className='recordings-table-wrapper'>
                                            <RecordingsTable recordings={recordings} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row-body-toolbar'>
                                    <div className='labels-container-wrapper'>
                                        <button onClick={openLabels} className='manage-labels-button' />
                                        {values.content.labels.length > 0 && (
                                            <div className='labels-container'>
                                                {values.content.labels.map((label, index) => {
                                                    return (
                                                        <div className='label-wrapper' key={label.label.id} onClick={() => toggleLabelWrapper(label.label.id, !label.isEnabled)} style={{
                                                            backgroundColor: label.isEnabled ? label.label.color : 'transparent',
                                                            border: `1px solid ${label.isEnabled ? 'transparent' : label.label.color}`,
                                                            marginLeft: index === 0 ? 0 : 6,

                                                        }}>
                                                            <p className='label-button-text' style={{ color: label.isEnabled ? '#FFF' : label.label.color }}>{label.label.code}</p>
                                                            <button data-testid={`delete-label-${label.label.code}`} className='delete-label-button' onClick={(event) => removeLabelWrapper(event, label.label.id)}>
                                                                <DeleteLabelIcon fill={label.isEnabled ? "#FFF" : label.label.color} />
                                                            </button>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {values.content.status === SessionState.IN_QUEUE && (
                                            <button onClick={cancelSessionProggress} className='cancel-session-button'>
                                                <CancelIcon />
                                            </button>
                                        ) || (
                                            <button onClick={recreateSessionCallback} className='recreate-button'>
                                                <RestartIcon />
                                            </button>
                                        )}
                                        <button onClick={downloadSession} className='download-button' />
                                        <button onClick={openShare} className='share-button' />
                                        <button onClick={copySessionHandler} className='copy-button' />
                                        {(!values.content.isDiscarded || (values.content.isDiscarded && isHardDeleter)) && (
                                        <button onClick={toggleDeletion} className='delete-button' />
                                        )}
                                        <div onClick={onSessionOpen} className='open-button'>
                                            <p>ODPRI</p>
                                        </div>

                                    </div>
                                </div>
                            </AnimateHeight>
                        </td>
                    </tr>

                    <DeletionConfirmation
                        hardDelete={values.content.isDiscarded && isHardDeleter}
                        open={deletionConfirmationModal}
                        handleClose={toggleDeletion}
                        deleteSessionCallback={deleteSessionHandler}
                    />
                </>
            )}
        </>
    )
}

interface ITableHeaderProps {
    header: ITableHeader
    index: number,
    handleResize: (index: number, width: number) => void;
    moveColumn: (sourceIndex: number, destinationIndex: number) => void;
    sortHeader: (headerKey: TableHeaders) => void;
}

const TableHeader = ({
    header,
    index,
    handleResize,
    moveColumn,
    sortHeader
}: ITableHeaderProps) => {
    const {
        key,
        label,
        width
    } = header
    const [{ isDragging }, dragRef] = useDrag({
        type: 'TABLE_COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [{ canDrop, isOver }, dropRef] = useDrop({
        accept: 'TABLE_COLUMN',
        drop: (droppedItem: { index: number }) => {
            if (droppedItem.index !== index) {
                moveColumn(droppedItem.index, index);
            }
        },
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
        }),
    });

    const isDraggingRef = useRef<boolean>(false);
    useEffect(() => {
        if (isDragging) {
            isDraggingRef.current = true;
        } else if (isOver) {
            isDraggingRef.current = false;
        }
    }, [isDragging, isOver])



    const opacity = isDragging ? 0.5 : 1;
    let backgroundColor = '#F7F7F7'

    const cursor = canDrop ? 'move' : 'default';

    const onResizeStart = () => {
        const header = document.getElementById(`table-header-${key}`)
        const rows = document.getElementsByClassName(`row-cell-${key}`)

        if (!header || rows.length === 0) {
            return;
        }

        header.classList.add('resizing-header')

        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            const row = rows.item(rowIndex)
            if (!row) continue;

            row.classList.add('resizing-row')
        }
    }

    const onResizeStop = () => {
        const header = document.getElementById(`table-header-${key}`)
        const rows = document.getElementsByClassName(`row-cell-${key}`)

        if (!header || rows.length === 0) {
            return;
        }

        header.classList.remove('resizing-header')

        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            const row = rows.item(rowIndex)
            if (!row) continue;

            row.classList.remove('resizing-row')
        }
    }

    const sortColumn = () => {
        if (isDraggingRef.current) {
            isDraggingRef.current = false;
            return;
        }
        sortHeader(header.key)
    }

    return (
        <th ref={dropRef} style={{ opacity, backgroundColor, cursor }}>
            <div id={`table-header-${key}`} style={{ position: 'relative', width, height: 30, padding: '0' }}>
                <ResizableBox
                    style={{ top: 25 }}
                    width={width}
                    minConstraints={[100, 0]}
                    maxConstraints={[200, 100]}
                    axis="x"
                    resizeHandles={['e']}
                    onResizeStart={onResizeStart}
                    onResizeStop={onResizeStop}
                    onResize={(_, { size }) => handleResize(index, size.width)}>
                </ResizableBox>
                <div onClick={sortColumn} ref={dragRef} style={{ position: 'absolute', top: 0, left: 0, width: width - 20, height: '100%', display: 'flex', alignItems: 'center', paddingLeft: header.key === TableHeaders.status ? 12 : 0 }}>
                    <p style={{ margin: 0, textAlign: header.key === TableHeaders.recordedMs ? 'right' : 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{label}</p>
                    {header.sortDirection && (
                        <div className={`${header.sortDirection === SortDirection.desc ? 'sorting-desc-icon' : 'sorting-asc-icon'}`} />
                    )}
                </div>
            </div>
        </th>
    )
}

const getInitialWidthForHeader = (key: TableHeaders) => {
    const width = window.innerWidth
    switch (key) {
        case TableHeaders.status: return width > 1500 ? (width - 120) * 0.11 : 150
        case TableHeaders.name: return width > 1500 ? (width - 120) * 0.11 : 200
        case TableHeaders.createdByUsername: return width > 1500 ? (width - 120) * 0.11 : 100
        case TableHeaders.labels: return width > 1500 ? (width - 120) * 0.11 : 100
        case TableHeaders.sources: return width > 1500 ? (width - 120) * 0.11 : 100
        case TableHeaders.recordedMs: return width > 1500 ? (width - 120) * 0.11 : 100
        case TableHeaders.createdAt: return width > 1500 ? (width - 120) * 0.11 : 200
        case TableHeaders.updatedAt: return width > 1500 ? (width - 120) * 0.11 : 200
        case TableHeaders.visibility: return width > 1500 ? (width - 120) * 0.11 : 100
    }
}

interface IV3Table {
    setLoading: (value: boolean) => void;
}
const Table = ({
    setLoading,
}: IV3Table) => {
    const [isSessionOpening, setIsSessionOpening] = useState<boolean>(false);
    
    const setSessionIsOpening = useCallback(() => {
        if (isSessionOpening) return;
        setIsSessionOpening(true);
    }, [isSessionOpening])

    const cancelSessionOpening = useCallback(() => {
        if (!isSessionOpening) return;
        setIsSessionOpening(false);
    }, [isSessionOpening])

    useEffect(() => {
        window.document.body.style.cursor = isSessionOpening ? 'wait' : 'default';
        return () => {
            window.document.body.style.cursor = 'default'
        }
    }, [isSessionOpening])


    const [last, setLast] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const appliedFilters = useAppSelector(state => state.app.dashboardFilters);

    const [tableHeaders, setTableHeaders] = useState<ITableHeader[]>(Object.values(TableHeaders).map(header => {
        return {
            key: header,
            label: translateHeader(header),
            width: getInitialWidthForHeader(header),
            sortDirection: null,
        }
    }))
    const [rows, setRows] = useState<ISessionEntryWithShared[]>([])

    const handleResize = (index: number, width: number) => {
        const newColumnWidths = [...tableHeaders];
        newColumnWidths[index].width = width;
        setTableHeaders(newColumnWidths);
    };

    const moveColumn = (sourceIndex: number, destinationIndex: number) => {
        if (sourceIndex === destinationIndex) {
            return; // No need to swap if the indexes are the same
        }

        const tableHeadersCopy = [...tableHeaders]
        const tmp = tableHeadersCopy[sourceIndex]
        tableHeadersCopy[sourceIndex] = tableHeadersCopy[destinationIndex]
        tableHeadersCopy[destinationIndex] = tmp;

        setTableHeaders(tableHeadersCopy)
    };


    const restartDashboardData = async () => {
        setLoading(true)
        const headerIndex = tableHeaders.findIndex(header => header.sortDirection)

        const container = document.getElementById('containers_container')
        if (container) {
            container.scrollTo({left: 0, top: 0, behavior: 'smooth'});
        }

        try {
            const filters: URLSearchParams = transformFilters(appliedFilters);
            const {
                sessions,
                last
            } = await getSessionsWithShares(
                undefined,
                40,
                filters,
                { columnName: 'null', type: "ASC" }
            );

            const rowHeaders = document.getElementsByClassName('row-header')
            for (let rowHeaderIndex = 0; rowHeaderIndex < rowHeaders.length; rowHeaderIndex++) {
                const row = rowHeaders.item(rowHeaderIndex)
                if (!row) continue;

                const key = row.getAttribute('identifier')
                if (key === null) {
                    continue;
                }
                row.classList.remove('selected-row-header')
                row.classList.remove('blurred-row-header')
            }


            

            setRows(sessions)
            setLast(last)
        } catch (error) {
            console.log(error)
        }

        setLoading(false)
    }

    //This sould trigger data invalidation and first slice re-fetch
    const sortHeader = async (headerKey: TableHeaders) => {
        setLoading(true)
        const sortableHeader = headerKey === TableHeaders.status || headerKey === TableHeaders.name || headerKey === TableHeaders.recordedMs || headerKey === TableHeaders.createdAt || headerKey === TableHeaders.updatedAt

        if (!sortableHeader) {
            setLoading(false)
            return;
        }

        const tableHeadersCopy = [...tableHeaders]
        let direction: SortDirection = SortDirection.desc
        tableHeadersCopy.forEach(tableHeader => {
            if (tableHeader.key === headerKey) {
                if (!tableHeader.sortDirection || tableHeader.sortDirection === SortDirection.asc) {
                    tableHeader.sortDirection = SortDirection.desc
                } else {
                    direction = SortDirection.asc
                    tableHeader.sortDirection = SortDirection.asc
                }
            } else {
                tableHeader.sortDirection = null
            }
        })

        try {
            const filters: URLSearchParams = transformFilters(appliedFilters);
            const {
                sessions,
                last
            } = await getSessionsWithShares(undefined, 40, filters, { columnName: 'null', type: "ASC" });

            const rowHeaders = document.getElementsByClassName('row-header')
            for (let rowHeaderIndex = 0; rowHeaderIndex < rowHeaders.length; rowHeaderIndex++) {
                const row = rowHeaders.item(rowHeaderIndex)
                if (!row) continue;

                const key = row.getAttribute('identifier')
                if (key === null) {
                    continue;
                }
                row.classList.remove('selected-row-header')
                row.classList.remove('blurred-row-header')
            }

            setTableHeaders(tableHeadersCopy)
            setRows(sessions)
            setLast(last)
        } catch (error) {
            console.log(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        let mounted = true

        const fetchFirstSlice = async () => {
            try {
                const filters: URLSearchParams = transformFilters(appliedFilters);
                const {
                    sessions,
                    last
                } = await getSessionsWithShares(undefined, 40, filters, { columnName: 'null', type: "ASC" });
                if (mounted) {
                    setRows(sessions)
                    setLast(last)
                }
            } catch (error) {
                console.log(error)
            }

            setLoading(false);
        }

        fetchFirstSlice();


        return () => {
            mounted = false
        }
    }, [appliedFilters, setLoading])

    useEffect(() => {
        const interval = setInterval(async () => {
            const rowsToBeUpdated = rows.map(row => {
                const {
                    content
                } = row
                const shouldRefetchRow = !(content.status === SessionState.FINISHED || content.status === SessionState.ERROR || content.status === SessionState.CANCELED)
                if (shouldRefetchRow) return content.id
                return -1
            }).filter(id => id > -1)
    
            const updatedSessions = await getSessionsWithSharesForIds(rowsToBeUpdated)
    
            const rowsCopy = [...rows]
            updatedSessions.forEach(session => {
                const rowIndex = rowsCopy.findIndex(row => row.content.id === session.id)
                if (rowIndex < 0) return;
                rowsCopy[rowIndex].content = session
            })
    
            setRows(rowsCopy)
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [rows])

    /*useInterval(() => {
        const test = async () => {
            try {
                const filters: URLSearchParams = transformFilters(appliedFilters);
                const {
                    sessions,
                    last
                } = await getSessionsWithShares(undefined, 40, filters, { columnName: null, type: null });
                setRows(sessions)
                setLast(last)
            } catch (error) {
                console.log(error)
            }
        }

        test();
    }, 15000, [appliedFilters, tableHeaders])*/


    const updateNotes = (id: number, updatedNotes: string) => {
        const rowsCopy = [...rows]
        const updateRowIndex = rowsCopy.findIndex(row => row.content.id === id)
        if (updateRowIndex < 0) return;
        rowsCopy[updateRowIndex].content.notes = updatedNotes;
        setRows(rowsCopy)
        enqueueSnackbar('Zapiski so bili posodobljeni!', { variant: 'success' });
    }

    const deleteRow = (id: number) => {
        setRows([...rows].filter(row => row.content.id !== id))
        enqueueSnackbar('Seja uspešno izbrisana!', { variant: 'success' });
    }

    const addLabel = (id: number, label: IChipData) => {
        const rowsCopy = [...rows]
        const updateRowIndex = rowsCopy.findIndex(row => row.content.id === id)
        if (updateRowIndex < 0) return;
        rowsCopy[updateRowIndex].content.labels.push(label)
        rowsCopy[updateRowIndex].content.labels = rowsCopy[updateRowIndex].content.labels.sort(function (a, b) {
            var textA = a.label.code.toUpperCase();
            var textB = b.label.code.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        setRows(rowsCopy)
        enqueueSnackbar('Labela uspešno dodana!', { variant: 'success' });
    }

    const removeLabel = (sessionId: number, labelId: number) => {
        const rowsCopy = [...rows]
        const updateRowIndex = rowsCopy.findIndex(row => row.content.id === sessionId)
        rowsCopy[updateRowIndex].content.labels = rowsCopy[updateRowIndex].content.labels.filter(label => label.label.id !== labelId)
        setRows(rowsCopy)
        enqueueSnackbar('Labela uspešno izbrisana!', { variant: 'success' });
    }

    const toggleLabel = (sessionId: number, labelId: number, isEnabled: boolean) => {
        const rowsCopy = [...rows]
        const updateRowIndex = rowsCopy.findIndex(row => row.content.id === sessionId)
        const updateLabelIndex = rowsCopy[updateRowIndex].content.labels.findIndex(label => label.label.id === labelId)
        rowsCopy[updateRowIndex].content.labels[updateLabelIndex].isEnabled = isEnabled;
        setRows(rowsCopy)
    }

    /*const topFetch = async (cursor: string) => {
        setLoading(true)
        try {
            const sortingHeader = tableHeaders.filter(tableHeader => tableHeader.sortDirection)
            let columnName: TableHeaders | null = null;
            let type: SortDirection | null = null;
            if (sortingHeader.length > 0) {
                columnName = sortingHeader[0].key
                type = sortingHeader[0].sortDirection
            }
            const filters: URLSearchParams = transformFilters(appliedFilters);
            const {
                sessions,
                last
            } = await getSessionsWithShares(cursor, -40, filters, { columnName, type });
            if (last) { //in this case, last is first. Rename this property in getSessionsWithShares
                setRows(sessions)
                
                setLowerBufferHeight(height => height + rows.length * 31)
                setUpperBufferHeight(height => Math.max(height - rows.length * 31, 0))
            }
            
        } catch (error) {
            console.log(error)
        }
        rowInitialyVisible.current = true;
        setLoading(false)
    }*/

    /*const delayPromise = new Promise(resolve => {
        setTimeout(resolve, 2000);
    });*/

    const bottomFetch = async (cursor: string) => {
        setLoading(true)
        try {
            //await delayPromise;
            const sortingHeader = tableHeaders.filter(tableHeader => tableHeader.sortDirection)
            let columnName: TableHeaders | null = null;
            let type: SortDirection | null = null;
            if (sortingHeader.length > 0) {
                columnName = sortingHeader[0].key
                type = sortingHeader[0].sortDirection
            }
            const filters: URLSearchParams = transformFilters(appliedFilters);
            const {
                sessions,
                last
            } = await getSessionsWithShares(cursor, 40, filters, { columnName: 'null', type: "ASC" });
            /*if (last) {
                setUpperBufferHeight(height => height + rows.length * 31)
                setLowerBufferHeight(height => Math.max(height - rows.length * 31, 0))
                setRows(_ => sessions)
                setLast(last)
            }*/
            setRows(rows => rows.concat(sessions))
            setLast(last)
        } catch (error) {
            console.log(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        const children = Array.from(document.getElementsByClassName('row-header')).concat(Array.from(document.getElementsByClassName('row-header-deleted')))

        const expandedRowIndex = children.findIndex(child => child.classList.contains('selected-row-header'));
        const allAreRowsShrunk = expandedRowIndex < 0;

        if (!allAreRowsShrunk) {
            children.forEach((child, index) => {
                if (index !== expandedRowIndex || child.classList.contains('row-body')) {
                    child.classList.add('blurred-row-header');
                }
            })
        }
    }, [rows])


    /*useEffect(() => {
        const elements = Array.from(document.getElementsByClassName('row-header'))
        if (elements.length < 1) return;

        const observerOptions: IntersectionObserverInit = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };

        const observerCallback: IntersectionObserverCallback = (entries) => {
            entries.forEach(entry => {
                const identifier = entry.target.getAttribute('identifier')
                if (entry.isIntersecting) {
                    //console.log(`${identifier} | ${rows[rows.length - 1].content.id.toString()}`)
                    if (identifier === rows[rows.length - 1].content.id.toString()) {
                        //Trigger fetching
                        console.log(rows[rows.length - 1].content.id.toString())
                        bottomFetch(rows[rows.length - 1].cursor);
                    } else if (identifier === rows[0].content.id.toString() && rowInitialyVisible.current) {
                        rowInitialyVisible.current = false
                    } else if (identifier === rows[0].content.id.toString() && !rowInitialyVisible.current) {
                        topFetch(rows[0].cursor);
                    }
                    //console.log(`Row ${identifier} is in view!`);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        elements.forEach(element => observer.observe(element))

        return () => {
            elements.forEach(element => observer.unobserve(element))
        };

    }, [rows]);*/

    //const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const elements = Array.from(document.getElementsByClassName('row-header')).concat(Array.from(document.getElementsByClassName('row-header-deleted')))
        if (elements.length < 1) return;

        const observerOptions: IntersectionObserverInit = {
            root: null, // Use the viewport as the root element
            rootMargin: '0px', // No margin
            threshold: 0.5, // Trigger when at least 50% of the table is visible
        };

        const observerCallback: IntersectionObserverCallback = (entries) => {
            entries.forEach(entry => {

                
                /*const scrollTop = entry.target.scrollTop;
                const startIdx = Math.floor(scrollTop / rowHeight);
                const endIdx = Math.min(totalRows - 1, Math.floor((scrollTop + entry.target.clientHeight) / rowHeight));
                setVisibleIndices([startIdx, endIdx]);*/

                const identifier = entry.target.getAttribute('identifier')

                if (!last && entry.isIntersecting) {
                    if (identifier === rows[rows.length - 1].content.id.toString()) {
                        //Trigger fetching
                        bottomFetch(rows[rows.length - 1].cursor)

                    }
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        elements.forEach(element => observer.observe(element))

        return () => {
            elements.forEach(element => observer.unobserve(element))
        };

    }, [rows]);

    /*useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.addEventListener("scroll", callback)
        }

        return () => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.removeEventListener("scroll", callback)
            }
        }
    }, [])*/

    /*const [fetchingBottom, setFetchingBottom] = useState<boolean>(false)
    const [fetchingTop, setFetchingTop] = useState<boolean>(false)
    useEffect(() => {
        const wrapper = async () => {
            try {
                await bottomFetch(rows[rows.length - 1].cursor)
                setFetchingBottom(false);
            } catch (error) {
                console.log(error)
            }
        }

        if (fetchingBottom) {
            wrapper()
        }
    }, [fetchingBottom])

    useEffect(() => {
        const wrapper = async () => {
            try {
                await topFetch(rows[0].cursor)
                setFetchingTop(false);
            } catch (error) {
                console.log(error)
            }
        }

        if (fetchingTop) {
            wrapper()
        }
    }, [fetchingTop])*/


    /*const [startIndex, setStartIndex] = useState<number>(0)
    const [endIndex, setEndIndex] = useState<number>(39)
    const onTableScroll = useCallback(async () => {

        if (!containerRef.current || !contentRef.current) return;
        
        //32 is row height
        const newStartIndex = Math.floor(containerRef.current.scrollTop / 32)

        const newEndIndex = Math.min(
            newStartIndex + Math.ceil(containerRef.current.clientHeight / 32),
            rows.length - 1
        );

        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);


        /*const containerHeight = ;
        const middleDivOffsetTop = contentRef.current.offsetTop;
        const middleDivHeight = contentRef.current.clientHeight;
        const scrollTop = containerRef.current.scrollTop;*/

    /*if (containerRef.current.scrollTop < upperBufferHeight) {
        containerRef.current.scrollTop = upperBufferHeight
    }*/
    /*
    //console.log(`scrollTop: ${scrollTop} | middleDivOffsetTop: ${middleDivOffsetTop}`)
    if (initialScroll < scrollTop) {
        //check if scroll should be limited
        //check if bottomFetch should be called
        if (scrollTop + containerHeight >= middleDivOffsetTop + middleDivHeight) {
            console.log('ON BOTTOM')
            setFetchingBottom(true)
        } 
    } else if (initialScroll > scrollTop) {
        //check if scroll should be limited
        //check if topFetch should be called

        console.log(`scrollTop: ${scrollTop} | middleDivOffsetTop - 30: ${middleDivOffsetTop - 30} | upperBufferHeight: ${upperBufferHeight}`)
        if (scrollTop === middleDivOffsetTop - 31) {
            console.log('ON TOP')
            setFetchingTop(true)
        } else if (scrollTop < middleDivOffsetTop - 31) {
            containerRef.current.scrollTop = upperBufferHeight
        }
    }*/



    /*const scrollBottom = scrollTop + contentRef.current.clientHeight

    if (containerRef.current.scrollTop < upperBufferHeight) {
        containerRef.current.scrollTop = upperBufferHeight
    }

    console.log(`scrollBottom: ${scrollBottom} | upperBufferHeight + contentRef.current.clientHeight: ${upperBufferHeight + contentRef.current.clientHeight}`)

    if (scrollTop === upperBufferHeight) {
        topFetch(rows[0].cursor)
        //scrollTop === topBufferHeight //topFetch
    } else if (scrollBottom === upperBufferHeight + contentRef.current.clientHeight) {
        bottomFetch(rows[rows.length - 1].cursor)
        //scrollBottom === topBufferHeight + contentHeight //bottomFetch
    }

    //initialScroll = scrollTop
}, [rows])*/

    return (
        <div id='containers_container'>
            <div className={`container`}>
                <table id={`table`}>
                    {/*<div className='upper-buffer' style={{height: upperBufferHeight}}></div>*/}
                    <thead id='table_head'>
                        <tr>
                            {tableHeaders.map((header, index) =>
                                <TableHeader
                                    sortHeader={sortHeader}
                                    key={header.key}
                                    header={header}
                                    index={index}
                                    handleResize={handleResize}
                                    moveColumn={moveColumn} />
                            )}
                        </tr>
                    </thead>
                    <tbody id='table_body'>
                        {rows/*.slice(startIndex, endIndex)*/.map(row =>
                            <Row
                                rows={rows}
                                restartDashboardData={restartDashboardData}
                                setLoading={setLoading}
                                toggleLabel={toggleLabel}
                                removeLabel={removeLabel}
                                addLabel={addLabel}
                                deleteRow={deleteRow}
                                updateNotes={updateNotes}
                                handleResize={handleResize}
                                isSessionOpening={isSessionOpening}
                                setSessionIsOpening={setSessionIsOpening}
                                cancelSessionOpening={cancelSessionOpening}
                                tableHeaders={tableHeaders}
                                key={row.content.id}
                                values={row} />
                        )}
                        {/*<List
                            height={rows.length * 30}
                            itemCount={rows.length}
                            itemSize={30}
                            width={tableHeaders.reduce((sum, column) => sum + column.width, 0)}
                            itemData={{cancelToken: sessionCancelTokenRef.current, cancelRequestToken, setCancelToken, doesCancelTokenExist, restartDashboardData, setLoading, toggleLabel, removeLabel, addLabel, deleteRow, updateNotes, handleResize, tableHeaders, rows}}
                        >
                            {({index, style, data}) => (
                                <Row data={data} index={index}/>
                            )}
                        </List>*/}
                    </tbody>
                </table>
            </div>

            {last && <p>PRIŠLI STE DO KONCA</p>}
        </div>
    )
}

const V3Table = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <NewTable />
        </DndProvider>
    )
}


export default V3Table;

enum RecordingTableHeaders {
    Number = "#",
    Recording = "Posnetek",
    Duration = "Dolžina posnetka",
    CreatedAt = "Datum kreiranja",
    User = "Uporabnik",
    Model = "Model",
    Settings = "Nastavitve",
}

interface RecordingTableProps {
    recordings: IRecordingsArray
}
const RecordingsTable = ({
    recordings
}: RecordingTableProps) => {

    return (
        <table className="recordings-table">
            <thead className='recordings-table-thead'>
                <tr>
                    {Object.values(RecordingTableHeaders).map(header => {
                        let className = 'left-aligned-recording-table-header'
                        if (
                            header === RecordingTableHeaders.Model ||
                            header === RecordingTableHeaders.Duration ||
                            header === RecordingTableHeaders.Settings
                        ) {
                            className = 'right-aligned-recording-table-header'
                        }

                        return <td className={`${className} recordings-table-header-cell`}>{header}</td>
                    })}
                </tr>
            </thead>
            <tbody className='recordings-table-tbody'>
                {recordings.map((recording, index) => {
                    const asrPipeIndex = recording.pipeline.findIndex(pipe => pipe.task === Task.asr)
                    const model = asrPipeIndex < 0 ? "Brez ASR" : recording.pipeline[asrPipeIndex].config.tag
                    const duration = moment.duration(recording.recordedMs);

                    const hours = Math.floor(duration.asHours());
                    const minutes = Math.floor(duration.minutes());
                    const seconds = Math.floor(duration.seconds());
                    const remainingMilliseconds = Math.floor(duration.milliseconds());

                    const formatedDuration = `${hours > 0 ? hours + ':' : ''}${minutes > 0 ? minutes + ':' : ''}${seconds}.${remainingMilliseconds}`

                    return (
                        <tr>
                            <td className='recordings-table-cell'>{index}</td>
                            <td className='recordings-table-cell'>name</td>
                            <td className='recordings-table-cell' style={{ textAlign: 'right' }}>{formatedDuration}</td>
                            <td className='recordings-table-cell'>{recording.createdAt}</td>
                            <td className='recordings-table-cell'>user</td>
                            <td className='recordings-table-cell' style={{ textAlign: 'right' }}>{model}</td>
                            <td className='recordings-table-cell' style={{ textAlign: 'right' }}>FTTFFFTT</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}