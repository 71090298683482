import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function CrossIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8L8 16M8.00001 8L16 16" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </SvgIcon>
    )
}