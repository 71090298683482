import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { getPhonems } from '../../api/DictionaryService';
import useHover from '../../hooks/useHover';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import useSelectedModelData from '../../hooks/useSelectedModelData';
import { AIcon, EditIcon, TrashIcon } from '../Icons/DictionaryIcons';
import CommandButton from './CommandButton';
import { useAppDispatch } from '../../redux/store';
import { setAddPronaun, setWordPronaun } from '../../redux/features/app/app';

interface Props {
  text: string;
  wIndex: number;
  pIndex: number;
  saved?: boolean;
  updateWord?: () => void;
}

export default function Pronaunciation({ text, wIndex, pIndex, saved, updateWord }: Props) {
  // const initText = useRef(text);
  // const initSaved = useRef(saved);
  const [hoverRef, { value: isHovered, setValue: setHover }] = useHover();
  const modelData = useSelectedModelData();
  const dispatch = useAppDispatch();
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState(text);
  const inputRef = useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    if (!showInput) return;
    // setValue('');
    inputRef.current?.focus();
  }, [showInput]);
  const onDeleteClick = () => {
    dispatch(setWordPronaun({ pronaun: null, pronaunIndex: pIndex, wordIndex: wIndex }));
  };
  const onConfirm = async (e: any, isForm: boolean) => {
    isForm && e.preventDefault();
    if (!value.length) {
      setValue(text);
      return;
    }
    if (value !== text) {
      // TO-DO:
      // const isSameAndSaved = initText.current === value && initSaved.current === true;
      dispatch(
        setWordPronaun({
          pronaun: { text: value },
          pronaunIndex: pIndex,
          wordIndex: wIndex,
        })
      );

      updateWord && updateWord();
    }
    setShowInput(false);
  };

  return (
    <span className="word_pron" ref={hoverRef}>
      {isHovered && (
        <PronaunPopup anchorEl={hoverRef?.current} isFixed>
          <div className="popup_commands_wrapper">
            <CommandButton onClick={onDeleteClick}>
              <TrashIcon style={{
                minHeight: 24,
                height: '2.2vw',
                maxHeight: 34,
                minWidth: 24,
                width: '2.2vw',
                maxWidth: 34,
                cursor: 'pointer',
                fill: '#F2F2F2',
              }} />
            </CommandButton>
            <CommandButton
              onClick={() => {
                setShowInput(true);
              }}
            >
              <EditIcon style={{
                minHeight: 24,
                height: '2.2vw',
                maxHeight: 34,
                minWidth: 24,
                width: '2.2vw',
                maxWidth: 34,
                cursor: 'pointer',
                fill: '#F2F2F2',
              }} />
            </CommandButton>
          </div>
        </PronaunPopup>
      )}
      {showInput && (
        <>
          /
          <form onSubmit={(e) => onConfirm(e, true)}>
            <input
              ref={inputRef}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{ width: `${value.length * 12}px`, minWidth: 20 }}
              onBlur={(e) => onConfirm(e, false)}
            />
          </form>
          /
        </>
      )}
      {!showInput && (
        <>
          <span
            style={{
              color: isHovered ? '#000000' : saved ? '#707070' : '#2b8dff',
              // cursor: 'pointer',
            }}
            onClick={() => {
              setShowInput(true);
            }}
          >
            /{value}/
          </span>
        </>
      )}
    </span>
  );
}

export function PronaunActions({}) {}

export function AddPronaunciation({ wIndex, updateWord }: Props) {
  const [hoverRef, { value: isHovered, setValue: setHover }] = useHover();
  const [showPhonemHelper, setShowPhonemHelper] = useState(false);
  const [showInput, setShowInput] = useState<{ show: boolean; initValue: string }>({
    show: false,
    initValue: '',
  });

  const [value, setValue] = useState('+');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (!showInput.show) return;
    setValue(showInput.initValue);
    inputRef.current?.focus();
  }, [showInput]);

  const onConfirm = (e: any) => {
    e.preventDefault();
    setShowInput({ show: false, initValue: '' });
    if (!value.length) {
      setValue('+');
      return;
    }
    if (value === '+') return;

    dispatch(setAddPronaun({ pronaun: { text: value }, wordIndex: wIndex }));
    updateWord && updateWord();
    setValue('+');
    setShowInput({ show: false, initValue: '' });
    setHover(false);
  };

  const onSelectCallback = (p: string) => {
    if (!showInput.show) {
      setShowInput({ show: true, initValue: p });
    } else {
      setValue((curr) => {
        if (curr === '+') {
          return p;
        }
        return curr + p;
      });
    }
  };

  return (
    <span className="word_pron" ref={hoverRef}>
      {isHovered && !showPhonemHelper && !showInput.show && (
        <PronaunPopup anchorEl={hoverRef?.current} isFixed>
          <div className="popup_commands_wrapper">
            <CommandButton
              onClick={() => {
                setShowPhonemHelper(true);
              }}
            >
              <AIcon style={{
                minHeight: 24,
                height: '2.2vw',
                maxHeight: 34,
                minWidth: 24,
                width: '2.2vw',
                maxWidth: 34,
                cursor: 'pointer',
                fill: '#F2F2F2',
              }} />
            </CommandButton>

            <CommandButton
              onClick={() => {
                setShowInput({ show: true, initValue: '' });
                setHover(false);
              }}
            >
              <EditIcon style={{
                minHeight: 24,
                height: '2.2vw',
                maxHeight: 34,
                minWidth: 24,
                width: '2.2vw',
                maxWidth: 34,
                cursor: 'pointer',
                fill: '#F2F2F2',
              }}/>
            </CommandButton>
          </div>
        </PronaunPopup>
      )}
      {showPhonemHelper && (
        <PronaunPopup anchorEl={hoverRef?.current} isFixed customContent>
          <PhonemHelper setShowPhonemHelper={setShowPhonemHelper} onSelectCallback={onSelectCallback} />
        </PronaunPopup>
      )}
      {showInput.show && (
        <form
          onSubmit={(e) => {
            setShowPhonemHelper(false);
            onConfirm(e);
          }}
          // style={{ width: '100%', maxWidth: `${value.length * 12}px`, minWidth: `${value.length * 12}px` }}
        >
          /
          <input
            ref={inputRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{ width: `${value.length * 12}px`, minWidth: 20 }}
            onBlur={(e) => {
              onConfirm(e);
            }}
          />
          /
        </form>
      )}
      {!showInput.show && (
        <>
          <span
            style={{ color: isHovered && !showPhonemHelper ? '#000000' : '#2b8dff', cursor: 'pointer' }}
            onClick={() => {
              setShowInput({ show: true, initValue: '' });
            }}
          >
            /{value}/
          </span>
        </>
      )}
    </span>
  );
}

function PhonemHelper({
  setShowPhonemHelper,
  onSelectCallback,
}: {
  setShowPhonemHelper: any;
  onSelectCallback?: (p: string) => void;
}) {
  const [tab, setTab] = useState(1);
  const modelData = useSelectedModelData();
  const { data: phones } = useQuery(
    `dmodel-phones-${modelData?.language}-${modelData?.domain}-${modelData?.modelVersion}`,
    () => {
      return modelData && getPhonems(modelData);
    },
    { enabled: !!modelData }
  );

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setShowPhonemHelper(false);
  });

  const selectedPhones = phones?.data && (tab === 1 ? phones.data.nonsilentPhones : phones.data.silentPhones);

  return (
    <div className="phonem_helper" ref={ref} onMouseDown={(e) => e.preventDefault()}>
      <div onClick={() => setTab(tab === 1 ? 2 : 1)}>{tab === 1 ? 'Ne-tihi' : 'tihi'}</div>
      <div className="phonem_helper_content">
        {selectedPhones &&
          selectedPhones.split(' ').map((p, i) => (
            <CommandButton
              key={p}
              className="phone"
              onClick={(e: any) => {
                onSelectCallback && onSelectCallback(p);
              }}
              isPronaunHelper
            >
              /{p}/
            </CommandButton>
          ))}
      </div>
    </div>
  );
}

function PronaunPopup({
  children,
  anchorEl,
  isFixed,
  customContent,
  classNames,
}: {
  children: any;
  anchorEl?: any;
  isFixed?: boolean;
  customContent?: boolean;
  classNames?: string;
}) {
  const childrenWrapperRef = useRef<HTMLDivElement | null>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const setPopupPosition = useCallback(() => {
    if (!isFixed || !anchorEl) return;
    const popupEl = popupRef?.current;
    if (!popupEl) return;
    const element = anchorEl as HTMLElement;
    const anchorRect = element.getBoundingClientRect();
    popupEl.style.position = 'fixed';
    popupEl.style.top = `${anchorRect.top - popupEl.clientHeight - 12}px`;
    popupEl.style.left = `${anchorRect.left + anchorRect.width / 2}px`;
    popupEl.style.right = `unset`;
    popupEl.style.bottom = `unset`;
    popupEl.style.transform = `translateX(-50%)`;
  }, [anchorEl, isFixed]);

  useEffect(() => {
    setPopupPosition();
    document.addEventListener('resize', setPopupPosition);
    document.addEventListener('scroll', setPopupPosition);
    return () => {
      document.removeEventListener('resize', setPopupPosition);
      document.removeEventListener('scroll', setPopupPosition);
    };
  }, [setPopupPosition]);

  return (
    <div className={`dict_popup${customContent ? '' : ' main_style'}`} ref={popupRef}>
      <div
        style={{
          width: '100%',
          height: 15,
          opacity: 0,
          top: '100%',
          position: 'absolute',
        }}
      />
      <div ref={childrenWrapperRef}>{children}</div>
      {!customContent && <div className="dict_popup_arrow"></div>}
    </div>
  );
}
function postWords(words: any, modelData: any) {
  throw new Error('Function not implemented.');
}
