import React from 'react';
import { IAddToDictWord } from '../../api/DictionaryService';
import MainWord from './MainWord';
import Pronaunciation, { AddPronaunciation } from './Pronaunciation';
import Skeleton from '@mui/material/Skeleton';
import { useAppSelector } from '../../redux/store';

interface Props {
  words?: IAddToDictWord[];
  isLoading?: boolean;
  isError?: boolean;
  isIdle?: boolean;
}

export default function WordLine({ words, isLoading, isError, isIdle }: Props) {
  const searchBy = useAppSelector(state => state.app.dictionary.searchBy);

  const noResults =
    searchBy === 'pronunciation' && !isLoading && words && words.length === 1 && words[0].text === '';
  return (
    <div
      className="dictionary_word_line_wrapper show_scrollbar"
      style={{ minHeight: 100, overflowX: 'auto' }}
    >
      {!isLoading && !words && isIdle && (
        <span className="ctx_string">
          Za iskanje po leksikonu vpišite željeno frazo in potrdite izbiro s pritiskom na Enter
        </span>
      )}
      {((!isLoading && words && words.length === 0) || noResults) && (
        <span className="ctx_string">Našli nismo nobenega zadetka</span>
      )}

      {isLoading &&
        new Array(3).fill(null).map((_, i) => (
          <div className="word_prons_wrapper" key={i.toString()}>
            <Skeleton variant="text" height={26} width={'100%'} />
          </div>
        ))}

      {!isLoading &&
        !noResults &&
        words &&
        words.map((word, i) => <WordAndPronauns word={word} wI={i} key={`${word.text}${i}`} />)}
    </div>
  );
}

const WordAndPronauns = ({ word, wI }: { word: IAddToDictWord; wI: number }) => {
  return (
    <div className="word_prons_wrapper">
      <MainWord text={word.text} status={word.status} />
      <Pronauns word={word} wI={wI} />
    </div>
  );
};

const Pronauns = ({ word, wI }: { word: IAddToDictWord; wI: number }) => {
  return (
    <div
      className="word_prons_wrapper_inner"
      // style={{ maxWidth: '100%', overflowX: 'auto', width: 'auto' }}
    >
      {word.pronunciations &&
        word.pronunciations.length > 0 &&
        word.pronunciations.map((pro, j) => (
          <Pronaunciation
            // updateWord={() => updateWord(word)}
            key={pro.text + j.toString()}
            text={pro.text}
            pIndex={j}
            wIndex={wI}
            saved={pro.saved}
          />
        ))}
      <AddPronaunciation text={'+'} pIndex={-1} wIndex={wI} />
    </div>
  );
};
