import { EditorState } from 'draft-js';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useRef } from 'react';
import {
  deleteReplacement,
  getReplacements,
  INewReplacement,
  postNewReplacement,
} from '../../api/ReplacementsService';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

import '../../styles/css/main_modals.css';
import { replaceSelectionWithNewText } from './helpers/EditorHelpers';
import { IModal, IReplaceWordsModalProps, ModalVariantsEnums } from './IEditor';
import { ClearIcon } from '../Icons/ClearIcon';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';

interface IProps {
  replaceWordsModalProps: IReplaceWordsModalProps;
  editorState: EditorState;
  setEditorState: React.Dispatch<SetStateAction<EditorState>>;
  setModal: React.Dispatch<SetStateAction<IModal>>;
}

interface ISavedNewReplacement extends INewReplacement {
  id: number;
}

const ReplaceWordsModalContent = ({
  replaceWordsModalProps,
  editorState,
  setEditorState,
  setModal,
}: IProps) => {
  const [replaceString, setReplaceString] = useState('');
  const [resState, setResState] = useState(false);
  const [stringError, setStringError] = useState<string | boolean>(false);

  const [showAllReplacements, setShowAllReplacements] = useState<boolean>(false);
  const [allReplacements, setAllReplacements] = useState<ISavedNewReplacement[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const replaceRef = useRef<HTMLInputElement>(null);

  const { selectedPhrase, selection, se } = replaceWordsModalProps;

  useEffect(() => {
    if (stringError && replaceString.length > 0) {
      setStringError(false);
    }
  }, [stringError, replaceString]);

  useEffect(() => {
    const { current } = replaceRef;

    getReplacements()
      .then((r) => {
        setAllReplacements(r.data);
      })
      .catch((e) => {});

    if (current) {
      current.focus();
    }
  }, []);

  const handleConfirmClick = async () => {
    if (replaceString === '') {
      setStringError('Polje ne sme biti prazno');

      enqueueSnackbar('Polje ne sme biti prazno.', { variant: 'error' });
      replaceRef.current?.focus();
    }

    if (!se || replaceString === '' || !selection) return;

    try {
      const res = await postNewReplacement({
        source: selectedPhrase,
        target: { text: replaceString, spaceBefore: true },
      });

      if (res.status === 201) {
        const newState = replaceSelectionWithNewText(
          editorState,
          selection,
          replaceString,
          [se[0].start, se[se.length - 1].end],
          se[0].entityKey
        );

        newState.getCurrentContent().mergeEntityData(se[0].entityKey, {
          text: replaceString,
          updatedText: replaceString,
          metadata: { source: 'REPLACEMENT', original: selectedPhrase },
        });
        setEditorState(newState);

        enqueueSnackbar('Zamenjava je bila uspešno dodana.', { variant: 'success' });
        setModal({ show: false, variant: ModalVariantsEnums.EXIT });
      }
    } catch (error) {
      const mappedError = error as any;

      enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, {
        variant: 'error',
        autoHideDuration: null,
      });
    }
  };

  const handleRemoveReplacement = async (rep: ISavedNewReplacement) => {
    try {
      const r = await deleteReplacement(rep.id);

      enqueueSnackbar('Zamenjava je bila uspešno izbrisana', { variant: 'success' });
      setAllReplacements((curr) => {
        return curr.filter((r) => r.id !== rep.id);
      });
    } catch (error) {
      const mappedError = error as any
      if (mappedError.response.status === 403) {
        enqueueSnackbar("Za brisanje zamenjav nimate ustreznih pravic.", { variant: 'error' })
      } else {
        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
      }
    }
  };

  return (
    <div className="replace_words_modal_wrapper">
      <div>
        <div className="replace_words_modal_selected_phrase">
          <span>{selectedPhrase.map((w, i) => `${w.spaceBefore && i !== 0 ? ' ' : ''}${w.text}`)}</span>
        </div>

        <TextField
          value={replaceString}
          FormHelperTextProps={{
            className: 'helper_text',
          }}
          inputRef={replaceRef}
          error={stringError ? true : false}
          style={{
            width: '100%',
            fontFamily: 'Roboto',
          }}
          placeholder="Vnesite zamenjavo"
          margin="dense"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReplaceString(e.target.value)}
          helperText={stringError || ''}
        />
        {allReplacements.length > 0 && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
            <button onClick={() => setShowAllReplacements(!showAllReplacements)}>
              {showAllReplacements ? (
                <UnfoldLessIcon style={{ color: '#707070' }} />
              ) : (
                <UnfoldMoreIcon style={{ color: '#707070' }} />
              )}
            </button>
          </div>
        )}
        <div>
          {showAllReplacements &&
            allReplacements.map((r, i) => (
              <div
                key={i.toString()}
                style={{
                  color: '#333333',
                  width: '100%',
                  position: 'relative',
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingRight: '45px',
                  whiteSpace: 'pre',
                }}
              >
                <span style={{ fontStyle: 'italic' }}>
                  {r.source.map((w, i) => `${i !== 0 && w.spaceBefore ? ' ' : ''}${w.text}`)}:
                </span>

                <span style={{ marginLeft: '20px' }}>{r.target.text}</span>
                <button
                  onClick={() => handleRemoveReplacement(r)}
                  style={{
                    color: '#707070',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    transform: 'translateY(-50%)',
                  }}
                >
                  <ClearIcon width="18px" />
                </button>
              </div>
            ))}
        </div>
      </div>
      <div className="modal_buttons_wrapper" style={{ marginTop: '35px' }}>
        <button
          type="button"
          onClick={() => setModal({ variant: ModalVariantsEnums.EXIT, show: false })}
          className="modal_button_disaggre"
        >
          Prekliči
        </button>
        <button type="button" onClick={handleConfirmClick} className="modal_button_aggre">
          Zamenjaj
        </button>
      </div>
    </div>
  );
};

export default ReplaceWordsModalContent;
