import { IPipelineModel } from "../../redux/store/IStore";
import { ILocalModel, ISttPipelines } from "./ISettings";

export const convertLocalModelToPipelineModel = (localModel: ILocalModel, versionName: string, selectedFramework: string): IPipelineModel => {
    const versionObject: IPipelineModel = {
        name: versionName,
        serviceProvider: selectedFramework
    }

    localModel.features.forEach(feature => {
        switch (feature) {
            case "onlineAsr":
                versionObject.onlineAsr = true
                break;
            case "offlineAsr":
                versionObject.offlineAsr = true
                break;
            case "dictatedCommands":
                versionObject.dictatedCommands = { value: true, enabled: true }
                break;
            case "dictatedPunctuations":
                versionObject.dictatedPunctuations = { value: true, enabled: true }
                break;
            default:
                throw new Error(`Unsupported feature: ${feature}`)
        }
    })

    localModel.parameters.forEach(parameter => {
        switch (parameter.name) {
            case "enableUnks":
                versionObject.enableUnks = { value: true, enabled: true }
                break;
            case "enableInterims":
                versionObject.enableInterims = { value: true, enabled: true }
                break;
            case "enableSd":
                versionObject.enableSd = { value: true, enabled: true }
                break;
            default:
                throw new Error(`Unsupported parameter: ${parameter}`)
        }
    })

    return versionObject;
}


export const getLanguages = (
    isAdmin: boolean | null,
    configurationDictionary: ISttPipelines,
    selectedFramework: string | null,
) => {
    const frameworks = Object.keys(configurationDictionary)
    const languages = new Set<string>();

    if (
        selectedFramework &&
        configurationDictionary[selectedFramework] &&
        isAdmin
    ) {
        Object.keys(configurationDictionary[selectedFramework]).forEach(language => languages.add(language))
    } else {
        frameworks.forEach(framework => {
            Object.keys(configurationDictionary[framework]).forEach(language => languages.add(language))
        })
    }

    return Array.from(languages)
}

export const getDomains = (
    isAdmin: boolean | null,
    configurationDictionary: ISttPipelines,
    selectedFramework: string | null,
    selectedLanguage: string | null,
) => {
    const frameworks = Object.keys(configurationDictionary)
    const domains = new Set<string>();

    if (
        selectedFramework &&
        selectedLanguage &&
        configurationDictionary[selectedFramework] &&
        configurationDictionary[selectedFramework][selectedLanguage] &&
        isAdmin
    ) {
        Object.keys(configurationDictionary[selectedFramework][selectedLanguage]).forEach(domain => domains.add(domain))
    } else if (
        selectedFramework &&
        selectedLanguage &&
        configurationDictionary[selectedFramework]
    ) {
        const languageDomains = configurationDictionary?.[selectedFramework]?.[selectedLanguage]
        if (languageDomains) {
            Object.keys(languageDomains).forEach(domain => domains.add(domain))
        }
    } else if (selectedLanguage) {
        frameworks.forEach(framework => {
            const languageDomains = configurationDictionary?.[framework]?.[selectedLanguage]
            if (languageDomains) {
                Object.keys(languageDomains).forEach(domain => domains.add(domain))
            }
        })
    }
    
    return Array.from(domains)
}

export const getVersions = (
    isAdmin: boolean | null,
    configurationDictionary: ISttPipelines,
    selectedFramework: string | null,
    selectedLanguage: string | null,
    selectedDomain: string | null,
) => {
    const frameworks = Object.keys(configurationDictionary)
    const versions = new Set<IPipelineModel>();

    if (
        selectedFramework &&
        selectedLanguage &&
        selectedDomain &&
        configurationDictionary[selectedFramework] &&
        configurationDictionary[selectedFramework][selectedLanguage] &&
        configurationDictionary[selectedFramework][selectedLanguage][selectedDomain] &&
        isAdmin
    ) {
        Object.keys(configurationDictionary[selectedFramework][selectedLanguage][selectedDomain]).forEach(model => {
            const pipelineModel = convertLocalModelToPipelineModel(configurationDictionary[selectedFramework][selectedLanguage][selectedDomain][model], model, selectedFramework)
            versions.add(pipelineModel)
        })
    } else if (
        selectedLanguage &&
        selectedDomain
    ) {
        frameworks.forEach(framework => {
            const domainModels = configurationDictionary?.[framework]?.[selectedLanguage]?.[selectedDomain];
            if (domainModels) {
                Object.keys(domainModels).forEach(model => {
                    const pipelineModel = convertLocalModelToPipelineModel(domainModels[model], model, framework)
                    pipelineModel.serviceProvider = framework
                    versions.add(pipelineModel)
                })
            }
        })
    }
    return Array.from(versions)
}


export const getAvailableSettings = (
    isAdmin: boolean | null,
    configurationDictionary: ISttPipelines,
    selectedFramework: string | null,
    selectedLanguage: string | null,
    selectedDomain: string | null,
) => {

    const frameworks = Object.keys(configurationDictionary)
    const languages = getLanguages(isAdmin, configurationDictionary, selectedFramework);
    const domains = getDomains(isAdmin, configurationDictionary, selectedFramework, selectedLanguage)
    const versions = getVersions(isAdmin, configurationDictionary, selectedFramework, selectedLanguage, selectedDomain)

    return {
        frameworks,
        languages,
        domains,
        versions
    }
}