import axios from 'axios';
import store from '../redux/store';
import { removeTokens } from './AuthenticationService';
import { keycloak } from '../providers/KeycloakAuthProvider';
import { config as configEnv } from '../config';
import { enqueueSnackbar } from 'notistack';
import { API, PIPELINES, PROCESS } from './endpoints';
import { setIsDashboardLoading, setUser } from '../redux/features/app/app';

const instance = axios.create();

const {
  keycloakUrl,
  backendApiUrl,
  keycloakAuthReam,
  publicUrl,
  keycloakClientId
} = configEnv


instance.interceptors.request.use(
  async (config) => {

    //console.log('Starting Request', JSON.stringify(config, null, 2))

    const user = store.getState().app.user
    if (!user || !user.isEditorUser) {
      return config;
    }
    
    const isTokenReq = typeof config.data === 'string' && config.data.includes('grant_type');

    if (config.url === `${backendApiUrl}/${API}/${PIPELINES}/${PROCESS}?async=true`) {
      config.timeout = 100000;
      config.timeoutErrorMessage = "Zahtevek je potekel.";
    } else {
      config.timeout = 10000;
      config.timeoutErrorMessage = "Zahtevek je potekel.";
    }

    try {
      const isRefreshed = await keycloak.updateToken(5)
      const user = store.getState().app.user
      if (user && isRefreshed) {
        store.dispatch(setUser({
          ...user,
          accessToken: keycloak.token,
          idToken: keycloak.idToken
        }))
      }
    } catch (error) {
      console.log(error)
    }
    

    if (keycloak.token && keycloak.token !== '' && !isTokenReq) {
      config.headers.Authorization = `Bearer ${keycloak.token}`;
    }
    
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // removeToken();
    // console.log('ERROR FROM AXIOS:', error, error.response);

    if (error.response && error.response.status === 401) {
      keycloak.createLogoutUrl = () => `${keycloakUrl}/realms/${keycloakAuthReam}/protocol/openid-connect/logout?id_token_hint=${keycloak.idToken}&post_logout_redirect_uri=${publicUrl}&client_id=${keycloakClientId}`
      keycloak.logout();
      removeTokens();
      //store.dispatch(setRefetchTokens(null));
      store.dispatch(setUser(null));
    } else if (error.response && error.response.status === 500) {
      
      store.dispatch(setIsDashboardLoading(false))
      let message = "Prišlo je do napake na zalednem delu, prosimo kontaktirajte tehnično podporo."
      if (error.response.id) message += ` ${error.response.id}`;

      enqueueSnackbar(message, { variant: error.response.id ? 'autoCopyError' : 'error', autoHideDuration: error.response.id ? null : 5000 });
      return Promise.reject();
    }

    const customEvent = new CustomEvent('axios-error', { detail: error.response });
    // console.log(error.response);
    document.dispatchEvent(customEvent);

    return Promise.reject(error);
  }
);

export default instance;
