import React from 'react';
import '../../styles/css/header.css';
import CustomPopover from '../../shared/CustomPopover';
import useDownload from '../../hooks/useDownload';
import FileCopyIcon from '@mui/icons-material/FileCopy';

export const CopyButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const { handleCopyTextToClipBoard } = useDownload();

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        aria-describedby={id}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        <FileCopyIcon className="icon_button" htmlColor="#cccccc" />
        <CustomPopover open={open} anchorEl={anchorEl} onClose={onMouseLeave} width="100px">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className="download_button_option_box" onClick={() => handleCopyTextToClipBoard()}>
              <div className="download_button_option">.txt</div>
            </div>
            <div className="download_button_option_box" onClick={() => handleCopyTextToClipBoard(true)}>
              <div className="download_button_option">.html</div>
            </div>
          </div>
        </CustomPopover>
      </div>
    </>
  );
};
