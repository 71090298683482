import { useCallback, useEffect } from 'react';
import {
  resetDashboardHeader,
  setDashboardHeaderWidths,
  setDashboardPagination,
} from '../redux/features/app/app';
import { IHeader, IHeaderColumn, IStore, UserRoleEnums } from '../redux/store/IStore';
import {
  UserHeader,
  AdminHeader,
  Attributes,
  genereateReduxHeader,
} from '../components/Dashboard/IDashboard';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../redux/store';

export interface IUserLocalSettings {
  pageSize?: number;
}

export default function useLocalStorage() {
  const user = useAppSelector(state => state.app.user);

  const dispatch = useAppDispatch();

  const validateDashboardHeader = (isAdmin: boolean) => {
    if (!user) return false;

    const storageBlob = localStorage.getItem(`editor-${user.username}-header`);
    if (!storageBlob) return false;

    const localStorageHeader = JSON.parse(storageBlob) as IHeader;
    const header = isAdmin ? AdminHeader : UserHeader
    const keys = Object.keys(localStorageHeader);
    const currentKeys = Object.keys(header);

    if (!_.isEqual(currentKeys, keys)) return false;


    setReduxHeader(localStorageHeader);
    return true;
  };

  const setReduxHeader = useCallback(
    (header: IHeader) => {
      const toReduxWidths = {},
        toReduxHeader = {};

      const entries = Object.entries(header) as [Attributes, IHeaderColumn][];

      entries.forEach(([key, item]) => {
        toReduxWidths[key] = item.width;

        toReduxHeader[key] = {
          ...item,
          filtered: false,
        };
      });

      dispatch(setDashboardHeaderWidths(toReduxWidths));
      dispatch(resetDashboardHeader(toReduxHeader));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!user) return;

    const isAdmin = user.userRoles.includes(UserRoleEnums.ADMIN);

    if (!validateDashboardHeader(isAdmin))
      setReduxHeader(genereateReduxHeader(isAdmin ? AdminHeader : UserHeader));

    const userSettings = localStorage.getItem(`editor-${user.username}-page-size`);
    if (!userSettings) return;

    const { pageSize } = JSON.parse(userSettings) as IUserLocalSettings;
    if (pageSize) {
      dispatch(setDashboardPagination({ pageSize: pageSize }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch]);
}
