import Keycloak from 'keycloak-js';
import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { JWTDecoded } from '../components/Dashboard/IDashboard';
import { jwtDecode } from 'jwt-decode';
import { setUser } from '../redux/features/app/app';
import { UserRoleEnums } from '../redux/store/IStore';
import { config } from '../config';
import { useAppDispatch, useAppSelector } from '../redux/store';

export interface IKeycloakAuthProps {
    children: ReactNode;
}
interface IKeycloakAuthContext {
    isAuthenticated: boolean,
}
const KeycloakAuthContext = createContext<IKeycloakAuthContext>({
    isAuthenticated: false
});

const { 
    keycloakUrl,
    publicUrl,
    keycloakClientId,
    keycloakAuthReam,
} = config;

export const keycloak = new Keycloak({
    url: `${keycloakUrl}`,
    realm: keycloakAuthReam,
    clientId: keycloakClientId,
});

export const KeycloakAuthProvider: React.FC<IKeycloakAuthProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const dispatch = useAppDispatch();
    const user = useAppSelector(store => store.app.user)

    
    useEffect(() => {
        if (keycloak.token && user) {
            dispatch(setUser({
                username: user.username,
                isAuthenticated: user.isAuthenticated,
                accessToken: keycloak.token,
                refreshToken: keycloak.refreshToken,
                userRoles: user.userRoles,
                isEditorUser: user.isEditorUser,
                idToken: keycloak.idToken,
                group: user.group,
              }))
        }
    }, [keycloak.token])
    useEffect(() => {
        const wrapper = async () => {
            
            if (keycloak.authenticated) {
                setIsAuthenticated(true)
            } else {
                try {
                    const response = await keycloak.init({
                        checkLoginIframe: false,
                        //onLoad: 'login-required'
                        redirectUri: publicUrl,
                        
                    })

                    if (!response) {
                        keycloak.login({ redirectUri: publicUrl })
                        return;
                    }

                    if (keycloak.token) {
                        const decodedTokenData: JWTDecoded = jwtDecode(keycloak.token);

                        dispatch(
                            setUser({
                                username: decodedTokenData.preferred_username,
                                accessToken: keycloak.token,
                                refreshToken: keycloak.refreshToken,
                                isAuthenticated: true,
                                userRoles: decodedTokenData.realm_access.roles,
                                isEditorUser: decodedTokenData.realm_access.roles.includes(UserRoleEnums.EDITOR_USER),
                                idToken: keycloak.idToken,
                                group: '',
                            })
                        );
                        setIsAuthenticated(true)
                    } else {
                        setIsAuthenticated(false)
                    }

                    
                } catch (error) {
                    console.log(error)
                }
            }
        }

        wrapper();
    }, [])

    return <KeycloakAuthContext.Provider value={{ isAuthenticated }}>{children}</KeycloakAuthContext.Provider>;
};

export default KeycloakAuthContext;