import React from 'react';
import { IAppliedFilter } from '../DashboardHeader/ISearch';
import { DashboardRefreshState, IStore } from '../../redux/store/IStore';

import './../../styles/css/dashboard.css';
import DeleteSweep from '@mui/icons-material/DeleteSweep';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setDashboardFilterCommands, setDashboardFilters, setDashboardRefresh } from '../../redux/features/app/app';



const AppliedFilters = () => {
  const appliedFilters = useAppSelector(state => state.app.dashboardFilters);
  const dispatch = useAppDispatch();

  const removeFilter = (filterIndex: number): void => {
    const newFilters = JSON.parse(JSON.stringify(appliedFilters)).filter(
      (_: IAppliedFilter, index: number) => index !== filterIndex
    );

    dispatch(setDashboardFilters(newFilters));
    dispatch(setDashboardRefresh(DashboardRefreshState.ZERO));
  };

  return (
    <div id="applied-filters" className="applied-filters-row table-container">
      
      <div className="applied-filters-inner-wrapper">
        {appliedFilters.map((labelC, index) => (
          <div key={index} className="applied-filter-item">
            <span>{labelC.map(({ label, field }) => (field === 'value' ? `'${label}'` : `${label} `))}</span>
            <div className="applied-filter-remove" onClick={() => removeFilter(index)}>
              <CloseIcon fontSize="small" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const Commands = () => {
  const dispatch = useAppDispatch();
  const dashboardFilterCommands = useAppSelector(state => state.app.dashboardFilterCommands);

  return (
    <div className="applied-filters-row table-container" style={{ alignItems: 'center' }}>
      <span className="applied-filters-row-tag">Ukazi:</span>
      <div className="applied-filters-inner-wrapper">
        <Tooltip
          title={
            !dashboardFilterCommands.showDeletedSessions ? 'Pokaži zbrisane seje' : 'Skrij zbrisane seje'
          }
        >
          <IconButton
            aria-label="copy"
            onClick={() =>
              dispatch(
                setDashboardFilterCommands({
                  showDeletedSessions: !dashboardFilterCommands.showDeletedSessions,
                })
              )
            }
          >
            <DeleteSweep
              className="accordion-header-icon"
              style={{ color: !dashboardFilterCommands.showDeletedSessions ? '#2b8dff' : '' }}
            />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default AppliedFilters;
