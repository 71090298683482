import React from 'react';
// import { postLogs } from "../../api/LogService";
import FooterInfo from '../Home/FooterInfo';
import '../../styles/css/home.css';
import '../../styles/css/footer.css';

interface IProps {
  //code related to your props goes here
  children: any;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service

    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="home">
          <div
            style={{
              fontFamily: 'Roboto',
              color: '#000',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
              maxWidth: '600px',
              flexDirection: 'column',
            }}
          >
            <span>We are sorry but something went wrong.</span>
            <span
              onClick={() => {
                window.location.href = '/';
              }}
              style={{
                marginLeft: '10px',
                marginRight: '10px',
                marginTop: '15px',
              }}
              className="button_primary"
            >
              Return Home
            </span>
          </div>
          <FooterInfo />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
