import { EditorState, RawDraftContentState } from 'draft-js';
import { IReplacementWord } from '../../api/ReplacementsService';
import { Directions } from '../SelectionPopover/SelectionPopover';
import { CursorTypesEnum } from './LastPositionCursor';

export interface ILiveText {
  finals: string[];
  lastInterim: string;
  appendPrev: boolean;
}
export interface ISelectionProps {
  selectionSequenceApplied: boolean;
  startTime: number;
  endTime: number;
}

export interface ISelectionPopoverProps {
  anchorRect?: ClientRect;
  direction: Directions;
  selectedText?: string;
  hideDict?: boolean;
}

export interface ISpeakerSegment {
  startIndex: number;
  endIndex: number;
  speakerId: number | null;
}

export interface IFinal {
//  id: number;
  content: ITranscriptWord[];
}

export interface IFinalToSave {
  id: number;
  content: string;
}

export interface IWordStyles {
  showStart: boolean;
  showEnd: boolean;
  classNames: string[];
}

export interface ISpeaker {
  id: number;
  name: string;
  labels?: any;
}

export interface ITbFormat {
  // transcriptRaw: IWordData[];
  editorState?: EditorState;
  wordData?: IWordData[][];
  transcript?: IWordData[][];
  editorStateStringified?: string;
  sessionId?: number;
  rawContentState?: RawDraftContentState;
  // transcriptLast?: IWordData[];
  // transcriptDraftRaw: RawDraftContentState;
  // wordStyles: IWordStyles[];
  // speakerSegments: ISpeakerSegment[];
}

export interface ISnack {
  open: boolean;
  message: string;
  variant: 'success' | 'error';
}

export interface ITranscriptWord {
  startTime: number;
  endTime: number;
  confidence: number;
  text: string;
  variableCode?: string;
  inlineStyles?: string[];
  spaceBefore?: boolean;
  speakerCode?: string;
  metadata?: {
    source?: 'DICTATION' | 'STT' | 'PUNCTUATION' | 'REPLACEMENT';
    postCapitalized?: boolean;
    original?: IWordData[];
  };
}

export enum CommandCodes {
  NEW_PARAGRAPH = 'NEW_PARAGRAPH',
  NEW_LINE = 'NEW_LINE',
  UPPER_CASE = 'UPPER_CASE',
  LOWER_CASE = 'LOWER_CASE',
  END_UPPER_CASE = 'END_UPPER_CASE',
  END_LOWER_CASE = 'END_LOWER_CASE',
  CAPITALIZE_NEXT = 'CAPITALIZE_NEXT',
  DELETE_WORD = 'DELETE_WORD',
  DELETE_SENTENCE = 'DELETE_SENTENCE',
  DELETE_PARAGRAPH = 'DELETE_PARAGRAPH',
}

export enum Commands {
  NEW_PARAGRAPH = '<np>',
  NEW_LINE = '<nl>',
  UPPER_CASE = '<uc>',
  LOWER_CASE = '<lc>',
  END_UPPER_CASE = '</uc>',
  END_LOWER_CASE = '</lc>',
  CAPITALIZE_NEXT = '<cc>',
  DELETE_WORD = '<delw>',
  DELETE_PARAGRAPH = '<delp>',
  DELETE_SENTENCE = '<dels>',
  END_RECORDING = '<end>',
  FIX = '<corr>',
  CONFIRM = '<ok>',
  CANCEL = '<canc>',
  SPELL = '<spell>',
  FIND_WORD = '<find>',
  INSERT_ENTRY = '<ins>',
  UPPER_CASE_NEXT_WORD = '<UC>',
  LOWER_CASE_NEXT_WORD = '<LC>',
  SHOW_MORE = '<more>',
  SHOW_LESS = '<less>',
  SELECT = '<select>',
  NEXT = '<next>',
  PREV = '<prev>',
  LEFT = '<left>',
  RIGHT = '<right>',
  INSERT_BEFORE = '<insb>',
  INSERT_AFTER = '<insa>',
  REPLACE = '<repl>',
  BOLD_ON = '<b>',
  BOLD_OFF = '</b>',
  ITALIC_ON = '<i>',
  ITALIC_OFF = '</i>',
  UNDERLINE_ON = '<u>',
  UNDERLINE_OFF = '</u>',
  BOLD_NEXT = '<B>',
  ITALIC_NEXT = '<I>',
  UNDERLINE_NEXT = '<U>',
  INPUT = '<input>',
}

export enum EntryVariables {
  INPUT = '{input}',
  DATE = '{date}',
  TIME = '{time}',
}

export interface IDictationCommand {
  keyword: string;
  command: CommandCodes;
}

export interface IDictationSubstitution {
  from: string;
  to: string;
  leftHanded: boolean;
  rightHanded: boolean;
  keepText?: boolean;
  regex: any;
}

export interface IEntityRange {
  key: string;
  offset: number;
  length: number;
  word: string;
}

export interface IOldWordData {
  word: string;
  startTime: number;
  endTime: number;
  firstInFinal?: boolean;
  confidence: number;
}

export interface IWordData extends ITranscriptWord {
  isFinal?: boolean;
  firstInFinal?: boolean;
  containsRightHandedSub?: boolean;
  interimFrom?: number;
  lastInFinal?: boolean;
  paragraphI?: number;
  emptyBlockAfter?: number;
  newLineAfter?: boolean;
  //id?: number;
  inlineStyles?: string[];
  updatedText?: string;
  // Sticky styles mean that new transcripts inherit styles from existing words,
  // which is useful for example when highlighting {input}s.
  stickyInlineStyles?: boolean;
  appendToLastWordInPrevFinal?: boolean;
  appendToFirstWordInNextFinal?: boolean;
  appendToNextWord?: boolean;
  isSelected?: boolean;
  capitalizeNextWord?: boolean;
  firstInRecordingSession?: boolean;
  wasMergedWithCorrCommand?: boolean;
  appendedInterimString?: string;
  notInDict?: boolean;
  wasUpdatedAndSaved?: boolean;
  isInputWord?: boolean;
}

export interface IWordGroup {
  words: IWordData[];
  isInput?: boolean;
  cursor: {
    show: boolean;
    pos?: number;
  };
  pos: {
    start: number;
    end: number;
  };
}

export interface ILastSaved {
  lastSavedTs: number | null;
  isSaving: boolean;
  lastSaveSuccess: boolean;
}

export enum Mutabilities {
  MUTABLE = 'MUTABLE',
  IMMUTABLE = 'IMMUTABLE',
  SEGMENTED = 'SEGMENTED',
}

export interface IEntityMapEntry {
  type: string;
  mutability: Mutabilities;
  data: IWordData;
}

export interface IEntityMap {
  [key: string]: IEntityMapEntry;
}

export interface IEntityMapEntryVars {
  type: string;
  mutability: Mutabilities;
  data: any;
}

export interface IEntityMapVars {
  [key: string]: IEntityMapEntryVars;
}

export interface LiveOptions {
  postProcessingLive?: boolean;
  isInFindMode?: boolean;
  isFromUpload?: boolean;
  startWithCapital?: boolean;
  upperCaseAllNext?: boolean;
  lowerCaseAllNext?: boolean;
  capitalizeNextWord?: boolean;
  capitalizeNextWordCommandPrev?: boolean;
  deleteLastWordInPrevFinals?: boolean;
  boldOn?: boolean;
  boldNext?: boolean;
  italicOn?: boolean;
  italicNext?: boolean;
  underlineOn?: boolean;
  underlineNext?: boolean;
  fixModeOptions?: {
    isInFixMode: boolean;
    fixModeAvailableSubs: any;
  };
  isNewRecordingSession?: boolean;
  returnOnlyCurrentTr?: boolean;
  ignoreAllCommands?: boolean;
  cursorPosition?: number[] | null;
}

export interface UploadOptions {
  startWithCapital?: boolean;
  addSubs?: boolean;
}

export interface BlockPositions {
  position: number;
  isNewParagraph: boolean;
}

export interface MergeNewTrToLWDOptions {
  deleteToLastDotInPrevFinals?: boolean;
  deleteLastWordInPrevFinalsCounter?: number;
  deleteParagraph?: boolean;
  cursorPosition?: number[] | null;
  fromEntries?: boolean;
}

export interface IFinalBlock {
  speaker: ISpeaker | null;
  words: IWordData[];
}

export interface IWordDataLive {
  finalsBlocks: IFinalBlock[];
  lastInterim: string | IWordData[];
}

export enum CurrentCommandModeEnums {
  INIT = 'INIT',
  FIX_MODE = 'FIX_MODE',
  SPELL_MODE_ONLY = 'SPELL_MODE_ONLY',
}

export interface IFixSpellCommandMode {
  currentCommandMode: CurrentCommandModeEnums;
  isSpellingOn: boolean;
  selectedWordOriginalText: string | null;
  currentSpelledWord: string[];
  selectedWordIndexes: number[] | null;
  textToFetch: string;
  numberOfWordsInFixMode: number;
  prevWordsHistory?: null | IWordData[];
  hasAddedNewWordInSpellingMode?: boolean;
  wordDataSpellingOnlyMode?: IWordData;
}

export interface IInsCommandMode {
  isOn: boolean;
  insertedEntryInfo?: {
    startPos: number[] | null;
    length: number;
  };
}

export interface ISpellCommandMode {
  spellCommandModeOn: boolean;
  currentSpelledWord: string[];
  textToFetch: string;
}

export interface IFindCommandMode {
  findCommandModeOn: boolean;
  selectedWordIndexes: number[] | null;
  searchedText: string | null;
  selectedWordData: IWordData[] | null;
  selectedRangeLength: number;
  lostSearchContext?: boolean;
  isReplaceMode?: boolean;
  isInsertMode?: 'insa' | 'insb' | null;
}

export interface ICursor {
  cursorType: CursorTypesEnum;
  cursorPosition: number[] | null;
}

export interface ISubsContent {
  [key: string]: string;
}

export interface IEntry {
  content: IWordData[];
  description: string;
  id: number;
  belongsToGroup: boolean;
}

export interface ICommandModeSubs {
  subsState: 'loading' | 'available' | 'error';
  subs: ICommandSub[] | null;
  selectedSubText?: string;
  entries?: IEntry[];
}

export interface ICommandSub {
  id: string;
  keys: string[];
  subText: string;
  entry?: IEntry;
}

export interface ILoopModeOpts {
  isActive: boolean;
  startTime: number;
  endTime: number;
}

export interface ISelectedEntities {
  start: number;
  end: number;
  entityKey: string;
  blockKey: string;
  entity: Draft.EntityInstance;
}

export interface IModal {
  show: boolean;
  variant: ModalVariantsEnums;
}

export interface IReplaceWordsModalProps {
  selectedPhrase: IReplacementWord[];
  se: ISelectedEntities[] | null;
  selection: Draft.SelectionState | null;
}

export enum ModalVariantsEnums {
  BLOCKS = 'BLOCKS',
  EXIT = 'EXIT',
  DISCARD = 'DISCARD',
  ADD_TO_DICT = 'ADD_TO_DICT',
  REPLACE_WORDS = 'REPLACE_WORDS,',
  SPEAKERS = 'SPEAKERS',
  ENTRIES = 'ENTRIES',
}

export enum Colors {
  HIGHLIGHT_INPUT = 'lightgreen',
  OUTLINE_INPUT = '#2B8DFF',
  OUTLINE_ACTIVE_INPUT = '#F80534',
}
