import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function DownloadBox(props: any) {
  return (
    <SvgIcon {...props}>
      <svg id="download-box" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24">
        <path
          id="Path_171"
          data-name="Path 171"
          d="M0,0H24V24H0ZM0,0H24V24H0ZM0,0H24V24H0ZM0,0H24V24H0Z"
          transform="translate(24 24) rotate(180)"
          fill="currentColor"
          className="command_bg_color"
        />
        <path
          className="command_main_color"
          id="download_box"
          data-name="download box"
          d="M8,17V15h8v2H8m8-7-4,4L8,10h2.5V7h3v3H16M5,3H19a2,2,0,0,1,2,2V19a1.993,1.993,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2.006,2.006,0,0,1,5,3M5,5V19H19V5Z"
          transform="translate(24 24) rotate(180)"
          opacity="0.999"
        />
      </svg>
    </SvgIcon>
  );
}

export function ABIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          id="Path_176"
          data-name="Path 176"
          d="M0,0H24V24H0ZM0,0H24V24H0ZM0,0H24V24H0ZM0,0H24V24H0Z"
          transform="translate(24 24) rotate(180)"
          className="command_bg_color"
        />
        <path
          className="command_main_color"
          id="format-letter-case"
          d="M20.06,18a3.991,3.991,0,0,1-.2-.89,3.229,3.229,0,0,1-2.41,1.05,2.989,2.989,0,0,1-2.05-.71,2.254,2.254,0,0,1-.8-1.79,2.387,2.387,0,0,1,1-2.05,4.719,4.719,0,0,1,2.83-.73h1.4v-.64a1.552,1.552,0,0,0-.45-1.17,1.852,1.852,0,0,0-1.33-.43,2.238,2.238,0,0,0-1.3.36,1.067,1.067,0,0,0-.52.89H14.77a2.036,2.036,0,0,1,.45-1.24,2.922,2.922,0,0,1,1.22-.94,4.356,4.356,0,0,1,1.69-.35,3.315,3.315,0,0,1,2.29.73,2.654,2.654,0,0,1,.86,2.02V16a4.791,4.791,0,0,0,.3,1.88V18H20.06m-2.4-1.12a2.8,2.8,0,0,0,1.29-.32,2.029,2.029,0,0,0,.88-.83V14.16H18.7c-1.77,0-2.66.47-2.66,1.41a1.1,1.1,0,0,0,.46.96,1.842,1.842,0,0,0,1.16.35M5.46,13.71H9.53L7.5,8.29,5.46,13.71M6.64,6H8.36l4.71,12H11.14l-.97-2.57H4.82L3.86,18H1.93Z"
          transform="translate(0.245 -0.08)"
        />
      </svg>
    </SvgIcon>
  );
}

export function MoreContext(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Component_8_1"
        data-name="Component 8 – 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          id="Path_175"
          data-name="Path 175"
          d="M0,0H24V24H0ZM0,0H24V24H0ZM0,0H24V24H0ZM0,0H24V24H0Z"
          transform="translate(24 24) rotate(180)"
          className="command_bg_color"
        />
        <path
          id="unfold-more-vertical"
          d="M18.17,12,15,8.83l1.41-1.42L21,12l-4.59,4.58L15,15.17,18.17,12M5.83,12,9,15.17,7.59,16.59,3,12,7.59,7.42,9,8.83Z"
          transform="translate(0 0)"
          className="command_main_color"
        />
      </svg>
    </SvgIcon>
  );
}

export function LessContext(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Component_7_1"
        data-name="Component 7 – 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          id="Path_175"
          data-name="Path 175"
          d="M0,0H24V24H0ZM0,0H24V24H0ZM0,0H24V24H0ZM0,0H24V24H0Z"
          transform="translate(24 24) rotate(180)"
          className="command_bg_color"
        />
        <path
          id="unfold-less-vertical"
          d="M5.41,7.41,10,12,5.41,16.59,4,15.17,7.17,12,4,8.83,5.41,7.41m13.18,9.18L14,12l4.59-4.58L20,8.83,16.83,12,20,15.17Z"
          transform="translate(0 0)"
          className="command_main_color"
        />
      </svg>
    </SvgIcon>
  );
}

export function IconNumbers(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Component_15_1"
        data-name="Component 15 – 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path id="Path_183" data-name="Path 183" d="M0,0H24V24H0Z" className="command_bg_color" />
        <path
          id="numeric"
          d="M5,17V9H3V7H7V17H5m16-2a2,2,0,0,1-2,2H15V15h4V13H17V11h2V9H15V7h4a2,2,0,0,1,2,2v1.5A1.5,1.5,0,0,1,19.5,12,1.5,1.5,0,0,1,21,13.5V15m-7,0v2H8V13a2,2,0,0,1,2-2h2V9H8V7h4a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2H10v2Z"
          className="command_main_color"
        />
      </svg>
    </SvgIcon>
  );
}

export function SmallABIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Component_16_1"
        data-name="Component 16 – 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path id="Path_184" data-name="Path 184" d="M0,0H24V24H0Z" className="command_bg_color" />
        <path
          id="Path_185"
          data-name="Path 185"
          d="M15,9a1,1,0,1,1,1,1A1,1,0,0,1,15,9Zm-5,1A2,2,0,0,1,8,8V0h2V4h2a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2Zm0-2h2V6H10ZM3,10A2,2,0,0,1,1,8V6A2,2,0,0,1,3,4H5A2,2,0,0,1,7,6v4ZM3,8H5V6H3Z"
          transform="translate(3 7)"
          className="command_bg_color"
        />
        <path
          id="Path_185-2"
          data-name="Path 185"
          d="M15,9a1,1,0,1,1,1,1A1,1,0,0,1,15,9Zm-5,1A2,2,0,0,1,8,8V0h2V4h2a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2Zm0-2h2V6H10ZM3,10A2,2,0,0,1,1,8V6A2,2,0,0,1,3,4H5A2,2,0,0,1,7,6v4ZM3,8H5V6H3Z"
          transform="translate(3 7)"
          className="command_main_color"
        />
      </svg>
    </SvgIcon>
  );
}

export function BigABIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Component_17_1"
        data-name="Component 17 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path id="Path_187" data-name="Path 187" d="M0,0H24V24H0Z" className="command_bg_color" />
        <path
          id="alphabetical-variant"
          d="M4,7A2,2,0,0,0,2,9v8H4V13H6v4H8V9A2,2,0,0,0,6,7H4M4,9H6v2H4m11-.5V9a2,2,0,0,0-2-2H9V17h4a2,2,0,0,0,2-2V13.5A1.54,1.54,0,0,0,13.5,12,1.54,1.54,0,0,0,15,10.5M13,15H11V13h2v2m0-4H11V9h2m5-2a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2V14H20v1H18V9h2v1h2V9a2,2,0,0,0-2-2Z"
          className="command_main_color"
        />
      </svg>
    </SvgIcon>
  );
}

export function SignsIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Component_18_1"
        data-name="Component 18 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path id="Path_188" data-name="Path 188" d="M0,0H24V24H0Z" className="command_bg_color" />
        <path
          id="symbol"
          d="M3,7v7H5V7H3M6,7V9h4v2H8v3h2V13a1.993,1.993,0,0,0,2-2V9a1.993,1.993,0,0,0-2-2H6m8.8,0-.2,2H13v2h1.4l-.2,2H13v2h1l-.2,2h2l.2-2h1.4l-.2,2h2l.2-2H21V13H19.6l.2-2H21V9H20l.2-2h-2L18,9H16.6l.2-2h-2m1.6,4h1.4l-.2,2H16.2l.2-2M3,15v2H5V15H3m5,0v2h2V15Z"
          className="command_main_color"
        />
      </svg>
    </SvgIcon>
  );
}

export function TwoFAIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        id="Component_19_1"
        data-name="Component 19 – 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path id="Path_189" data-name="Path 189" d="M0,0H24V24H0Z" className="command_bg_color" />
        <path
          id="Path_186"
          data-name="Path 186"
          d="M18,12H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H18a2,2,0,0,1,2,2v8A2,2,0,0,1,18,12ZM14.2,7.4h1.4V9.5H17V3.9a1.4,1.4,0,0,0-1.4-1.4H14.2a1.4,1.4,0,0,0-1.4,1.4V9.5h1.4V7.4ZM7.9,2.5v7H9.3V6.7h2.1V5.3H9.3V3.9h2.8V2.5ZM4.4,5.3A1.4,1.4,0,0,0,3,6.7V9.5H7.2V8.1H4.4V6.7H5.8A1.4,1.4,0,0,0,7.2,5.3V3.9A1.4,1.4,0,0,0,5.8,2.5H3V3.9H5.8V5.3ZM15.6,6H14.2V3.9h1.4V6Z"
          transform="translate(2 6)"
          className="command_main_color"
        />
      </svg>
    </SvgIcon>
  );
}

export function TrashIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
        <g id="Group_105" data-name="Group 105" transform="translate(-644 -351)">
          <path
            id="Path_178"
            data-name="Path 178"
            d="M0,0H34V34H0Z"
            transform="translate(644 351)"
            fill="rgba(51,51,51,0)"
          />
          <path
            id="trash-can-outline"
            d="M11.083,3V4.417H4V7.25H5.417V25.667A2.833,2.833,0,0,0,8.25,28.5H22.417a2.833,2.833,0,0,0,2.833-2.833V7.25h1.417V4.417H19.583V3h-8.5M8.25,7.25H22.417V25.667H8.25V7.25m2.833,2.833v12.75h2.833V10.083H11.083m5.667,0v12.75h2.833V10.083Z"
            transform="translate(645.667 352.25)"
            fill="#f2f2f2"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export function AIcon(props: any) {
  return (
    <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
      <g id="Group_104" data-name="Group 104" transform="translate(-710 -260)">
          <path
          id="Path_177"
          data-name="Path 177"
          d="M0,0H34V34H0Z"
          transform="translate(710 260)"
          fill="rgba(0,0,0,0)"
        />
        <path
          id="brightness-auto"
          d="M19.971,22.379l-.992-2.833H14.446l-.992,2.833H10.762L15.3,9.629h2.833l4.533,12.75H19.971m8.075-10.356V5.379H21.4L16.712.69,12.023,5.379H5.379v6.644L.69,16.712,5.379,21.4v6.644h6.644l4.689,4.689L21.4,28.046h6.644V21.4l4.689-4.689-4.689-4.689m-12.962,5.61h3.258l-1.629-5.171Z"
          transform="translate(710.287 260.288)"
          fill="#f2f2f2"
        />
      </g>
    </svg>
    </SvgIcon>
  );
}

export function EditIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
        <g id="Group_102" data-name="Group 102" transform="translate(-1028 -505)">
          <path
            id="Path_54"
            data-name="Path 54"
            d="M0,0H34V34H0Z"
            transform="translate(1028 505)"
            fill="rgba(51,51,51,0)"
          />
          <path
            id="pencil-outline"
            d="M18.668,11.5,20,12.832,7.137,25.667h-1.3v-1.3L18.668,11.5m5.1-8.5a1.418,1.418,0,0,0-.992.411L20.184,6,25.5,11.316l2.593-2.592a1.411,1.411,0,0,0,0-2L24.774,3.411A1.391,1.391,0,0,0,23.768,3m-5.1,4.519L3,23.188V28.5H8.313L23.981,12.832Z"
            transform="translate(1029.248 506.249)"
            fill="#f2f2f2"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export function SyncIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <path d="M655.461 473.469c11.875 81.719-13.062 167.781-76.812 230.594-94.188 92.938-239.5 104.375-346.375 34.562l74.875-73L31.96 627.25 70.367 896l84.031-80.5c150.907 111.25 364.938 100.75 502.063-34.562 79.5-78.438 115.75-182.562 111.25-285.312L655.461 473.469zM189.46 320.062c94.156-92.938 239.438-104.438 346.313-34.562l-75 72.969 275.188 38.406L697.586 128l-83.938 80.688C462.711 97.34400000000005 248.742 107.96900000000005 111.585 243.25 32.085 321.656-4.133 425.781 0.335 528.5l112.25 22.125C100.71 468.875 125.71 382.906 189.46 320.062z" />
    </svg>
  );
}
