import React from 'react';
import DownloadBox from '../Icons/DictionaryIcons';
import CommandButton from './CommandButton';
import Skeleton from '@mui/material/Skeleton';

interface Title {
  text: string | null | undefined;
  onClick?: () => void;
  isLoading?: boolean;
}

interface Command {
  children: JSX.Element;
  isOn?: boolean;
  disabled?: boolean;
  onClick: () => void;
  separate?: boolean;
}

interface Props {
  titles: Title[];
  mainCommands: Command[];
  middleCommands?: Command[];
  isLoading: boolean;
}

export default function OptionsLine({ titles, mainCommands, middleCommands, isLoading }: Props) {
  return (
    <div className="dictionary_options_line_wrapper">
      {titles.map((title, i) => {
        if (!title.isLoading && !title.text?.length) return null;

        return (
          <div className="main_line_section" key={title.text}>
            {i !== 0 && <div className="dictionary_section_main_line_divider" style={{ width: '20px' }} />}
            {title.isLoading ? (
              <Skeleton variant="text" width={80} />
            ) : (
              title.text &&
              title.text.length && (
                <>
                  <span className="dictionary_options_title">{title.text}</span>
                </>
              )
            )}
          </div>
        );
      })}

      <div className="dictionary_section_main_line_divider" />
      <div className="main_line_section">
        {mainCommands &&
          mainCommands.map((command, i) => (
            <div className="dictionary_commands_wrapper" key={i.toString()}>
              <CommandButton onClick={command.onClick} isOn={command.isOn} disabled={command.disabled}>
                {command.children}
              </CommandButton>
            </div>
          ))}
        {mainCommands && mainCommands.length > 0 && (
          <div className="dictionary_section_main_line_divider" style={{ width: '20px' }} />
        )}
        {middleCommands &&
          middleCommands.map((command, i) => (
            <div className="dictionary_commands_wrapper" key={i.toString()}>
              {command.separate && (
                <div className="dictionary_section_main_line_divider" style={{ width: '20px' }} />
              )}
              <CommandButton onClick={command.onClick} isOn={command.isOn} disabled={command.disabled}>
                {command.children}
              </CommandButton>
            </div>
          ))}
        <>
          <div className="dictionary_section_main_line_divider" style={{ width: '20px' }} />
          <div className="dictionary_commands_wrapper">
            <CommandButton onClick={() => {}}>
              <DownloadBox />
            </CommandButton>
          </div>
          <div className="dictionary_section_main_line_divider" style={{ width: '6px' }} />
        </>
      </div>
    </div>
  );
}
