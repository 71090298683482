import React, { RefObject, useEffect, useRef } from 'react';
import { ISuggestionItem, FilterType } from './ISearch';
import Looks3Icon from '@mui/icons-material/Looks3';
import SearchIcon from '@mui/icons-material/Search';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FilterListIcon from '@mui/icons-material/FilterList';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import './../../styles/css/search.css';
interface IProps {
  suggestions: ISuggestionItem[];
  shown: boolean;
  activeIndex: number;
  forwardRef: RefObject<HTMLDivElement>;
  clickCallback: (index: number) => void;
  hoverCallback: (index: number) => void;
  canSearch: boolean;
  text: string;
}

const SuggestionDropdown = ({
  suggestions,
  shown,
  activeIndex,
  forwardRef,
  clickCallback,
  hoverCallback,
  canSearch,
  text,
}: IProps) => {

  const wasMouseMoved = useRef<boolean>(false);
  const activeIndexLocal = useRef<number>(-1)

  const onMouseEnter = (index: number) => {
    activeIndexLocal.current = index
    if (wasMouseMoved.current) hoverCallback(index)
  }

  useEffect(() => {
    if (!shown) {
      wasMouseMoved.current = false;
    }
  }, [shown])

  const mouseMovedCallback = () => {
    if (activeIndexLocal.current !== activeIndex) hoverCallback(activeIndexLocal.current)
    wasMouseMoved.current = true;
  }

  const onMouseLeaveDropdown = () => onMouseEnter(-1)

  return (

    <div onMouseMove={mouseMovedCallback} onMouseLeave={onMouseLeaveDropdown} className={`suggestions ${shown ? 'active' : ''}`} ref={forwardRef}>
      {canSearch && text !== '' && (
        <div
          className={`suggestions-item ${activeIndex === -1 ? 'active' : ''}`}
          onMouseEnter={() => onMouseEnter(-1)}
          onClick={() => clickCallback(-1)}
        >
          <SearchIcon fontSize="small" style={{ padding: '1px' }}></SearchIcon>
          <span className="suggestions-item-label">Išči '{text}'</span>
        </div>
      )}
      {suggestions.map((sug: ISuggestionItem, index: number) => (
        <div
          key={index}
          className={`suggestions-item ${activeIndex === index ? 'active' : ''}`}
          onMouseEnter={() => onMouseEnter(index)}
          onClick={() => clickCallback(index)}
          data-testid={`filter-${sug.label}`}
        >
          <FilterIcon filterType={sug.nextType} />
          <span className="suggestions-item-label"> {sug.label} </span>
          {/* <span className="suggestions-item-field"> {sug.field} </span> */}
        </div>
      ))}
    </div>
  );
};

interface IPropsIcon {
  filterType: FilterType;
}

const FilterIcon = ({ filterType }: IPropsIcon) => {
  switch (filterType) {
    case FilterType.NumberFilter:
      return <Looks3Icon fontSize="small" style={{ padding: '1px' }} />;
    case FilterType.StringFilter:
      return <TextFieldsIcon fontSize="small" style={{ padding: '1px' }} />;
    case FilterType.DateFilter:
      return <CalendarTodayIcon fontSize="small" style={{ padding: '1px' }} />;
    default:
      return <FilterListIcon fontSize="small" style={{ padding: '1px' }} />;
  }
};

export default SuggestionDropdown;
