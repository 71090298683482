export enum AccessMode {
    READ_ONLY = "READ_ONLY",
    READ_WRITE = "READ_WRITE",
}

export interface IPutSessionLock {
    expiresAt: string,
    key: string,
}

export interface IModelStatus {
    getModelStatus: (language?: string, domain?: string, modelName?: string) => Promise<{ isUpdating: boolean, isUpdatable: boolean }>
}

export interface ISpeakerRange {
    id: number,
    rangeStart: number,
    rangeEnd: number,
    speaker: string | null,
    text: string
}