import React, { Fragment } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { MainRoute } from './mainRoutes';

interface Props {
  routes: MainRoute[];
}

const RoutesComponent = ({ routes }: Props) => {
  return (
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Component = route.component || Fragment;
        const Provider = route.provider || Fragment;

        return (
          <Route
            key={i.toString()}
            path={route.path}
            element={
              <Guard>
                <Provider>
                  <Component />
                </Provider>
              </Guard>
            }
          />
        );
      })}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RoutesComponent;
