import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function DictDesynchedIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        data-name="Component 32 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect
          className="dict_icon_bg"
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="24"
          height="24"
          transform="translate(24) rotate(90)"
        />
        <path
          id="Path_198"
          data-name="Path 198"
          d="M-4324,1515a4,4,0,0,1-3.521-2.109,4,4,0,0,1,.19-4.1l1.09,1.09a2.5,2.5,0,0,0,.109,2.436A2.5,2.5,0,0,0-4324,1513.5V1512l2.25,2.25-2.25,2.251Zm-13-1a2.007,2.007,0,0,1-2-2v-14a2.007,2.007,0,0,1,2-2h14a2.006,2.006,0,0,1,2,2v6h-2v-6h-14v14h6v2Zm15.24-1.881a2.5,2.5,0,0,0-.109-2.435A2.5,2.5,0,0,0-4324,1508.5v1.5l-2.25-2.251,2.25-2.25v1.5a4,4,0,0,1,3.521,2.109,4,4,0,0,1-.191,4.1ZM-4335,1510v-2h4v2Zm0-4v-2h8v2Zm0-4v-2h6v2Z"
          transform="translate(4342 -1493)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}

export function DictEditIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        // id="Component_33_1"
        data-name="Component 33 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect
          className="dict_icon_bg"
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="24"
          height="24"
          transform="translate(24) rotate(90)"
        />
        <path
          id="Path_197"
          data-name="Path 197"
          d="M-4382.92,1515.95v-2.1l6.2-6.1,2,2.1-6.1,6.1ZM-4391,1514a2.005,2.005,0,0,1-2-2v-14a2.005,2.005,0,0,1,2-2h14a2.005,2.005,0,0,1,2,2v6h-2v-6h-14v14h6v2Zm2-4v-2h4v2Zm12.681-2.75,1-1a.752.752,0,0,1,.4-.2.519.519,0,0,1,.4.2l1.3,1.3a.429.429,0,0,1-.1.7l-1,1ZM-4389,1506v-2h8v2Zm0-4v-2h6v2Z"
          transform="translate(4396 -1493)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}

export function DictSynchedIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        // id="Component_32_1"
        data-name="Component 32 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="24"
          height="24"
          transform="translate(24) rotate(90)"
          fill="none"
        />
        <path
          id="Path_197"
          data-name="Path 197"
          d="M-4350.75,1511.08l1.161-1.16,1.589,1.59,3.59-3.59,1.16,1.41-4.75,4.75ZM-4360,1514a2.006,2.006,0,0,1-2-2v-14a2.006,2.006,0,0,1,2-2h14a2.006,2.006,0,0,1,2,2v6h-2v-6h-14v14h6v2Zm2-4v-2h4v2Zm0-4v-2h8v2Zm0-4v-2h6v2Z"
          transform="translate(4365 -1493)"
          fill="#707070"
        />
      </svg>
    </SvgIcon>
  );
}

export function FailedSyncingIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20.005" height="20.003" viewBox="0 0 20.005 20.003">
        <g id="Group_140" data-name="Group 140" transform="translate(-1.995 -1.999)">
          <path
            id="alert-rhombus-outline"
            d="M12,2a2,2,0,0,0-1.41.59l-8,8a1.974,1.974,0,0,0,0,2.82l8,8a1.974,1.974,0,0,0,2.82,0l8-8a1.974,1.974,0,0,0,0-2.82l-8-8A2,2,0,0,0,12,2m0,2,8,8-8,8L4,12m7-5v6h2V7m-2,8v2h2V15Z"
            transform="translate(-0.002 -0.001)"
            fill="#f80534"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export function DictSyncingIcon0(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        // id="Component_32_1"
        data-name="Component 32 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="24"
          height="24"
          transform="translate(24) rotate(90)"
          fill="none"
        />
        <path
          id="Path_197"
          data-name="Path 197"
          d="M-4349.209,1514.33l1.089-1.089a2.5,2.5,0,0,0,2.436-.109A2.5,2.5,0,0,0-4344.5,1511h-1.5l2.25-2.25,2.25,2.25h-1.5a4,4,0,0,1-2.109,3.522,3.99,3.99,0,0,1-1.89.475A3.993,3.993,0,0,1-4349.209,1514.33ZM-4360,1514a2.006,2.006,0,0,1-2-2v-14a2.006,2.006,0,0,1,2-2h14a2.006,2.006,0,0,1,2,2v6h-2v-6h-14v14h6v2Zm7.5-3h1.5a4,4,0,0,1,2.11-3.521,4,4,0,0,1,4.1.192l-1.089,1.089a2.5,2.5,0,0,0-2.436.109,2.5,2.5,0,0,0-1.184,2.13h1.5l-2.25,2.251Z"
          transform="translate(4365 -1493)"
          fill="#707070"
        />
      </svg>
    </SvgIcon>
  );
}
export function DictSyncingIcon1(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        // id="Component_32_1"
        data-name="Component 32 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="24"
          height="24"
          transform="translate(24) rotate(90)"
          fill="none"
        />
        <path
          id="Path_197"
          data-name="Path 197"
          d="M-4347,1515a4,4,0,0,1-3.521-2.11,4,4,0,0,1,.19-4.1l1.09,1.089a2.5,2.5,0,0,0,.109,2.436,2.5,2.5,0,0,0,2.13,1.185V1512l2.251,2.25L-4347,1516.5Zm-13-1a2.006,2.006,0,0,1-2-2v-14a2.006,2.006,0,0,1,2-2h14a2.006,2.006,0,0,1,2,2v6h-2v-6h-14v14h6v2Zm15.24-1.88a2.5,2.5,0,0,0-.109-2.436A2.5,2.5,0,0,0-4347,1508.5v1.5l-2.25-2.25,2.25-2.251v1.5a4,4,0,0,1,3.522,2.109,4,4,0,0,1-.192,4.1ZM-4358,1502v-2h6v2Z"
          transform="translate(4365 -1493)"
          fill="#707070"
        />
      </svg>
    </SvgIcon>
  );
}
export function DictSyncingIcon2(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        // id="Component_32_1"
        data-name="Component 32 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="24"
          height="24"
          transform="translate(24) rotate(90)"
          fill="none"
        />
        <path
          id="Path_197"
          data-name="Path 197"
          d="M-4349.209,1514.33l1.089-1.089a2.5,2.5,0,0,0,2.436-.109A2.5,2.5,0,0,0-4344.5,1511h-1.5l2.25-2.25,2.25,2.25h-1.5a4,4,0,0,1-2.109,3.522,3.99,3.99,0,0,1-1.89.475A3.993,3.993,0,0,1-4349.209,1514.33ZM-4360,1514a2.006,2.006,0,0,1-2-2v-14a2.006,2.006,0,0,1,2-2h14a2.006,2.006,0,0,1,2,2v6h-2v-6h-14v14h6v2Zm7.5-3h1.5a4,4,0,0,1,2.11-3.521,4,4,0,0,1,4.1.192l-1.089,1.089a2.5,2.5,0,0,0-2.436.109,2.5,2.5,0,0,0-1.184,2.13h1.5l-2.25,2.251Zm-5.5-5v-2h8v2Zm0-4v-2h6v2Z"
          transform="translate(4365 -1493)"
          fill="#707070"
        />
      </svg>
    </SvgIcon>
  );
}
export function DictSyncingIcon3(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        // id="Component_32_1"
        data-name="Component 32 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="24"
          height="24"
          transform="translate(24) rotate(90)"
          fill="none"
        />
        <path
          id="Path_197"
          data-name="Path 197"
          d="M-4347,1515a4,4,0,0,1-3.521-2.11,4,4,0,0,1,.19-4.1l1.09,1.089a2.5,2.5,0,0,0,.109,2.436,2.5,2.5,0,0,0,2.13,1.185V1512l2.251,2.25L-4347,1516.5Zm-13-1a2.006,2.006,0,0,1-2-2v-14a2.006,2.006,0,0,1,2-2h14a2.006,2.006,0,0,1,2,2v6h-2v-6h-14v14h6v2Zm15.24-1.88a2.5,2.5,0,0,0-.109-2.436A2.5,2.5,0,0,0-4347,1508.5v1.5l-2.25-2.25,2.25-2.251v1.5a4,4,0,0,1,3.522,2.109,4,4,0,0,1-.192,4.1ZM-4358,1510v-2h4v2Zm0-4v-2h8v2Zm0-4v-2h6v2Z"
          transform="translate(4365 -1493)"
          fill="#707070"
        />
      </svg>
    </SvgIcon>
  );
}
